<ng-container *ngIf="record">
  <button nz-button nz-dropdown nz-button nzType="text" [nzDropdownMenu]="menu" nzPlacement="bottomLeft">
    <app-icon *ngIf="visibleActions > 0" iconName="verticalEllipsis"></app-icon>
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <ng-container>
        <li *ngFor="let action of actions; let i = index; let l = count">
          <inline-action
            *ngIf="action.actionCode !== defaultAction"
            [action]="action"
            [recordData]="record?.recordData"
            [recordId]="record?.recordId"
            (actionSelected)="actionInSetSelected($event)">
          </inline-action>
        </li>
      </ng-container>
    </ul>
  </nz-dropdown-menu>
</ng-container>
