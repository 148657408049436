import { Component, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-textnumber',
  templateUrl: './app-text-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTextNumberComponent extends FieldComponent {
  valueChanged = false;
  preffix: string = null;
  separators = true;
  reg = /^([0-9]*)?$/;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  override focus() {
    this.inputElement && this.inputElement.nativeElement.focus();
  }

  makeReady(): void {
    if (this.valueChanged) {
      this.updateObject();
      this.onChangeContent();
      this.valueChanged = false;
    }
  }

  setValueNativeInput(formatedValue) {
    this.value.set(formatedValue);
    if (this.inputElement?.nativeElement) {
      this.inputElement!.nativeElement.value = formatedValue ?? '';
    }
  }

  override updateObject() {
    this.field.value = this.value();
  }

  override updateValue() {
    this.setValueNativeInput(this.field.value);
  }

  onChangeModel(value: string): void {
    this.valueChanged = true;
    let formatedValue = this.value();
    const rawValue = value?.replace(/[^0-9]+/g, '') ?? '';
    if ((!isNaN(+rawValue) && this.reg.test(rawValue)) || rawValue === '') {
      formatedValue = `${rawValue}`;
    }
    this.setValueNativeInput(formatedValue);
    this.updateObject();
    this.onInputChange();
  }
}
