import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { NzMarks } from 'ng-zorro-antd/slider';

function zeroPad(num, size) {
  return ('000000000' + num).substr(-size);
}
@Component({
  selector: 'app-timerangeslider',
  templateUrl: './app-time-range-slider.component.html',
  styles: [
    `
      .ant-slider-with-marks {
        margin-bottom: 44px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTimeRangeSliderComponent extends FieldComponent {
  style: string;
  timeMin = 600;
  timeMax = 2300;
  marks: NzMarks = {};

  override start() {
    super.start();
    for (let index = 5; index <= 24; index += 1) {
      const suffix = index >= 12 ? 'pm' : 'am';
      const hour = index > 12 ? index - 12 : index;
      const markLabel = index % 2 === 0 ? `${hour} ${suffix}` : '';
      this.marks[index * 100] = markLabel;
    }
  }

  formatter(integerTime: number): string {
    const decimalTime = integerTime / 100.0;
    const hour = Math.floor(decimalTime);
    const minutes = Math.floor(60 * (decimalTime - hour));
    return `${zeroPad(hour, 2)}:${zeroPad(minutes, 2)}`;
  }
}
