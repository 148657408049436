import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-html',
  template: `
  <!-- class="card p-4" style="box-shadow: 6px 6px 4px lightgrey;" -->
    <div style="text-align: justify; max-height: 40vh; overflow: auto; margin-top: 0 !important;" class="card p-3 mt-4 mb-4" role="alert" [class]="displayClass">
     <span [innerHtml]="value()"></span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTextHtmlComponent extends FieldComponent {
  @Input() labelType: any;
  @Input() displayClass: any = '';

  override updateValue(): void {
    this.displayClass = this?.customAttributes().displayClass ?? '';
    super.updateValue();
  }
}
