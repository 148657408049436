<div *ngIf="actions?.length > 0" class="actionsFormFooter">
  <ng-container *ngFor="let actionGroup of actionGroups">
    <div class="elementFooterGroup">
      <div [ngClass]="actionGroup.className">
        <ng-container *ngFor="let action of actionGroup.actions">
          <ng-container *ngIf="action?.visible">
            <app-action
              *ngIf="
                action?.customAttributes.location === 'BODY' &&
                action?.customAttributes.displayType !== 'link' &&
                action?.customAttributes.displayLocation !== 'modal'
              "
              [action]="action"></app-action>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
