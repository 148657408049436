<div class="row">
  <div
    *ngIf="captureType() === 'MESSAGE' && value()"
    [ngClass]="customAttributes().displayType === 'link' ? 'underline' : ''"
    [class]="code() + ' ' + customAttributes().displayClass">
    {{ value() }}
  </div>
  <h3 class="col-lg-12" *ngIf="type() === 'LABEL'">
    <span class="ant-page-header-heading-title ng-star-inserted">
      {{ title() }}
    </span>
  </h3>
  <h3 *ngIf="type() === 'TITLE'">
    {{ title() }}
  </h3>
  <div class="col-lg-12 offset-lg-4" *ngIf="type() === 'MESSAGE' && value()">
    <nz-tag nzColor="orange">
      <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
      <span>{{ value() }}</span>
    </nz-tag>
  </div>
  <a href="{{ value() }}" target="_blank" *ngIf="type() === formConfig?.fieldTypes?.link">
    {{ title() }}
  </a>
</div>
