<app-frontend-config></app-frontend-config>
<nz-layout>
  <nz-content>
    <ng-container>
      <app-frontend-config></app-frontend-config>
      <app-activity-check *ngIf="sessionActive"></app-activity-check>
    </ng-container>
    <router-outlet></router-outlet>
    <nz-modal [(nzVisible)]="viewNotification" nzTitle="" (nzOnCancel)="closeNotification()">
      <div *nzModalContent>
        <div class="overlay">
          <div class="imageContainer">
            <img class="notificationImage" [src]="notification.image" alt="notification">
          </div>
        </div>
      </div>
      <div *nzModalFooter>
        <ng-container *ngIf="notification.type === 'LINK' || notification.type === 'FORM'">
          <button nz-button nzType="primary" (click)="openNotification()">Detalle</button>
          <button nz-button nzType="default" (click)="closeNotification()" [nzLoading]="isConfirmLoading">
            Ver Luego</button>
        </ng-container>
        <ng-container *ngIf="notification.type !== 'LINK' && notification.type !== 'FORM'">
          <button nz-button nzType="default" (click)="closeNotification()">Cerrar</button>
        </ng-container>
      </div>
    </nz-modal>
  </nz-content>
</nz-layout>