<nz-form-item>
  <nz-form-control>
    <nz-select
      style="min-width: 80px"
      [nzDropdownStyle]="{ 'min-width': '250px' }"
      [id]="field.code"
      [(ngModel)]="value()[0]"
      (ngModelChange)="onChooseDept()"
      nzShowSearch
      nzPlaceHolder="Departamento"
      [nzAllowClear]="!required()"
      [disabled]="disabled()">
      <nz-option
        *ngFor="let dept of departaments"
        nzCustomContent
        [nzLabel]="dept.name"
        [nzValue]="dept.code"
        [nzDisabled]="disabled()"
        >{{ dept.name }}</nz-option
      >
    </nz-select>
  </nz-form-control>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-select
      style="min-width: 80px"
      [nzDropdownStyle]="{ 'min-width': '250px' }"
      [id]="field.code"
      [(ngModel)]="value()[1]"
      (ngModelChange)="inputChanged()"
      nzShowSearch
      [nzPlaceHolder]="placeholder()"
      [nzAllowClear]="!required()"
      [disabled]="disabled()">
      <nz-option
        *ngFor="let city of cities"
        nzCustomContent
        [nzLabel]="city.name"
        [nzValue]="city.code"
        [nzDisabled]="disabled()">
        {{ city.name }}</nz-option
      >
    </nz-select>
  </nz-form-control>
</nz-form-item>
