<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-select
      [nzNotFoundContent]="nzEmpty"
      [id]="field.code"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      nzShowSearch
      [nzPlaceHolder]="placeholder()"
      [nzAllowClear]="!required() && !disabled()"
      [nzFilterOption]="filterInput"
      autocomplete="off"
      [disabled]="disabled()">
      <nz-option
        *ngFor="let option of options()"
        [nzLabel]="option.fieldOptionValue"
        [nzValue]="option.fieldOptionId"
        [nzDisabled]="disabled()"></nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
<ng-template #nzEmpty>
  <div style="display: flex; justify-content: center">
    <span nz-icon nzType="cloud" nzTheme="twotone" style="font-size: 35px"></span>
  </div>
  <div style="display: flex; justify-content: center">Sin resultados</div>
</ng-template>
