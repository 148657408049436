<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" (nzVisibleChange)="badgeCountIncrease()">
    <!-- [nzShape]="'square'" -->
    <nz-avatar nzIcon="user" [nzSize]="'large'" class="avatar" nzSrc="{{userInfo?.avatar}}">
    </nz-avatar>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu *ngFor="let item of menuData">
      <li nz-menu-item (click)="activateMenu(item?.form)">
        <i *ngIf="item.icon" [ngClass]="[item.iconName, 'icon']"></i>
        <span>{{ item?.title }} {{userInfo[item?.value]}}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
