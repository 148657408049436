import { Component, Input } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as Reducers from 'src/app/store/reducers';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations';

const CONFIG_LOADED = 'configLoaded';

@Component({
  selector: 'app-layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: [
    './auth.component.scss',
    '../../templates/form.scss',
  ],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutAuthComponent {
  assetsBase: String;
  logo: String;
  isGrayTopbar: Boolean;
  isCardShadow: Boolean;
  isSquaredBorders: Boolean;
  isBorderless: Boolean;
  authPagesColor: String;
  routerAnimation: String;
  selectedInitial = 0;
  currentPath: any[]

  constructor(
    private _store: Store<any>,
    private _eventManager: EventManagerService,
    private _appConfig: AppConfigService,
  ) {
    this._eventManager.subscribe(CONFIG_LOADED, () => this.assetsBase = this._appConfig.getParameter('assetsBase'));
    this._store.pipe(select(Reducers.getSettings)).subscribe((state) => {
      this.logo = state.logo;
      this.isGrayTopbar = state.isGrayTopbar;
      this.isCardShadow = state.isCardShadow;
      this.isSquaredBorders = state.isSquaredBorders;
      this.isBorderless = state.isBorderless;
      this.authPagesColor = state.authPagesColor;
      this.routerAnimation = state.routerAnimation;
      this.routerAnimation = state.routerAnimation;
    });
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path;
    }
  }
}
