import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserSessionService, activeSessionErrors } from './user-session.service';
import { EventManagerService } from './event-manager.service';
import { ComponentPageService } from './component-pages.service';
import { FormOperationService } from './form-manager.service';

const SESSION_ENDED = 'sessionEnded';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  controlVar: any;
  constructor(
    private _userSession: UserSessionService,
    private _eventManager: EventManagerService,
    private _componentManager: ComponentPageService,
    private _formManager: FormOperationService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requestedRoute = state.url;
    const errorCode = await this._userSession.activeSession();
    const relatedForm = this._componentManager.routeForm(requestedRoute);
    let requiredFunctions = [];
    if (relatedForm) {
      const formDefinition = await this._formManager.getFormDefinition(relatedForm) ?? {};
      requiredFunctions = [...(formDefinition?.requiredFunctions ?? [])];
    }
    if (errorCode === activeSessionErrors.validSession) {
      if (!this._userSession.userCanNavigate(requestedRoute, requiredFunctions)) {
        this._eventManager.next('notAuthorized', null);
        return false;
      }
      return true;
    }
    if (errorCode === activeSessionErrors.expiredOnServer
      || errorCode === activeSessionErrors.concurrentSession
      || errorCode === activeSessionErrors.noSession) {
      this._eventManager.next(SESSION_ENDED, { endDate: new Date() });
    } else {
      this._eventManager.next(SESSION_ENDED, { endDate: new Date() });
    }
    return false;
  }
}
