import { Component, Input, OnInit } from '@angular/core';
import { commonSliders } from '../../../data/common-sliders'

@Component({
  selector: 'app-swiper-slides',
  templateUrl: './swiper-slides.component.html',
  styleUrls: ['./swiper-slides.component.scss']
})

export class SwiperSlidesComponent implements OnInit {

  @Input() slideObjects: any[] = commonSliders.slideObjects
  @Input() swiperProperties: any = commonSliders.swiperProperties

  constructor() { }

  ngOnInit(): void {
    
   }

}