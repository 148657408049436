import { Component, ChangeDetectionStrategy } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-yearmonth',
  templateUrl: './app-yearmonth.component.html',
  styles: [`nz-date-picker ::ng-deep .ant-calendar-picker { width: 100%; }
    nz-date-picker, nz-time-picker { width: 100%; }
    .form-control-error { z-index:4; }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppYearMonthComponent extends FieldComponent { }
