<nz-form-item>
  <nz-form-control
    [nzValidateStatus]="onValidation() ? 'validating' : errorType()"
    [id]="field.code"
    [nzErrorTip]="errorMessage()">
    <nz-range-picker
      [nzDisabledDate]="disabledDate"
      [nzRanges]="ranges"
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      [nzDisabled]="disabled()">
    </nz-range-picker>
  </nz-form-control>
</nz-form-item>
