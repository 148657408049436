/* eslint-disable @typescript-eslint/naming-convention */
import { moduleRoutes as auth } from './auth-routes';
import { moduleRoutes as login } from './login-routes';
import { moduleRoutes as admin } from './admin-routes';
import { moduleRoutes as home } from './home-routes';
import { moduleRoutes as learning } from './learning-routes';
import { moduleRoutes as root } from './root-page-routes';
import { moduleRoutes as security } from './security-routes';
import { moduleRoutes as transactions } from './transactions-routes';
import { moduleRoutes as tickets } from './tickets-routes';
import { moduleRoutes as welcome } from './welcome-routes';
import { moduleRoutes as onboard } from './onboard-routes';
// ENDUSER
import { moduleRoutes as euHome } from './eu-home-routes';
import { moduleRoutes as endUserAuth } from './eu-auth-routes';
import { moduleRoutes as endUserRoot } from './eu-root-routes';
import { moduleRoutes as endUserTransactions } from './eu-transactions-routes';

export const standardRoutes = {
  EMPTY: '',
  ANY: '**',
  HOME: 'agentHome',
  DEFAULT: 'login',
  UNAUTHORIZED: 'unauthorized',
  NOTFOUND: 'notfound',
  LOGIN: 'login',
  START: 'welcome',
  OPENIDLOGIN: 'openidlogin',
  FORGETPASSWORD: 'forgetPassword',
  REQUESTPASSWORD: 'forgotPassword',
  CHANGEPASSWORD: 'password',
  SESSIONBASED_HOME_NODEVICE: 'unknownDevice',
  SESSIONBASED_HOME_DEVICE: 'services',
  SESSIONBASED_HOME: 'services',
  SESSIONLESS_HOME: 'login',
  REGISTER: 'registerUser',
  //ENDUSER
  EUHOME: 'endUserHomePage',
  EUDEFAULT: 'endUserAccess',
};

export const formRoutes = {
  auth,
  login,
  admin,
  home,
  learning,
  root,
  security,
  transactions,
  tickets,
  onboard,
  welcome,
  // ENDUSER
  euHome,
  endUserAuth,
  endUserRoot,
  endUserTransactions,
};
