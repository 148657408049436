import { Component, Input } from '@angular/core';
import { AppSimpleField } from '../app-simple-field/app-simple-field.component';
import * as moment from 'moment';
import 'moment/locale/es';

@Component({
  selector: 'app-timerange-pckrs',
  templateUrl: './app-timerange-pckrs.component.html',
  styles: [`
    .timePickerError {
      font-size: small;
      color: #ff0000;
      text-align: center;
    }
  `],
})
export class AppTimeRangePckrsComponent extends AppSimpleField {
  startTime: Date;
  endTime: Date;
  tzoffset: any;

  override start() {
    super.start();
    if (this.field) {
      this.field.value || (this.field.value = []);
    }
  }

  override updateObject() {
    const nowDate = new Date();
    this.tzoffset = nowDate.getTimezoneOffset();
    const startTime = new Date(this.startTime);
    const endTime = new Date(this.endTime);
    startTime.setMinutes(startTime.getMinutes() - this.tzoffset);
    endTime.setMinutes(endTime.getMinutes() - this.tzoffset);
    this.value.set([startTime, endTime]);
    this.field.value = this.value();
  }

  formatTime(timeData) {
    const nowDate = new Date();
    this.tzoffset = nowDate.getTimezoneOffset();
    let startTime = new Date(timeData?.[0]);
    let endTime = new Date(timeData?.[1]);
    startTime.setMinutes(startTime.getMinutes() + this.tzoffset);
    endTime.setMinutes(endTime.getMinutes() + this.tzoffset);
    return [startTime, endTime];
  }

  override updateValue() {
    this.value.set(this.field.value);
    if (Array.isArray(this.value())) {
      [this.startTime, this.endTime] = this.formatTime(this.value());
    } else {
      [this.startTime, this.endTime] = this.formatTime([null, null]);
    }
  }

  onChangeModel1(value): void {
    this.startTime = value;
    this.updateObject();
    this.onChangeContent();
  }

  onChangeModel2(value): void {
    this.endTime = value;
    this.updateObject();
    this.onChangeContent();
  }
}