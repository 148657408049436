import { Component, ChangeDetectionStrategy, computed } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-segment',
  templateUrl: './app-segment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSegmentComponent extends FieldComponent {
  selectedOption: any;
  segmentOptions = computed<any[]>(() => {
    let options = this.options().map(item => item.fieldOptionValue);
    return options;
  })

  override start() {
    super.start();
  }

  segmentChanged() {
    const optionValue = this.segmentOptions()?.[this.selectedOption];
    this.value.set(this.options().find(item => item.fieldOptionValue === optionValue)?.fieldOptionId);
    super.inputChanged();
  }

  override updateObject() {
    const optionValue = this.segmentOptions()?.[this.selectedOption];
    this.value.set(this.options().find(item => item.fieldOptionValue === optionValue)?.fieldOptionId);
    this.field.value = this.value();
  }

  override updateValue() {
    const relatedValue = this.options().find(item => item.fieldOptionId === this.field.value)?.fieldOptionValue;
    this.selectedOption = this.segmentOptions()?.findIndex(item => item === relatedValue);
  }
}
