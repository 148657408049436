export const moduleRoutes = {
  root: 'tickets',
  childForms: {
    agentTicketOpen: {
      data: { title: 'Nueva Solicitud' },
      requireAuth: true,
      functions: [],
      route: 'newTicket',
    },
    agentTicketDetail: {
      data: { title: 'Detalle de Solicitud' },
      requireAuth: true,
      functions: [],
      route: 'ticketDetail',
    }
  },
  layout: 'main',
  loadChildren: () => import('src/app/page-modules/tickets/tickets.module').then(m => m.TicketsModule),
};
