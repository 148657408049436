import { Component, Input, OnInit } from '@angular/core';
import { componentConstants } from 'src/app/components/tuain/forms.module.config';

const ERROR_TYPES = {
  DEFAULT: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'error',
};

@Component({
  selector: 'app-form-error',
  templateUrl: './app-form-error.component.html',
  styleUrls: ['./app-form-error.component.scss']
})
export class AppFormErrorComponent implements OnInit {
  errorStyle: any = {
    background: '#ffffff',
    'border-radius': '0px',
    'margin-bottom': '0px',
    border: '0px',
    padding: '0px',
  };
  componentConstants: any;
  @Input() errorTitle: any;
  @Input() errorMessage: any;
  @Input() errorType: any;
  type: any;
  showDetail: boolean;
  constructor() {
    this.componentConstants = componentConstants;
    this.showDetail = false;
  }

  ngOnInit(): void {
    this.type = ERROR_TYPES[this.errorType] ?? ERROR_TYPES.DEFAULT;
  }

  toggleDetail() {
    this.showDetail = !this.showDetail;
  }
}
