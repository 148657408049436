<ng-container *ngIf="visible()">
  <button
    *ngIf="!customAttributes().preConfirm"
    nz-button
    [nzType]="nzType"
    [nzSize]="size"
    [nzShape]="shape"
    [nzLoading]="inProgress()"
    [disabled]="disabled() || form?.busy()"
    (click)="activate()"
    [class]="customAttributes().displayClass ?? ''">
    <app-icon
      *ngIf="!inProgress() && customAttributes().showFaIcon"
      [iconName]="customAttributes().faIcon?.toLowerCase() ?? iconName().toLowerCase()"
      style="margin-right: 0.5rem"
      [class]="customAttributes().displayClass + '_Icon' ?? ''">
    </app-icon>
    <ion-icon
      *ngIf="!inProgress() && customAttributes().showIonIcon"
      [slot]="iconSlot"
      [name]="customAttributes().ionIcon?.toLowerCase() ?? iconName().toLowerCase()"
      style="min-width: fit-content; margin-right: 0.5rem"
      [class]="customAttributes().displayClass + '_Icon' ?? ''">
    </ion-icon>
    <span [class]="actionClass()">{{ showLabel ? actionName() : '' }}</span>
  </button>
  <button
    *ngIf="customAttributes().preConfirm"
    nz-button
    [nzType]="nzType"
    [nzSize]="size"
    [nzShape]="shape"
    [nzLoading]="inProgress()"
    [disabled]="disabled() || form?.busy()"
    nz-popconfirm
    [nzPopconfirmTitle]="customAttributes().preConfirm"
    (nzOnConfirm)="activate()"
    nzPopconfirmPlacement="leftTop"
    [class]="customAttributes().displayClass ?? ''">
    <app-icon
      *ngIf="!inProgress() && customAttributes().showFaIcon"
      [iconName]="customAttributes().faIcon?.toLowerCase() ?? iconName().toLowerCase()"
      style="margin-right: 0.5rem"
      [class]="customAttributes().displayClass + '_Icon' ?? ''">
    </app-icon>
    <ion-icon
      *ngIf="!inProgress() && customAttributes().showIonIcon"
      [slot]="iconSlot"
      [name]="customAttributes().ionIcon?.toLowerCase() ?? iconName().toLowerCase()"
      style="min-width: fit-content; margin-right: 0.5rem"
      [class]="customAttributes().displayClass + '_Icon' ?? ''">
    </ion-icon>
    <span [class]="actionClass()">{{ showLabel ? actionName() : '' }}</span>
  </button>
</ng-container>
