import { Component, computed, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-radio',
  templateUrl: './app-radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRadioComponent extends FieldComponent {
  optionsChecked = computed(() => {
    return (this.customAttributes().displayType === 'multiCheckWithIcon') ?
      (this.customAttributes().optionsChecked ?? [])
      : null;
  });
}
