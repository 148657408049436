import { Component, computed, Input, ChangeDetectionStrategy } from '@angular/core';
import { ActionComponent } from 'tuain-ng-forms-lib';
import { NzButtonType, NzButtonShape, NzButtonSize } from "ng-zorro-antd/button";

@Component({
  selector: 'app-action',
  templateUrl: './app-action.component.html',
  styleUrls: ['./app-action.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppActionComponent extends ActionComponent {
  icon: String;
  actionClass = computed(() => this.configureStyle());
  @Input() style = 'primary';
  @Input() showLabel = true;
  @Input() size: NzButtonSize = 'default';
  @Input() nzType: NzButtonType = 'primary';
  @Input() shape: NzButtonShape = null; // 'round';

  override start() {
    super.start();
    this.configureStyle();
  }

  configureStyle() {
    if (this.customAttributes().displayType === 'clean') {
      this.nzType = 'text';
    } else if (this.customAttributes().displayType === 'link') {
      this.nzType = 'link';
      return 'actionLink';
    } else if (this.customAttributes().displayType === 'outline') {
      this.nzType = 'dashed';
      return 'outline';
    }
  }
}

