<div class="ion-header ion-no-border">
  <ion-progress-bar
    *ngIf="form?.busy()"
    style="position: absolute; top: 0; left: 0"
    type="indeterminate"></ion-progress-bar>
  <div class="ion-toolbar" color="primary" [style]="'padding-top: 1rem !important'">
    <div class="ion-buttons" slot="start">
      <ion-menu-button *ngIf="showMenu || form?.showMenu"></ion-menu-button>
      <ng-container *ngIf="!(showMenu || form?.showMenu)">
        <ion-button *ngIf="form?.viewGoBack" (click)="form?.goBackForm?.()">
          <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
        </ion-button>
      </ng-container>
    </div>
    <!-- Zona con el título de la página-->
    <div
      nz-col
      nzSpan="18"
      nzOffset="3"
      style="display: flex; align-items: center; color: white; justify-content: space-between">
      <img src="{{ assetsBase }}/images/end-user/bb.png" />
      <div style="padding-inline: 1rem; display: flex; flex-direction: column">
        <h5 style="margin: auto; font-weight: 700">
          {{ name }}
          {{ intro }}
        </h5>
      </div>
      <div class="headerButtons" *ngIf="!isAuthenticated">
        <div class="headerOptionButton">
          <app-icon iconName="person" size="2x" (click)="openRegisterForm()"></app-icon>
        </div>
        <div class="headerOptionButton">
          <app-icon iconName="exit" size="2x" (click)="leave()"></app-icon>
        </div>
      </div>

      <div class="headerButtons" *ngIf="isAuthenticated">
        <app-topbar-end-user-menu (actionSelected)="activateMenu($event)"></app-topbar-end-user-menu>
      </div>
    </div>
  </div>
</div>
