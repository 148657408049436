/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component } from '@angular/core';
import { LibTableComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-key-values-table',
  templateUrl: './app-key-values-table.component.html',
  styleUrls: ['./app-key-values-table.component.scss'],
})
export class AppKeyValueTableComponent extends LibTableComponent { }
