<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <nz-input-group>
      <div nz-row>
        <div nz-col nzSpan="12">
          <input
            nz-input
            [id]="field.code"
            [(ngModel)]="latitude"
            (change)="inputChanged2()"
            placeholder="latitud"
            autocomplete="off"
            [readOnly]="disabled()"
            [disabled]="disabled()" />
        </div>
        <div nz-col nzSpan="12">
          <input
            nz-input
            [id]="field.code"
            [(ngModel)]="longitude"
            (change)="inputChanged2()"
            placeholder="longitud"
            autocomplete="off"
            [readOnly]="disabled()"
            [disabled]="disabled()" />
        </div>
      </div>
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
<div>
  <google-map
    height="500px"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    (mapClick)="clickOnMap($event)">
    <map-marker
      #markerElem
      [position]="marker?.position"
      [label]="marker?.label"
      [title]="marker?.title"
      [options]="marker?.options"
      (mapClick)="openInfo(markerElem, '')">
    </map-marker>
    <map-info-window>{{ infoContent }}</map-info-window>
  </google-map>
</div>
