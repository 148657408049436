<ng-container *ngIf="visible()">
  <button
    *ngIf="action?.actionType === 'CHECK'"
    type="button"
    nz-button
    nz-tooltip
    [nzTooltipTitle]="action.actionTitle"
    nzTooltipPlacement="topCenter"
    [disabled]="disabled()"
    (mousedown)="onActivate()">
    <app-icon [iconName]="isActive ? 'check' : 'uncheck'"></app-icon>
    <span>&nbsp; {{ action.actionTitle }}</span>
  </button>
  <ng-container *ngIf="action?.actionType === 'STANDARD'">
    <button
      *ngIf="!confirm"
      type="button"
      nz-button
      nz-tooltip
      [nzTooltipTitle]="action.actionTitle"
      nzTooltipPlacement="topCenter"
      [disabled]="disabled()"
      (mousedown)="onActivate()">
      <app-icon [iconName]="action?.iconName"></app-icon>
      <span *ngIf="action.action?.iconName === '' || action.actionType === 'SELECT'">
        &nbsp; {{ action.actionTitle }}
      </span>
    </button>
    <button
      *ngIf="confirm"
      type="button"
      nz-button
      nz-tooltip
      [nzTooltipTitle]="action.actionTitle"
      nzTooltipPlacement="topCenter"
      [disabled]="disabled()"
      nz-popconfirm
      [nzPopconfirmTitle]="confirm"
      (nzOnConfirm)="onActivate()"
      nzPopconfirmPlacement="leftTop">
      <app-icon [iconName]="action?.iconName"></app-icon>
      <span *ngIf="action.action?.iconName === '' || action.actionType === 'SELECT'">
        &nbsp; {{ action.actionTitle }}
      </span>
    </button>
  </ng-container>
</ng-container>
