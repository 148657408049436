<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
      <input
        nz-input
        [id]="field.code"
        [(ngModel)]="value"
        (change)="inputChanged()"
        (keyup)="inputTyped()"
        [readOnly]="disabled()"
        [placeholder]="placeholder()"
        autocomplete="off"
        [attr.maxlength]="maxLength()"
        [disabled]="disabled()" />
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch (click)="openSearch()">
          <app-icon iconName="search"></app-icon>
        </button>
      </ng-template>
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
