import { Component, ViewChild, ElementRef, computed, ChangeDetectionStrategy } from '@angular/core';
import yn from 'yn';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { formatNumber, formatCreditCard } from '../../utilities';

const regExp = {
  CURRENCY: /^-?(0|[1-9][0-9]*)(\.[0-9]{0,2})?$/,
  NUMBER: /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/,
  DECIMAL: /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/,
  INTEGER: /^-?(0|[1-9][0-9]*)?$/,
  NATURAL: /^([0-9]*)?$/,
  CREDITCARD: /^([1-9][0-9]{0,15})?$/,
};

@Component({
  selector: 'app-number',
  templateUrl: './app-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNumberComponent extends FieldComponent {
  preffix: string = null;
  numberType: string;
  separators = computed(() => yn(this.customAttributes().useSeparators ?? true));
  reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  override start() {
    super.start();
    this.preffix = (this.type() === this.formConfig.fieldTypes.currency) ? '$' : null;
    this.numberType = (this.minValue() >= 0) ? 'NATURAL' : this.type();
    this.reg = regExp[this.numberType];
  }

  override focus() { this.inputElement && this.inputElement.nativeElement.focus(); }

  makeReady(): void {
    if (this.value()?.charAt(this.value().length - 1) === '.' || this.value() === '-') {
      this.value.set(this.value().slice(0, -1));
    }
    this.updateObject();
    this.onChangeContent();
  }

  setValueNativeInput(formatedValue) {
    this.value.set(formatedValue);
    if (this.inputElement?.nativeElement) {
      this.inputElement!.nativeElement.value = formatedValue ?? 0;
    }
  }

  formatNumber(value) {
    if (this.type() === this.formConfig.fieldTypes.creditCard) {
      return formatCreditCard(value);
    } else if (this.type() === this.formConfig.fieldTypes.natural) {
      return value;
    }
    return (this.separators) ? formatNumber(value) : value;
  }

  override updateObject() {
    const value = this.value().replace(/,/g, '');
    if (value.length > 0) {
      this.field.setValue(isNaN(+value) ? value : +value, false);
    } else {
      this.field.setValue('', false);
    }
  }

  override updateValue() {
    this.setValueNativeInput(this.formatNumber(this.field.value));
  }

  onChangeModel(value: string): void {
    let formatedValue = this.value();
    let rawValue = value?.replace(/[^0-9-,.]+/g, '') ?? '';
    rawValue = rawValue.replace(/,/g, '');
    const suffix = (rawValue?.charAt(rawValue.length - 1) === '.') ? '.' : '';
    const isNumber = !isNaN(+rawValue)
    if (rawValue === '-' && this.numberType !== 'NATURAL') {
      formatedValue = '-';
    } else if (isNumber && (rawValue === '' || (this.reg?.test(rawValue)))) {
      formatedValue = `${this.formatNumber(rawValue)}${suffix}`;
    }
    this.setValueNativeInput(formatedValue);
    this.updateObject();
    this.onInputChange();
  }
}
