<ng-container [ngSwitch]="customAttributes().displayType">
  <ng-container *ngSwitchCase="'card'">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-radio-group [id]="field.code" [(ngModel)]="value" (ngModelChange)="inputChanged()">
          <label nz-radio *ngFor="let option of options()" [nzValue]="option.fieldOptionId" [nzDisabled]="disabled()">{{
            option.fieldOptionValue
          }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
  <ng-container *ngSwitchCase="'button'">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-radio-group
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem 1rem;
            margin-top: 1rem;
          "
          [id]="field.code"
          [(ngModel)]="value"
          (ngModelChange)="inputChanged()"
          nzButtonStyle="solid">
          <label
            class="labelQuestion"
            style="
              display: flex;
              min-height: 8rem;
              aspect-ratio: 1.5/1;
              border-radius: 1rem;
              justify-content: center;
              align-items: center;
              text-align: center;
              height: 10vw;
              border: none;
              box-shadow: 2px 3px 10px lightgray;
            "
            nz-radio-button
            *ngFor="let option of options()"
            [nzValue]="option.fieldOptionId"
            [nzDisabled]="disabled()"
            >{{ option.fieldOptionValue }}</label
          >
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <div *ngIf="options()?.length === 0 && customAttributes().emptyMessage">
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="customAttributes().emptyMessage"></nz-empty>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'questionnaireOpt'">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-radio-group
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem 1rem;
            margin-top: 1rem;
          "
          [id]="field.code"
          [(ngModel)]="value"
          (ngModelChange)="inputChanged()"
          nzButtonStyle="solid">
          <label
            class="labelQuestion"
            style="
              display: flex;
              min-height: 8rem;
              aspect-ratio: 1.5/1;
              border-radius: 1rem;
              justify-content: center;
              align-items: center;
              text-align: center;
              height: 10vw;
              border: none;
              box-shadow: 2px 3px 10px lightgray;
            "
            nz-radio-button
            *ngFor="let option of options()"
            [nzValue]="option.fieldOptionId"
            [nzDisabled]="disabled()"
            >{{ option.fieldOptionValue }}</label
          >
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </ng-container>

  <ng-container *ngSwitchCase="'multiCheckWithIcon'">
    <nz-form-item class="radioButtonWithIcon">
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-radio-group [id]="field.code" [(ngModel)]="value" (ngModelChange)="inputChanged()" nzButtonStyle="solid">
          <label
            class="labelQuestion"
            [class]="optionsChecked().includes(option.fieldOptionId) ? 'checked' : ''"
            nz-radio-button
            *ngFor="let option of options()"
            [nzValue]="option.fieldOptionId"
            [nzDisabled]="disabled()"
            >{{ option.fieldOptionValue }}</label
          >
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-radio-group [id]="field.code" [(ngModel)]="value" (ngModelChange)="inputChanged()">
          <label nz-radio *ngFor="let option of options()" [nzValue]="option.fieldOptionId" [nzDisabled]="disabled()">{{
            option.fieldOptionValue
          }}</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</ng-container>
