import { Component, Injector } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

const frm = {
  name: 'userDetail',
  states: { EDIT: 'EDIT' },
  sections: { editUserSection: 'editUserSection' },
  subsections: { generalInformationSubsection: 'generalInformationSubsection' },
  fields: {
    userId: 'userId',
    userName: 'userName',
    userLastName: 'userLastName',
    identificationType: 'identificationType',
    userDocumentNumber: 'userDocumentNumber',
    userCompany: 'userCompany',
    userRole: 'userRole',
    userEmail: 'userEmail',
    userPhone: 'userPhone',
    userOrganizationType: 'userOrganizationType',
    userCellPhone: 'userCellPhone',
    userAvatar: 'userAvatar',
    profileName: 'profileName',
  },
};
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: [
    './topbar.component.scss',
    '../../../templates/form.scss',
  ],
})
export class TopbarComponent extends AppFormComponent {
  userName: any;

  constructor(private themeService: ThemeService, private injector: Injector) {
    super(injector);
  }

  override preStart() { this.name = frm.name; }

  override start() {
    super.start();
    this.userName = `${this.getFieldValue(frm.fields.userName)} ${this.getFieldValue(
      frm.fields.userLastName,
    )}`;
    // const userInfo = JSON.parse(localStorage.getItem('user'));
    const data = {
      userName: this.getFieldValue(frm.fields.userName),
      userLastName: this.getFieldValue(frm.fields.userLastName),
      billingPlan: 'Professional',
      email: this.getFieldValue(frm.fields.userEmail),
      phone: this.getFieldValue(frm.fields.userCellPhone)?.[1],
      role: this.getFieldValue(frm.fields.userRole),
      avatar: this.getFieldValue(frm.fields.userAvatar),
      profileName: this.getFieldValue(frm.fields.profileName),
    };
    this._eventEmiter.next('profileObtained', data);
  }

  referralsClick() {
    // this._formOperationService.openForm(null, { name: item.form });
  }

  toggleTheme(): void {
    this.themeService.toggleTheme().then();
  }
}
