import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class AppLoggerService {
  log(message) {
    console.log(message);
  }

  debug(message) {
    console.log(message);
  }
};
