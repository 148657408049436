<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <textarea
      nz-input
      #inputElement
      [id]="field.code"
      [nzAutosize]="{
        minRows: customAttributes().rowNumber ?? rows,
        maxRows: customAttributes().maxRows ?? 10
      }"
      [(ngModel)]="value"
      [placeholder]="placeholder()"
      [readOnly]="disabled()"
      (change)="inputChanged()"
      (keyup)="inputTyped()"
      [attr.maxlength]="maxLength()"
      [disabled]="disabled()"></textarea>
  </nz-form-control>
</nz-form-item>
