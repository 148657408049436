/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { LibTableComponent } from 'tuain-ng-forms-lib';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';

const SEARCH_ICON = 'SEARCH';
const CHECKED_ICON = 'CHECKED';
const UNCHECKED_ICON = 'UNCHECKED';
const MAX_INLINE_ITEMS = 3;
@Component({
  selector: 'app-multi-line-table',
  templateUrl: './app-multi-line-table.component.html',
  styleUrls: ['./app-multi-line-table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMultiLineTableComponent extends LibTableComponent {
  reverseActions: any[] = [];
  secondaryActions: any[] = null;
  multipleActions: boolean = false;
  defaultAction: any = null;
  leftFields: any[];
  rightFields: any[];
  avatarField: any;
  indicatorField: any;
  tableStyle: any;
  leftStyle: any;
  rightStyle: any;
  noLines: string;

  currentRecord: any;
  private nzContextMenuService: NzContextMenuService
  searchIcon: string;
  selectedIcon: string;
  unselectedIcon: string;
  actionColorNames: string[] = [
    'secondary', 'danger', 'success', 'tertiary', 'primary', 'warning',
    'light', 'medium', 'dark'];

  visibleColumns: any[];
  activeColumnSearch: any;
  activeSearchText: string;
  selectionSortable: boolean;
  selectionColumn: any;

  inlineActIcn = {};

  constructor(
    injector: Injector
  ) {
    super();
    this.nzContextMenuService = injector.get(NzContextMenuService);
  }

  override start() {
    super.start();
    this.searchIcon = SEARCH_ICON;
    this.selectedIcon = CHECKED_ICON;
    this.unselectedIcon = UNCHECKED_ICON;
    this.table.setTableAppend(false);
    this.inlineActions?.forEach((act) => { this.inlineActIcn[act?.actionCode] = act?.iconName; });
    if (this.inlineActions?.length === 1) {
      this.defaultAction = this.inlineActions?.[0]?.actionCode;
    } else {
      this.defaultAction = this.table?.getCustomAttribute('defaultAction');
    }
    this.multipleActions = this.inlineActions?.length > 1;
    const reverseActions = this.inlineActions.slice().reverse();
    if (this.inlineActions?.length > MAX_INLINE_ITEMS) {
      this.reverseActions = [];
      this.reverseActions.push(...reverseActions.slice(-(MAX_INLINE_ITEMS - 1)));
      this.secondaryActions = reverseActions.slice(0, -(MAX_INLINE_ITEMS - 1));
    } else {
      this.reverseActions = reverseActions;
      // this.reverseActions?.pop();
    }
    const leftFieldNames = this.table?.getCustomAttribute('leftFields');
    const rightFieldNames = this.table?.getCustomAttribute('rightFields');
    const avatarFieldName = this.table?.getCustomAttribute('avatarField');
    const indicatorFieldName = this.table?.getCustomAttribute('indicatorField');
    this.tableStyle = this.table?.getCustomAttribute('styles') ?? '';
    this.leftStyle = this.table?.getCustomAttribute('leftStyles') ?? '';
    this.rightStyle = this.table?.getCustomAttribute('rightStyles') ?? '';

    const frmProperties = this.table.getCustomAttribute('properties') ?? {};
    const { lines } = frmProperties;
    this.avatarField = this.table?.columns?.find(col => col.fieldCode === avatarFieldName);
    this.indicatorField = this.table?.columns?.find(col => col.fieldCode === indicatorFieldName);
    this.leftFields = [];
    leftFieldNames?.forEach((fieldName) => {
      const column = this.table?.columns?.find(col => col.fieldCode === fieldName);
      column && this.leftFields?.push(column);
    });
    this.rightFields = [];
    rightFieldNames?.forEach((fieldName) => {
      const column = this.table?.columns?.find(col => col.fieldCode === fieldName);
      column && this.rightFields.push(column);
    });
    this.noLines = lines;
  }

  selectRecord(recordId) {
    const selectedRecords = this.table.getSelectedRecords();
    if (!selectedRecords.includes(recordId)) {
      this.table.unSelectAll();
    }
    this.table.notifyRecordSelection(recordId);
  }

  execDefaultAction(record) {
    this.defaultAction && this.execAction({ actionCode: this.defaultAction }, record);
  }

  inlineActionSelected(event) {
    const { actionCode, recordId, recordData } = event;
    this.execAction({ actionCode }, { recordData, recordId });
  }

  execAction(action, record) {
    if (action && action?.actionCode && record) {
      this.tableActionSelected({ actionCode: action?.actionCode, ...record });
    }
  }

  openSearch(column, visible) {
    this.activeColumnSearch = visible ? column : null;
    this.activeSearchText = this.activeColumnSearch?.filter?.values?.[0] ?? '';
  }

  search() {
    this.activeColumnSearch && this.table.addColumnFilter(this.activeColumnSearch.fieldCode, [this.activeSearchText], 'HAS');
    this.activeColumnSearch.filterVisible = false;
  }

  reset() {
    this.activeSearchText = '';
    this.activeColumnSearch && this.table.removeColumnFilter(this.activeColumnSearch.fieldCode);
    this.activeColumnSearch.filterVisible = false;
  }

  sortOrderHasChanged(column, direction) {
    this.tableColumnSort(column.fieldCode, direction);
  }

  clearFilter() {
    this.globalFilterString = '';
    this.globalFilterChanged();
  }

  onScroll(event) {
    const lastVisibleRecord = this.currentPage() * this.recordsPerPage();
    if (lastVisibleRecord < this.totalRecordsNumber()) {
      // this.changePage(this.currentPage() + 1);
      this.table.visibleRecords = this.table?.tableRecords;
    }
  }

  isVisible(action, record) {
    const { recordData, recordId } = record;
    const fieldRestriction = action.restrictedOnField;
    if (
      !fieldRestriction ||
      recordData[fieldRestriction] === null ||
      recordData[fieldRestriction] === undefined
    ) {
      return true;
    }
    const actOper = action.restrictedOnOperator;
    const actRequiredValue = action.restrictedOnValue;
    const fieldValue = recordData[fieldRestriction] ?? '';
    return (
      (actOper === '==' && fieldValue === actRequiredValue) ||
      (actOper === '!=' && fieldValue !== actRequiredValue)
    );
  }
}
