<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <input
      autocomplete="off"
      nz-input
      type="email"
      [id]="field.code"
      [(ngModel)]="value"
      (change)="inputChanged()"
      (keyup)="inputTyped()"
      [readOnly]="disabled()"
      [placeholder]="placeholder()"
      [attr.maxlength]="maxLength()"
      [disabled]="disabled()" />
  </nz-form-control>
</nz-form-item>
