<nz-form-item>
  <nz-form-control
    [nzValidateStatus]="onValidation() ? 'validating' : errorType()"
    [nzErrorTip]="errorMessage()"
    nzErrorTip="{{ errorMessage() }}">
    <nz-select
      [id]="field.code"
      nzMode="tags"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      nzShowSearch
      [nzPlaceHolder]="placeholder()"
      [nzAllowClear]="!required()"
      [nzNotFoundContent]=""
      [disabled]="disabled()">
      <nz-option
        *ngFor="let option of options()"
        [nzLabel]="option.fieldOptionValue"
        [nzValue]="option.fieldOptionId"
        [nzDisabled]="disabled()"></nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
