export const iconDictionary = {
  awesome: {
    admin: ['fas', 'cogs'],
    agent: ['fas', 'shop'],
    agentgroup: ['fas', 'people-roof'],
    alert: ['fas', 'bell'],
    analytics: ['fas', 'chart-pie'],
    arrowDown: ['fas', 'chevron-down'],
    assignCase: ['fas', 'crosshairs'],
    award: ['fas', 'award'],
    back: ['fas', 'arrow-left'],
    balanced: ['fas', 'scale-balanced'],
    bank: ['fas', 'bank'],
    bank2: ['fas', 'building-columns'],
    barcode: ['fas', 'barcode'],
    barcode2: ['fas', 'barcode-read'],
    barcode3: ['fas', 'barcode-scan'],
    barcode4: ['fas', 'scanner-gun'],
    batchfile: ['fas', 'wpforms'],
    bell: ['far', 'bell'],
    rotateRight: ['fas', 'rotate-right'],
    reload: ['fas', 'rotate-right'],
    billpayment: ['fas', 'file-invoice-dollar'],
    bolt: ['fas', 'bolt'],
    briefcase: ['fas', 'briefcase'],
    bus: ['fas', 'bus'],
    camera2: ['fas', 'camera'],
    campaign: ['fas', 'tags'],
    car: ['fas', 'car'],
    cardbalance: ['fas', 'comment-dollar'],
    caretdown: ['fas', 'caret-down'],
    cash: ['fas', 'money-bill'],
    cash2: ['far', 'money-bill-1'],
    cashregister: ['fas', 'cash-register'],
    category: ['fas', 'folder-tree'],
    changepassword: ['fas', 'key'],
    chart: ['fas', 'chart-column'],
    chart2: ['fas', 'chart-line'],
    chart3: ['fas', 'chart-simple'],
    chartarea: ['fas', 'chart-area'],
    chartpie: ['fas', 'chart-pie'],
    checkcircle: ['fas', 'check-circle'],
    check: ['fas', 'check'],
    checked: ['far', 'square-check'],
    circleDollarToSlot: ['fas', 'circle-dollar-to-slot'],
    circleError: ['far', 'circle-xmark'],
    circleInfo: ['far', 'circle-info'],
    circleplus: ['fas', 'circle-plus'],
    circlePlus: ['fas', 'circle-plus'],
    circleQuestion: ['far', 'circle-question'],
    circleSuccess: ['far', 'circle-check'],
    circleWarning: ['far', 'circle-exclamation'],
    clean: ['fas', 'eraser'],
    clip: ['fas', 'paperclip'],
    clock: ['fas', 'clock'],
    closeshift: ['fas', 'shop-lock'],
    code: ['fas', 'code'],
    coins: ['fas', 'coins'],
    comment: ['far', 'comment'],
    comment2: ['fas', 'comments'],
    comments: ['fas', 'comments'],
    compensation: ['fas', 'hand-holding-dollar'],
    computer: ['fas', 'desktop'],
    creditcard: ['far', 'credit-card'],
    creditcard2: ['fas', 'credit-card'],
    cross: ['fas', 'cross'],
    customerservice: ['fas', 'bell-concierge'],
    dashboard: ['fas', 'gauge'],
    default: ['fas', 'ellipsis'],
    delete: ['fas', 'trash-can'],
    deploy: ['fas', 'cloud-bolt'],
    deposit: ['fas', 'circle-dollar-to-slot'],
    deposit2: ['fas', 'piggy-bank'],
    design: ['fas', 'compass-drafting'],
    disable: ['fas', 'toggle-off'],
    droplet: ['fas', 'droplet'],
    edit: ['fas', 'pen-to-square'],
    education: ['fas', 'graduation-cap'],
    ellipsis: ['fas', 'ellipsis'],
    verticalEllipsis: ['fas', 'ellipsis-v'],
    enable: ['fas', 'toggle-on'],
    endpoint: ['fas', 'plug'],
    event: ['fas', 'bomb'],
    exclamation: ['fas', 'exclamation-circle'],
    exit: ['fas', 'arrow-right-from-bracket'],
    externaluser: ['fas', 'house-chimney-user'],
    eye: ['fas', 'eye'],
    eyeslash: ['fas', 'eye-slash'],
    fax: ['fas', 'fax'],
    fileinvoice: ['fas', 'file-invoice'],
    financial: ['fas', 'money-bill-1-wave'],
    formdesign: ['fas', 'table-list'],
    gear: ['far', 'gear'],
    genericMessage: ['fas', 'message'],
    glasschart: ['fas', 'magnifying-glass-chart'],
    glasses: ['fas', 'glasses'],
    globe: ['fas', 'globe'],
    goto: ['fas', 'arrow-right'],
    group: ['fas', 'users-between-lines'],
    hand: ['far', 'hand'],
    hashtag: ['fas', 'hashtag'],
    headset: ['fas', 'headset'],
    heart2: ['fas', 'heart'],
    heartpulse: ['fas', 'heart-pulse'],
    historial: ['fas', 'clock-rotate-left'],
    inactiveagent: ['fas', 'shop-slash'],
    inactivestore: ['fas', 'store-slash'],
    inbox: ['fas', 'inbox'],
    integrationuser: ['fas', 'user-shield'],
    internaluser: ['fas', 'id-badge'],
    invoice: ['fas', 'file-invoice-dollar'],
    key: ['fas', 'key'],
    keyboard: ['fas', 'keyboard-o'],
    kpi: ['fas', 'weight-scale'],
    landline: ['fas', 'phone-flip'],
    landline2: ['fas', 'phone'],
    lightbulb: ['far', 'lightbulb'],
    listboard: ['fas', 'clipboard-list'],
    listcheck: ['fas', 'list-check'],
    lock: ['fas', 'lock'],
    login: ['fas', 'arrow-right-to-bracket'],
    logout: ['fas', 'sign-out'],
    location2: ['fas', 'location-dot'],
    menubars: ['fas', 'bars'],
    messageNews: ['fas', 'envelope-square'],
    mobile: ['fas', 'mobile'],
    mobile2: ['fas', 'mobile-screen-button'],
    mobile3: ['fas', 'mobile-retro'],
    moneyBill: ['fas', 'money-bill-1'],
    moneyBills: ['fas', 'money-bill-1-wave'],
    moneyBillTransfer: ['fas', 'money-bill-transfer'],
    moneycheck: ['fas', 'money-check'],
    moneyCheckDollar: ['fas', 'money-check-dollar'],
    moneyservices: ['fas', 'landmark'],
    network: ['fas', 'circle-nodes'],
    networkwired: ['fas', 'network-wired'],
    noeye: ['eye-invisible'],
    notequal: ['fas', 'not-equal'],
    otp: ['far', 'comment-dots'],
    payment: ['fas', 'money-check-dollar'],
    person: ['fas', 'user'],
    phone: ['fas', 'mobile-screen-button'],
    piggybank: ['fas', 'piggy-bank'],
    playawsm: ['fas', 'play'],
    plugcheck: ['fas', 'plug-circle-check'],
    plugCircleCheck: ['fas', 'plug-circle-check'],
    pos: ['fas', 'mobile-retro'],
    product: ['fas', 'cubes'],
    profiles: ['fas', 'user-gear'],
    provider: ['far', 'id-card'],
    qrcode: ['fas', 'qrcode'],
    questionnaire: ['fas', 'file-circle-question'],
    questions: ['fas', 'clipboard-question'],
    quickpay: ['fas', 'money-bill-transfer'],
    reaction: ['fas', 'thumbs-up'],
    reactivate: ['fas', 'arrow-rotate-right'],
    receipt: ['fas', 'receipt'],
    reconciliation: ['fas', 'code-compare'],
    rectanglelist: ['far', 'rectangle-list'],
    rectanglelist2: ['far', 'rectangle-list'],
    reference: ['far', 'keyboard'],
    reports: ['fas', 'chart-line'],
    reservationNews: ['fas', 'chair-office'],
    reset: ['fas', 'bolt'],
    reverse: ['fas', 'clock-rotate-left'],
    rocket: ['fas', 'rocket'],
    school: ['fas', 'school'],
    search: ['fas', 'magnifying-glass'],
    security: ['fas', 'shield-halved'],
    send: ['far', 'paper-plane'],
    send2: ['fas', 'paper-plane'],
    shield: ['fas', 'shield'],
    shop: ['fas', 'shop'],
    signature: ['fas', 'signature'],
    smartphone: ['fas', 'mobile-screen'],
    steps: ['fas', 'shoe-prints'],
    store: ['fas', 'store'],
    suitcase: ['fas', 'suitcase'],
    system: ['fas', 'server'],
    takeTicket: ['fas', 'thumbtack'],
    textfile: ['fas', 'file-text-o'],
    thirdparty: ['fas', 'people-arrows'],
    thumbdown: ['fas', 'thumbs-o-down'],
    thumbsup: ['far', 'thumbs-up'],
    thumbup: ['fas', 'thumbs-o-up'],
    ticket: ['fas', 'ticket'],
    tools: ['fas', 'screwdriver-wrench'],
    transfer: ['fas', 'money-bill-transfer'],
    turnon: ['fas', 'land-mine-on'],
    tv: ['fas', 'tv'],
    uncheck: ['fas', 'xmark'],
    unchecked: ['far', 'square'],
    user: ['far', 'user'],
    usergroup: ['fas', 'users-rectangle'],
    userlock: ['fas', 'user-lock'],
    usermgr: ['fas', 'users-gear'],
    users: ['fas', 'users'],
    view: ['far', 'eye'],
    viewfinder: ['fas', 'users-viewfinder'],
    wallet: ['fas', 'wallet'],
    warning: ['fas', 'triangle-exclamation'],
    water: ['fas', 'droplet'],
    wholesaler: ['fas', 'cash-register'],
    wifi: ['fas', 'wifi'],
    win: ['fas', 'trophy'],
    withdrawal: ['fas', 'money-bills'],
    xmark: ['far', 'rectangle-xmark'],
  },
  icmn: {
    activate: 'icmn-checkmark',
    add: 'icmn-plus',
    adduser: 'icmn-user-plus',
    approve: 'icmn-checkmark',
    atach: 'icmn-atachment',
    autonewaffiliation: 'icmn-profile',
    bpmcodes: 'icmn-file-text2',
    camaracomercio: 'icmn-book',
    cancel: 'icmn-cross',
    cart: 'icmn-cart',
    chat: 'icmn-bubbles4',
    check: 'icmn-checkmark',
    close: 'icmn-ungroup',
    codes: 'icmn-file-text',
    coin: 'icmn-coin-dollar',
    config: 'icmn-wrench',
    contacts: 'icmn-address-book',
    copy: 'icmn-copy',
    cut: 'icmn-scissors',
    default: 'icmn-home',
    ionDelete: 'icmn-bin',
    deliver: 'icmn-truck',
    detail: 'icmn-binoculars',
    down: 'icmn-circle-down',
    download: 'icmn-cloud-download',
    downloadset: 'icmn-cloud-download',
    earth: 'icmn-earth',
    edit: 'icmn-pencil',
    enter: 'icmn-enter',
    entity: 'icmn-library',
    excel: 'icmn-file-excel',
    facebook: 'icmn-facebook',
    filter: 'icmn-filter',
    fire: 'icmn-fire',
    folder: 'icmn-folder-open',
    formCreate: 'icmn-file-empty',
    formEdit: 'icmn-pencil2',
    formrecord: 'icmn-insert-template',
    formView: 'icmn-file-text2',
    gauge: 'icmn-meter',
    gift: 'icmn-gift',
    google: 'icmn-google',
    graph: 'icmn-stats-dots',
    happy: 'icmn-happy',
    heart: 'icmn-heart',
    heartbroken: 'icmn-heart-broken',
    holder: 'icmn-profile',
    hourglass: 'icmn-hour-glass',
    inactivate: 'icmn-cross',
    inboxempty: 'icmn-drawer2',
    inboxfull: 'icmn-drawer',
    info: 'icmn-info',
    lab: 'icmn-lab',
    left: 'icmn-circle-left',
    link: 'icmn-link',
    linkedin: 'icmn-linkedin',
    list: 'icmn-list',
    location: 'icmn-location',
    lockDoc: 'icmn-lock',
    map: 'icmn-map',
    movedown: 'icmn-arrow-down',
    moveup: 'icmn-arrow-up',
    new: 'icmn-file-empty',
    newaffiliation: 'icmn-profile',
    notification: 'icmn-notification',
    office: 'icmn-office',
    pdf: 'icmn-file-pdf',
    picture: 'icmn-camera',
    pie: 'icmn-pie-chart',
    play: 'icmn-play2',
    power: 'icmn-switch',
    profile: 'icmn-profile',
    question: 'icmn-question',
    recharge: 'icmn-power-cord',
    redo: 'icmn-redo2',
    reject: 'icmn-cross',
    removeuser: 'icmn-user-minus',
    requests: 'icmn-stack',
    restart: 'icmn-file-empty',
    right: 'icmn-circle-right',
    sad: 'icmn-sad',
    sarlaft: 'icmn-binoculars',
    save: 'icmn-floppy-disk',
    screening: 'icmn-user-check',
    select: 'icmn-pushpin',
    selectHolder: 'icmn-user-check',
    selectrow: 'icmn-checkbox-unchecked',
    serverprocess: 'icmn-cloud-check',
    share: 'icmn-share',
    sortasc: 'icmn-sort-alpha-asc',
    sortdsc: 'icmn-sort-alpha-desc',
    spinner: 'icmn-spiiner3',
    star2: 'icmn-star-empty',
    stop: 'icmn-stop',
    table: 'icmn-table',
    tags: 'icmn-price-tags',
    television: 'icmn-tv',
    text: 'icmn-file-text',
    tree: 'icmn-tree',
    trophy: 'icmn-trophy',
    tune: 'icmn-equalizer',
    twitter: 'icmn-twitter',
    uncheck: 'icmn-cross',
    undo: 'icmn-undo2',
    unlock: 'icmn-unlocked',
    unselectrow: 'icmn-checkbox-checked',
    up: 'icmn-circle-up',
    upload: 'icmn-cloud-upload',
    validateAml: 'icmn-warning',
    verify: 'icmn-clipboard',
    view: 'icmn-eye',
    whatsapp: 'icmn-whatsapp',
    youtube: 'icmn-youtube',
    zipfile: 'icmn-file-zip',
    zoomin: 'icmn-zoom-in',
    zoomout: 'icmn-zoom-out',
  },
  fe: {
    alertCircle: 'fe fe-alert-circle',
    alertTriangle: 'fe fe-alert-triangle',
    atSign: 'fe fe-at-sign',
    book: 'fe fe-book-open',
    bookmark: 'fe fe-bookmark',
    calendar: 'fe fe-calendar',
    camera: 'fe fe-camera',
    clipboard: 'fe fe-clipboard',
    command: 'fe fe-command',
    cpu: 'fe fe-cpu',
    creditCard: 'fe fe-credit-card',
    database: 'fe fe-database',
    dollarSign: 'fe fe-dollar-sign',
    eventNews: 'fe fe-calendar',
    feather: 'fe fe-feather',
    filePlus: 'fe fe-file-plus',
    fileText: 'fe fe-file-text',
    globe: 'fe fe-globe',
    grid: 'fe fe-grid',
    hardDrive: 'fe fe-hard-drive',
    helpCircle: 'fe fe-help-circle',
    home: 'fe fe-home',
    image: 'fe fe-image',
    inbox: 'fe fe-inbox',
    layers: 'fe fe-layers',
    mail: 'fe fe-mail',
    menu: 'fe fe-menu',
    messageCircle: 'fe fe-message-circle',
    minus: 'fe fe-minus',
    minusCircle: 'fe fe-minus-circle',
    percent: 'fe fe-percent',
    phoneCall: 'fe fe-phone-call',
    pieChart: 'fe fe-pie-chart',
    pin: 'fe fe-map-pin',
    printer: 'fe fe-printer',
    shoppingBag: 'fe fe-shopping-bag',
    shoppingCart: 'fe fe-shopping-cart',
    slash: 'fe fe-slash',
    star: 'fe fe-star',
    tag: 'fe fe-tag',
    target: 'fe fe-target',
    trendingUp: 'fe fe-trending-up',
    truck: 'fe fe-truck',
    tv: 'fe fe-tv',
    type: 'fe fe-type',
    userCheck: 'fe fe-user-check',
    userPlus: 'fe fe-user-plus',
    userX: 'fe fe-user-x',
  },
  ionic: {
    activate: 'checkmark-outline',
    back: 'arrow-back-outline',
    ionDelete: 'trash-outline',
    edit: 'create-outline',
    inactivate: 'close-outline',
    learn: 'school-outline',
    lock: 'lock-closed-outline',
    new: 'document-outline',
    newuser: 'person-add-outline',
    paper: 'document-outline',
    print: 'print-outline',
    printer: 'print-outline',
    reset: 'repeat-outline',
    restart: 'refresh-outline',
    select: 'checkbox-outline',
    view: 'eye-outline',
    star3: 'star'
  },
};
