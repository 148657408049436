<nz-layout style="background: #f4f5f9">
  <nz-content>
    <div class="authContainer">
      <div class="containerInner">
        <div class="float-left nav">
          <img class="header-logo" src="{{ assetsBase }}/images/logos/horizontal-logo-pbb.png" />
        </div>

        <div nz-row>
          <div nz-col nzFlex="auto"></div>
          <div nz-col nzFlex="1200px">
            <div nz-row>
              <div nz-col nzSpan="16">
                <div class="cardBox widgetCard">
                  <!-- <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
                    [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
                    [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')"
                    class="containerRouter"> -->
                  <div class="containerRouter">
                    <router-outlet #outlet="outlet"></router-outlet>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8">
                <div class="cardBox widgetCard">
                  <!-- <app-swiper-slides [slideObjects]="slideObjects"></app-swiper-slides> -->
                  <app-swiper-slides></app-swiper-slides>
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzFlex="auto"></div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
