import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

import { FormBasedComponentsModule } from 'src/app/components/form-based/form-based.module';

import { LayoutAuthComponent } from './auth/auth.component';
import { LayoutLoginComponent } from './login/login.component';
import { LayoutSecurityComponent } from './security/security.component';
import { LayoutPasswordComponent } from './password/password.component';
import { LayoutMainComponent } from './main/main.component';
import { LayoutWelcomeComponent } from './welcome/welcome.component';
import { LayoutServicesComponent } from './services/services.component';
import { LayoutSimpleComponent } from './simple/simple.component';
import { LayoutAdminComponent } from './admin/admin.component';
import { LayoutOnboardComponent } from './onboard/onboard.component';
// ENDUSER
import { LayoutEndUserMainComponent } from './eumain/eu-main.component';
import { LayoutEndUserAuthComponent } from './euauth/eu-auth.component';

const COMPONENTS = [
  LayoutWelcomeComponent,
  LayoutAuthComponent,
  LayoutLoginComponent,
  LayoutSecurityComponent,
  LayoutPasswordComponent,
  LayoutMainComponent,
  LayoutServicesComponent,
  LayoutSimpleComponent,
  LayoutAdminComponent,
  LayoutEndUserMainComponent,
  LayoutEndUserAuthComponent,
  LayoutOnboardComponent,
];

@NgModule({
  imports: [SharedModule, FormsModule, FormBasedComponentsModule],
  providers: [],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class LayoutsModule { }
