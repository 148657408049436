export const moduleRoutes = {
  root: 'eu-home',
  childForms: {
    endUserHomePage: {
      data: { title: 'Inicio' },
      requireAuth: false,
      functions: [],
      route: 'endUserHomePage',
    },
  },
  layout: 'euhome',
  loadChildren: () => import('src/app/page-modules/end-user/eu-home/eu-home.module').then(m => m.EndUserHomeModule),
};
