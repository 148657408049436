<div style="height: 100vh; background: white;">
  <app-eu-form-branded-header [form]="form" [showMenu]="true"></app-eu-form-branded-header>
  <div style="display: flex; padding-block: 2rem; background: white;">
    <div class="euServiceGroups" nz-col nzSpan="8">
      <app-eu-side-menu></app-eu-side-menu>
    </div>
    <div class="euServiceGroups" nz-col nzSpan="16">
      <router-outlet id="main-content"></router-outlet>
    </div>
  </div>
</div>