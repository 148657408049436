import { Component, computed, Input, Output, EventEmitter, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { AppTableInlineActionComponent } from '../inline-action/inline-action.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
})
export class AppTableActionsDropdownComponent implements AfterViewInit {
  @Input() actions: any[];
  @Input() record;
  @Input() defaultAction: string = '';

  @Output() actionSelected = new EventEmitter();
  @ViewChildren(AppTableInlineActionComponent) inlineActions!: QueryList<AppTableInlineActionComponent>;

  visibleActions: number = 0;

  ngAfterViewInit(): void {
    this.visibleActions = this.calculateVisibility();
  }

  calculateVisibility() {
    let visibleActions = 0;
    const actions = this.inlineActions; // actions | inlineActions
    (actions?.length > 0) && actions.forEach(actionComponent => {
      if (actionComponent.visible()) {
        visibleActions++;
      }
    });
    return visibleActions;
  }

  actionInSetSelected(event) {
    this.actionSelected.emit(event);
  }
}