import { NgModule } from '@angular/core';
import { Routes, Route, RouterModule } from '@angular/router';
import { LayoutsModule } from 'src/app/layout-views/layouts.module';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { layoutModules, moduleRoot, formRoute } from 'src/app/services/component-pages.service';
import { LayoutAuthComponent } from 'src/app/layout-views/auth/auth.component';
import { LayoutLoginComponent } from 'src/app/layout-views/login/login.component';
import { LayoutSecurityComponent } from 'src/app/layout-views/security/security.component';
import { LayoutPasswordComponent } from './layout-views/password/password.component';
import { LayoutMainComponent } from 'src/app/layout-views/main/main.component';
import { LayoutWelcomeComponent } from 'src/app/layout-views/welcome/welcome.component';
import { LayoutServicesComponent } from 'src/app/layout-views/services/services.component';
import { LayoutSimpleComponent } from 'src/app/layout-views/simple/simple.component';
import { LayoutOnboardComponent } from './layout-views/onboard/onboard.component';

import { LayoutEndUserMainComponent } from './layout-views/eumain/eu-main.component';
import { LayoutEndUserAuthComponent } from './layout-views/euauth/eu-auth.component';

import { NotFoundComponent } from 'src/app/pages/auth/notfound/notfound.component';

const COMPONENTS = [NotFoundComponent];
const modules = {
  HOME: 'home',
  EUHOME: 'euhome',
  WELCOME: 'welcome',
  AUTH: 'auth',
  EUAUTH: 'euauth',
  LOGIN: 'login',
  SIMPLE: 'simple',
  SERVICES: 'services',
  SECURITY: 'security',
  MAIN: 'main',
  EUMAIN: 'eumain',
  ONBOARD: 'onboard',
};

function buildAppRoutes() {
  const appRoutes: Routes = [];
  appRoutes.push(layoutModules(modules.HOME)[0]);
  appRoutes.push(layoutModules(modules.EUHOME)[0]);
  appRoutes.push({ ...layoutModules(modules.WELCOME)[0], component: LayoutWelcomeComponent });
  appRoutes.push({ ...layoutModules(modules.AUTH)[0], component: LayoutAuthComponent });
  appRoutes.push({ ...layoutModules(modules.EUAUTH)[0], component: LayoutEndUserAuthComponent });
  appRoutes.push({ ...layoutModules(modules.LOGIN)[0], component: LayoutLoginComponent });
  appRoutes.push({ ...layoutModules(modules.ONBOARD)[0], component: LayoutOnboardComponent });
  // appRoutes.push({ ...layoutModules(modules.SIMPLE)[0], canActivate: [AuthGuardService], component: LayoutSimpleComponent });
  appRoutes.push({ ...layoutModules(modules.SERVICES)[0], canActivate: [AuthGuardService], component: LayoutServicesComponent });
  appRoutes.push({
    path: modules.SECURITY,
    canActivate: [AuthGuardService],
    component: LayoutSecurityComponent,
    loadChildren: layoutModules(modules.SECURITY)?.[0]?.loadChildren,
  });
  appRoutes.push({ path: '', redirectTo: formRoute('START'), pathMatch: 'full' });
  appRoutes.push({ path: '', canActivate: [AuthGuardService], component: LayoutMainComponent, children: layoutModules(modules.MAIN) });
  appRoutes.push({ path: '', component: LayoutEndUserMainComponent, children: layoutModules(modules.EUMAIN) });
  appRoutes.push({
    path: '**',
    component: LayoutAuthComponent,
    children: [{ path: '', component: NotFoundComponent, data: { title: 'Not Found' } }],
  });
  return appRoutes;
}

@NgModule({
  imports: [
    RouterModule.forRoot(buildAppRoutes(), {
      useHash: false,
      onSameUrlNavigation: 'ignore',
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      // relativeLinkResolution: 'corrected',
    }),
    LayoutsModule,
  ],
  providers: [],
  declarations: COMPONENTS,
  exports: [RouterModule],
})
export class AppRoutingModule { }
