import { Component, ChangeDetectionStrategy } from '@angular/core';

import { AppMultiLineTableComponent } from '../multi-line-table/app-multi-line-table.component';

@Component({
  selector: 'app-message-list-table',
  templateUrl: '../multi-line-table/app-multi-line-table.component.html',
  styleUrls: ['./app-message-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMessageListTableComponent extends AppMultiLineTableComponent {
  override onScroll(event) {}
}
