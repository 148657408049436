import { Component, Injector } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

@Component({
  selector: 'app-other-services',
  templateUrl: './other-services.component.html',
  styleUrls: [
    './other-services.component.scss',
    '../../../templates/form.scss',
  ]
})
export class OtherServicesComponent extends AppFormComponent {
  services: any[];
  activeIndex: number = 0;

  constructor(injector: Injector) {
    super(injector);
  }

  override preStart() { this.name = 'login'; }

  override start() {
    super.start();
    this.subscribeAppEvents('storeServices', serviceObject => { this.services = serviceObject.services; });
    this.subscribeAppEvents('txnGroupSelection', serviceObject => {
      this.activeIndex = serviceObject.index;
    });
  }

  activateServiceGroup(index) {
    this._eventManager.next('chgTxnActiveGroup', { index });
  }
}
