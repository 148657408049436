<div *ngIf="globalSearch()">
  <nz-input-group [nzAddOnBefore]="prefixIconSearch">
    <input
      nzType="text"
      nz-input
      [(ngModel)]="globalFilterString"
      (keyup)="globalFilterChanged()"
      (change)="globalFilterCompleted()"
      placeholder="Buscar..."
      autocomplete="off" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <i class="{{ searchIcon }}"></i>
  </ng-template>
</div>

<div class="formTable">
  <ng-container>
    <!-- *ngIf="multipleActions" -->
    <nz-list [id]="table.tableCode" class="list">
      <ng-container *ngFor="let record of visibleRecords()">
        <nz-list-item [class]="record?.selected ? 'highLighted' : ''">
          <ng-container *ngIf="avatarField?.fieldCode">
            <div *ngIf="avatarField" class="rowAvatar">
              <img [src]="record.recordData[avatarField.fieldCode]" />
            </div>
          </ng-container>
          <div nz-row style="align-items: center; width: 100%; grid-template-columns: repeat(3, 1fr); display: grid">
            <div nz-col style="text-align: center">
              <ng-container *ngIf="leftFields?.length > 0">
                <div *ngFor="let column of leftFields" [ngStyle]="leftStyle ?? ''">
                  <row-field
                    [column]="column"
                    [fieldCode]="column.fieldCode"
                    [fieldType]="column.fieldType"
                    [fieldValue]="record.recordData[column.fieldCode]"
                    [class]="column.fieldCode">
                  </row-field>
                </div>
              </ng-container>
            </div>
            <div nz-col style="text-align: center">
              <ng-container *ngIf="rightFields?.length > 0">
                <div *ngFor="let column of rightFields" [ngStyle]="rightStyle ?? ''">
                  <row-field
                    [column]="column"
                    [fieldCode]="column.fieldCode"
                    [fieldType]="column.fieldType"
                    [fieldValue]="record.recordData[column.fieldCode]"
                    [class]="column.fieldCode">
                  </row-field>
                </div>
              </ng-container>
            </div>
            <div nz-col style="margin: auto; padding-inline: 1rem; display: flex; gap: 0.75rem" class="inlineActions">
              <div *ngFor="let action of inlineActions; let i = index; let l = count">
                <div style="font-size: small; font-weight: normal">
                  <button
                    class="inlineCustomBtn"
                    (click)="execAction(action, record)"
                    [action]="action"
                    [recordData]="record?.recordData"
                    [recordId]="record?.recordId">
                    {{ action?.actionTitle }}
                    <ion-icon size="large" [name]="action?.iconName"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </nz-list-item>
      </ng-container>
    </nz-list>
  </ng-container>
</div>
