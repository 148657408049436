import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

function zeroPad(num, size) {
  return ('000000000' + num).substr(-size);
}
@Component({
  selector: 'app-timerange',
  templateUrl: './app-time-range.component.html',
  styles: [
    `
      .ant-slider-with-marks {
        margin-bottom: 44px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTimeRangeComponent extends FieldComponent {
  style: string;
  startTime: string;
  endTime: string;

  override start() {
    super.start();
    this.field.value || (this.field.value = []);
  }

  override updateObject() {
    this.value.set([this.startTime, this.endTime]);
    this.field.value = [this.startTime, this.endTime];
  }

  formatTime(timeData) {
    const [startTime, endTime] = timeData;
    return [startTime, endTime];
  }

  override updateValue() {
    this.value.set(this.field.value);
    if (Array.isArray(this.value())) {
      [this.startTime, this.endTime] = this.formatTime(this.value());
    } else {
      [this.startTime, this.endTime] = this.formatTime([null, null]);
    }
  }

  onChangeModel1(value): void {
    this.startTime = `${value}`;
    this.updateObject();
    this.onChangeContent();
  }

  onChangeModel2(value): void {
    this.endTime = `${value}`;
    this.updateObject();
    this.onChangeContent();
  }
}
