<!-- nzValidateStatus="warning" nzHasFeedback -->
<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-time-picker
      [id]="field.code"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      [nzDisabled]="disabled()"
      nzFormat="HH:mm"
      [nzMinuteStep]="10"
      [disabled]="disabled()">
    </nz-time-picker>
  </nz-form-control>
</nz-form-item>
