import * as moment from 'moment';
import { Component, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'app-daterange',
  templateUrl: './app-date-range.component.html',
  styles: [
    `
      nz-date-picker ::ng-deep .ant-calendar-picker {
        width: 100%;
      }
      nz-date-picker,
      nz-time-picker {
        width: 100%;
      }
      .form-control-error {
        z-index: 4;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDateRangeComponent extends FieldComponent {
  style: string;
  today = new Date();

  startValue: any | null;
  endValue: any | null;
  datePlaceHolder = ['Fecha inicial', 'Fecha final'];
  ranges: any;
  historyDepthDays: number

  override updateObject() {
    this.value.set([this.startValue, this.endValue]);
    this.field.value = [this.startValue, this.endValue];
  }

  override updateValue() {
    this.ranges = { general: [new Date(this?.minValue), new Date(this?.maxValue)], }
    this.historyDepthDays = moment(this?.maxValue).diff(moment(this?.minValue), 'd')
    this.value.set(this.field.value);
    if (Array.isArray(this.value())) {
      this.startValue = moment(this.value()[0]).toISOString();
      this.endValue = moment(this.value()[1]).toISOString();
    } else {
      const today = moment().toISOString()
      this.startValue = today;
      this.endValue = today;
      this.updateObject()
    }
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  onChange(ev: Date[]) {
    if (Array.isArray(ev)) {
      this.value.set(ev);
      this.startValue = moment(this.value()[0]).toISOString();
      this.endValue = moment(this.value()[1]).toISOString();
    }
    this.updateObject();
    this.onChangeContent();
  }
}
