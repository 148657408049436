export const environment = {
  production: true,
  authenticated: true,
  hmr: false,
  appConfig: {
    googleApiKey: 'AIzaSyA0fXpn7j_CHYd3OxmE3bXWJZDHf_eUIes',
    debug: false,
    liveConnection: false,
    serverEntryPoint: {
      protocol: 'https',
      server: 'api.cnb.bolivariano.com',
      port: '',
    },
  },
};
