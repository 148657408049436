export const moduleRoutes = {
  root: 'admin',
  childForms: {
    agentDevices: {
      data: { title: 'Admin / agentDevices' },
      requireAuth: false,
      route: 'agentDevices',
    },
    agentStores: {
      data: { title: 'Admin / agentStores' },
      requireAuth: false,
      route: 'agentStores',
    },
    agentUsers: {
      data: { title: 'Admin / agentUsers' },
      requireAuth: false,
      route: 'agentUsers',
    },
    agentReports: {
      data: { title: 'Admin / agentReports' },
      requireAuth: false,
      route: 'agentReports',
    },
  },
  layout: 'main',
  loadChildren: () => import('src/app/page-modules/admin/admin.module').then(m => m.AdminModule),
};
