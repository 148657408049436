
export const countries = [
  {
    name: 'Afghanistan',
    code: 93,
    isoCodes: 'AF',
    code3: 'AFG',
  },
  {
    name: 'Albania',
    code: 355,
    isoCodes: 'AL',
    code3: 'ALB',
  },
  {
    name: 'Algeria',
    code: 213,
    isoCodes: 'DZ',
    code3: 'DZA',
  },
  {
    name: 'American Samoa',
    code: '1-684',
    isoCodes: 'AS',
    code3: 'ASM',
  },
  {
    name: 'Andorra',
    code: 376,
    isoCodes: 'AD',
    code3: 'AND',
  },
  {
    name: 'Angola',
    code: 244,
    isoCodes: 'AO',
    code3: 'AGO',
  },
  {
    name: 'Anguilla',
    code: '1-264',
    isoCodes: 'AI',
    code3: 'AIA',
  },
  {
    name: 'Antarctica',
    code: 672,
    isoCodes: 'AQ',
    code3: 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    code: '1-268',
    isoCodes: 'AG',
    code3: 'ATG',
  },
  {
    name: 'Argentina',
    code: 54,
    isoCodes: 'AR',
    code3: 'ARG',
  },
  {
    name: 'Armenia',
    code: 374,
    isoCodes: 'AM',
    code3: 'ARM',
  },
  {
    name: 'Aruba',
    code: 297,
    isoCodes: 'AW',
    code3: 'ABW',
  },
  {
    name: 'Australia',
    code: 61,
    isoCodes: 'AU',
    code3: 'AUS',
  },
  {
    name: 'Austria',
    code: 43,
    isoCodes: 'AT',
    code3: 'AUT',
  },
  {
    name: 'Azerbaijan',
    code: 994,
    isoCodes: 'AZ',
    code3: 'AZE',
  },
  {
    name: 'Bahamas',
    code: '1-242',
    isoCodes: 'BS',
    code3: 'BHS',
  },
  {
    name: 'Bahrain',
    code: 973,
    isoCodes: 'BH',
    code3: 'BHR',
  },
  {
    name: 'Bangladesh',
    code: 880,
    isoCodes: 'BD',
    code3: 'BGD',
  },
  {
    name: 'Barbados',
    code: '1-246',
    isoCodes: 'BB',
    code3: 'BRB',
  },
  {
    name: 'Belarus',
    code: 375,
    isoCodes: 'BY',
    code3: 'BLR',
  },
  {
    name: 'Belgium',
    code: 32,
    isoCodes: 'BE',
    code3: 'BEL',
  },
  {
    name: 'Belize',
    code: 501,
    isoCodes: 'BZ',
    code3: 'BLZ',
  },
  {
    name: 'Benin',
    code: 229,
    isoCodes: 'BJ',
    code3: 'BEN',
  },
  {
    name: 'Bermuda',
    code: '1-441',
    isoCodes: 'BM',
    code3: 'BMU',
  },
  {
    name: 'Bhutan',
    code: 975,
    isoCodes: 'BT',
    code3: 'BTN',
  },
  {
    name: 'Bolivia',
    code: 591,
    isoCodes: 'BO',
    code3: 'BOL',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 387,
    isoCodes: 'BA',
    code3: 'BIH',
  },
  {
    name: 'Botswana',
    code: 267,
    isoCodes: 'BW',
    code3: 'BWA',
  },
  {
    name: 'Brazil',
    code: 55,
    isoCodes: 'BR',
    code3: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 246,
    isoCodes: 'IO',
    code3: 'IOT',
  },
  {
    name: 'British Virgin Islands',
    code: '1-284',
    isoCodes: 'VG',
    code3: 'VGB',
  },
  {
    name: 'Brunei',
    code: 673,
    isoCodes: 'BN',
    code3: 'BRN',
  },
  {
    name: 'Bulgaria',
    code: 359,
    isoCodes: 'BG',
    code3: 'BGR',
  },
  {
    name: 'Burkina Faso',
    code: 226,
    isoCodes: 'BF',
    code3: 'BFA',
  },
  {
    name: 'Burundi',
    code: 257,
    isoCodes: 'BI',
    code3: 'BDI',
  },
  {
    name: 'Cambodia',
    code: 855,
    isoCodes: 'KH',
    code3: 'KHM',
  },
  {
    name: 'Cameroon',
    code: 237,
    isoCodes: 'CM',
    code3: 'CMR',
  },
  {
    name: 'Canada',
    code: 1,
    isoCodes: 'CA',
    code3: 'CAN',
  },
  {
    name: 'Cape Verde',
    code: 238,
    isoCodes: 'CV',
    code3: 'CPV',
  },
  {
    name: 'Cayman Islands',
    code: '1-345',
    isoCodes: 'KY',
    code3: 'CYM',
  },
  {
    name: 'Central African Republic',
    code: 236,
    isoCodes: 'CF',
    code3: 'CAF',
  },
  {
    name: 'Chad',
    code: 235,
    isoCodes: 'TD',
    code3: 'TCD',
  },
  {
    name: 'Chile',
    code: 56,
    isoCodes: 'CL',
    code3: 'CHL',
  },
  {
    name: 'China',
    code: 86,
    isoCodes: 'CN',
    code3: 'CHN',
  },
  {
    name: 'Christmas Island',
    code: 61,
    isoCodes: 'CX',
    code3: 'CXR',
  },
  {
    name: 'Cocos Islands',
    code: 61,
    isoCodes: 'CC',
    code3: 'CCK',
  },
  {
    name: 'Colombia',
    code: 57,
    isoCodes: 'CO',
    code3: 'COL',
  },
  {
    name: 'Comoros',
    code: 269,
    isoCodes: 'KM',
    code3: 'COM',
  },
  {
    name: 'Cook Islands',
    code: 682,
    isoCodes: 'CK',
    code3: 'COK',
  },
  {
    name: 'Costa Rica',
    code: 506,
    isoCodes: 'CR',
    code3: 'CRI',
  },
  {
    name: 'Croatia',
    code: 385,
    isoCodes: 'HR',
    code3: 'HRV',
  },
  {
    name: 'Cuba',
    code: 53,
    isoCodes: 'CU',
    code3: 'CUB',
  },
  {
    name: 'Curacao',
    code: 599,
    isoCodes: 'CW',
    code3: 'CUW',
  },
  {
    name: 'Cyprus',
    code: 357,
    isoCodes: 'CY',
    code3: 'CYP',
  },
  {
    name: 'Czech Republic',
    code: 420,
    isoCodes: 'CZ',
    code3: 'CZE',
  },
  {
    name: 'Democratic Republic of the Congo',
    code: 243,
    isoCodes: 'CD',
    code3: 'COD',
  },
  {
    name: 'Denmark',
    code: 45,
    isoCodes: 'DK',
    code3: 'DNK',
  },
  {
    name: 'Djibouti',
    code: 253,
    isoCodes: 'DJ',
    code3: 'DJI',
  },
  {
    name: 'Dominica',
    code: '1-767',
    isoCodes: 'DM',
    code3: 'DMA',
  },
  {
    name: 'Dominican Republic',
    code: '1-809',
    isoCodes: '1-829',
    code3: '1-849',
  },
  {
    name: 'East Timor',
    code: 670,
    isoCodes: 'TL',
    code3: 'TLS',
  },
  {
    name: 'Ecuador',
    code: 593,
    isoCodes: 'EC',
    code3: 'ECU',
  },
  {
    name: 'Egypt',
    code: 20,
    isoCodes: 'EG',
    code3: 'EGY',
  },
  {
    name: 'El Salvador',
    code: 503,
    isoCodes: 'SV',
    code3: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    code: 240,
    isoCodes: 'GQ',
    code3: 'GNQ',
  },
  {
    name: 'Eritrea',
    code: 291,
    isoCodes: 'ER',
    code3: 'ERI',
  },
  {
    name: 'Estonia',
    code: 372,
    isoCodes: 'EE',
    code3: 'EST',
  },
  {
    name: 'Ethiopia',
    code: 251,
    isoCodes: 'ET',
    code3: 'ETH',
  },
  {
    name: 'Falkland Islands',
    code: 500,
    isoCodes: 'FK',
    code3: 'FLK',
  },
  {
    name: 'Faroe Islands',
    code: 298,
    isoCodes: 'FO',
    code3: 'FRO',
  },
  {
    name: 'Fiji',
    code: 679,
    isoCodes: 'FJ',
    code3: 'FJI',
  },
  {
    name: 'Finland',
    code: 358,
    isoCodes: 'FI',
    code3: 'FIN',
  },
  {
    name: 'France',
    code: 33,
    isoCodes: 'FR',
    code3: 'FRA',
  },
  {
    name: 'French Polynesia',
    code: 689,
    isoCodes: 'PF',
    code3: 'PYF',
  },
  {
    name: 'Gabon',
    code: 241,
    isoCodes: 'GA',
    code3: 'GAB',
  },
  {
    name: 'Gambia',
    code: 220,
    isoCodes: 'GM',
    code3: 'GMB',
  },
  {
    name: 'Georgia',
    code: 995,
    isoCodes: 'GE',
    code3: 'GEO',
  },
  {
    name: 'Germany',
    code: 49,
    isoCodes: 'DE',
    code3: 'DEU',
  },
  {
    name: 'Ghana',
    code: 233,
    isoCodes: 'GH',
    code3: 'GHA',
  },
  {
    name: 'Gibraltar',
    code: 350,
    isoCodes: 'GI',
    code3: 'GIB',
  },
  {
    name: 'Greece',
    code: 30,
    isoCodes: 'GR',
    code3: 'GRC',
  },
  {
    name: 'Greenland',
    code: 299,
    isoCodes: 'GL',
    code3: 'GRL',
  },
  {
    name: 'Grenada',
    code: '1-473',
    isoCodes: 'GD',
    code3: 'GRD',
  },
  {
    name: 'Guam',
    code: '1-671',
    isoCodes: 'GU',
    code3: 'GUM',
  },
  {
    name: 'Guatemala',
    code: 502,
    isoCodes: 'GT',
    code3: 'GTM',
  },
  {
    name: 'Guernsey',
    code: '44-1481',
    isoCodes: 'GG',
    code3: 'GGY',
  },
  {
    name: 'Guinea',
    code: 224,
    isoCodes: 'GN',
    code3: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    code: 245,
    isoCodes: 'GW',
    code3: 'GNB',
  },
  {
    name: 'Guyana',
    code: 592,
    isoCodes: 'GY',
    code3: 'GUY',
  },
  {
    name: 'Haiti',
    code: 509,
    isoCodes: 'HT',
    code3: 'HTI',
  },
  {
    name: 'Honduras',
    code: 504,
    isoCodes: 'HN',
    code3: 'HND',
  },
  {
    name: 'Hong Kong',
    code: 852,
    isoCodes: 'HK',
    code3: 'HKG',
  },
  {
    name: 'Hungary',
    code: 36,
    isoCodes: 'HU',
    code3: 'HUN',
  },
  {
    name: 'Iceland',
    code: 354,
    isoCodes: 'IS',
    code3: 'ISL',
  },
  {
    name: 'India',
    code: 91,
    isoCodes: 'IN',
    code3: 'IND',
  },
  {
    name: 'Indonesia',
    code: 62,
    isoCodes: 'ID',
    code3: 'IDN',
  },
  {
    name: 'Iran',
    code: 98,
    isoCodes: 'IR',
    code3: 'IRN',
  },
  {
    name: 'Iraq',
    code: 964,
    isoCodes: 'IQ',
    code3: 'IRQ',
  },
  {
    name: 'Ireland',
    code: 353,
    isoCodes: 'IE',
    code3: 'IRL',
  },
  {
    name: 'Isle of Man',
    code: '44-1624',
    isoCodes: 'IM',
    code3: 'IMN',
  },
  {
    name: 'Israel',
    code: 972,
    isoCodes: 'IL',
    code3: 'ISR',
  },
  {
    name: 'Italy',
    code: 39,
    isoCodes: 'IT',
    code3: 'ITA',
  },
  {
    name: 'Ivory Coast',
    code: 225,
    isoCodes: 'CI',
    code3: 'CIV',
  },
  {
    name: 'Jamaica',
    code: '1-876',
    isoCodes: 'JM',
    code3: 'JAM',
  },
  {
    name: 'Japan',
    code: 81,
    isoCodes: 'JP',
    code3: 'JPN',
  },
  {
    name: 'Jersey',
    code: '44-1534',
    isoCodes: 'JE',
    code3: 'JEY',
  },
  {
    name: 'Jordan',
    code: 962,
    isoCodes: 'JO',
    code3: 'JOR',
  },
  {
    name: 'Kazakhstan',
    code: 7,
    isoCodes: 'KZ',
    code3: 'KAZ',
  },
  {
    name: 'Kenya',
    code: 254,
    isoCodes: 'KE',
    code3: 'KEN',
  },
  {
    name: 'Kiribati',
    code: 686,
    isoCodes: 'KI',
    code3: 'KIR',
  },
  {
    name: 'Kosovo',
    code: 383,
    isoCodes: 'XK',
    code3: 'XKX',
  },
  {
    name: 'Kuwait',
    code: 965,
    isoCodes: 'KW',
    code3: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    code: 996,
    isoCodes: 'KG',
    code3: 'KGZ',
  },
  {
    name: 'Laos',
    code: 856,
    isoCodes: 'LA',
    code3: 'LAO',
  },
  {
    name: 'Latvia',
    code: 371,
    isoCodes: 'LV',
    code3: 'LVA',
  },
  {
    name: 'Lebanon',
    code: 961,
    isoCodes: 'LB',
    code3: 'LBN',
  },
  {
    name: 'Lesotho',
    code: 266,
    isoCodes: 'LS',
    code3: 'LSO',
  },
  {
    name: 'Liberia',
    code: 231,
    isoCodes: 'LR',
    code3: 'LBR',
  },
  {
    name: 'Libya',
    code: 218,
    isoCodes: 'LY',
    code3: 'LBY',
  },
  {
    name: 'Liechtenstein',
    code: 423,
    isoCodes: 'LI',
    code3: 'LIE',
  },
  {
    name: 'Lithuania',
    code: 370,
    isoCodes: 'LT',
    code3: 'LTU',
  },
  {
    name: 'Luxembourg',
    code: 352,
    isoCodes: 'LU',
    code3: 'LUX',
  },
  {
    name: 'Macau',
    code: 853,
    isoCodes: 'MO',
    code3: 'MAC',
  },
  {
    name: 'Macedonia',
    code: 389,
    isoCodes: 'MK',
    code3: 'MKD',
  },
  {
    name: 'Madagascar',
    code: 261,
    isoCodes: 'MG',
    code3: 'MDG',
  },
  {
    name: 'Malawi',
    code: 265,
    isoCodes: 'MW',
    code3: 'MWI',
  },
  {
    name: 'Malaysia',
    code: 60,
    isoCodes: 'MY',
    code3: 'MYS',
  },
  {
    name: 'Maldives',
    code: 960,
    isoCodes: 'MV',
    code3: 'MDV',
  },
  {
    name: 'Mali',
    code: 223,
    isoCodes: 'ML',
    code3: 'MLI',
  },
  {
    name: 'Malta',
    code: 356,
    isoCodes: 'MT',
    code3: 'MLT',
  },
  {
    name: 'Marshall Islands',
    code: 692,
    isoCodes: 'MH',
    code3: 'MHL',
  },
  {
    name: 'Mauritania',
    code: 222,
    isoCodes: 'MR',
    code3: 'MRT',
  },
  {
    name: 'Mauritius',
    code: 230,
    isoCodes: 'MU',
    code3: 'MUS',
  },
  {
    name: 'Mayotte',
    code: 262,
    isoCodes: 'YT',
    code3: 'MYT',
  },
  {
    name: 'Mexico',
    code: 52,
    isoCodes: 'MX',
    code3: 'MEX',
  },
  {
    name: 'Micronesia',
    code: 691,
    isoCodes: 'FM',
    code3: 'FSM',
  },
  {
    name: 'Moldova',
    code: 373,
    isoCodes: 'MD',
    code3: 'MDA',
  },
  {
    name: 'Monaco',
    code: 377,
    isoCodes: 'MC',
    code3: 'MCO',
  },
  {
    name: 'Mongolia',
    code: 976,
    isoCodes: 'MN',
    code3: 'MNG',
  },
  {
    name: 'Montenegro',
    code: 382,
    isoCodes: 'ME',
    code3: 'MNE',
  },
  {
    name: 'Montserrat',
    code: '1-664',
    isoCodes: 'MS',
    code3: 'MSR',
  },
  {
    name: 'Morocco',
    code: 212,
    isoCodes: 'MA',
    code3: 'MAR',
  },
  {
    name: 'Mozambique',
    code: 258,
    isoCodes: 'MZ',
    code3: 'MOZ',
  },
  {
    name: 'Myanmar',
    code: 95,
    isoCodes: 'MM',
    code3: 'MMR',
  },
  {
    name: 'Namibia',
    code: 264,
    isoCodes: 'NA',
    code3: 'NAM',
  },
  {
    name: 'Nauru',
    code: 674,
    isoCodes: 'NR',
    code3: 'NRU',
  },
  {
    name: 'Nepal',
    code: 977,
    isoCodes: 'NP',
    code3: 'NPL',
  },
  {
    name: 'Netherlands',
    code: 31,
    isoCodes: 'NL',
    code3: 'NLD',
  },
  {
    name: 'Netherlands Antilles',
    code: 599,
    isoCodes: 'AN',
    code3: 'ANT',
  },
  {
    name: 'New Caledonia',
    code: 687,
    isoCodes: 'NC',
    code3: 'NCL',
  },
  {
    name: 'New Zealand',
    code: 64,
    isoCodes: 'NZ',
    code3: 'NZL',
  },
  {
    name: 'Nicaragua',
    code: 505,
    isoCodes: 'NI',
    code3: 'NIC',
  },
  {
    name: 'Niger',
    code: 227,
    isoCodes: 'NE',
    code3: 'NER',
  },
  {
    name: 'Nigeria',
    code: 234,
    isoCodes: 'NG',
    code3: 'NGA',
  },
  {
    name: 'Niue',
    code: 683,
    isoCodes: 'NU',
    code3: 'NIU',
  },
  {
    name: 'North Korea',
    code: 850,
    isoCodes: 'KP',
    code3: 'PRK',
  },
  {
    name: 'Northern Mariana Islands',
    code: '1-670',
    isoCodes: 'MP',
    code3: 'MNP',
  },
  {
    name: 'Norway',
    code: 47,
    isoCodes: 'NO',
    code3: 'NOR',
  },
  {
    name: 'Oman',
    code: 968,
    isoCodes: 'OM',
    code3: 'OMN',
  },
  {
    name: 'Pakistan',
    code: 92,
    isoCodes: 'PK',
    code3: 'PAK',
  },
  {
    name: 'Palau',
    code: 680,
    isoCodes: 'PW',
    code3: 'PLW',
  },
  {
    name: 'Palestine',
    code: 970,
    isoCodes: 'PS',
    code3: 'PSE',
  },
  {
    name: 'Panama',
    code: 507,
    isoCodes: 'PA',
    code3: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    code: 675,
    isoCodes: 'PG',
    code3: 'PNG',
  },
  {
    name: 'Paraguay',
    code: 595,
    isoCodes: 'PY',
    code3: 'PRY',
  },
  {
    name: 'Peru',
    code: 51,
    isoCodes: 'PE',
    code3: 'PER',
  },
  {
    name: 'Philippines',
    code: 63,
    isoCodes: 'PH',
    code3: 'PHL',
  },
  {
    name: 'Pitcairn',
    code: 64,
    isoCodes: 'PN',
    code3: 'PCN',
  },
  {
    name: 'Poland',
    code: 48,
    isoCodes: 'PL',
    code3: 'POL',
  },
  {
    name: 'Portugal',
    code: 351,
    isoCodes: 'PT',
    code3: 'PRT',
  },
  {
    name: 'Puerto Rico',
    code: '1-787',
    isoCodes: '1-939',
    code3: 'PR',
  },
  {
    name: 'Qatar',
    code: 974,
    isoCodes: 'QA',
    code3: 'QAT',
  },
  {
    name: 'Republic of the Congo',
    code: 242,
    isoCodes: 'CG',
    code3: 'COG',
  },
  {
    name: 'Reunion',
    code: 262,
    isoCodes: 'RE',
    code3: 'REU',
  },
  {
    name: 'Romania',
    code: 40,
    isoCodes: 'RO',
    code3: 'ROU',
  },
  {
    name: 'Russia',
    code: 7,
    isoCodes: 'RU',
    code3: 'RUS',
  },
  {
    name: 'Rwanda',
    code: 250,
    isoCodes: 'RW',
    code3: 'RWA',
  },
  {
    name: 'Saint Barthelemy',
    code: 590,
    isoCodes: 'BL',
    code3: 'BLM',
  },
  {
    name: 'Saint Helena',
    code: 290,
    isoCodes: 'SH',
    code3: 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: '1-869',
    isoCodes: 'KN',
    code3: 'KNA',
  },
  {
    name: 'Saint Lucia',
    code: '1-758',
    isoCodes: 'LC',
    code3: 'LCA',
  },
  {
    name: 'Saint Martin',
    code: 590,
    isoCodes: 'MF',
    code3: 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 508,
    isoCodes: 'PM',
    code3: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: '1-784',
    isoCodes: 'VC',
    code3: 'VCT',
  },
  {
    name: 'Samoa',
    code: 685,
    isoCodes: 'WS',
    code3: 'WSM',
  },
  {
    name: 'San Marino',
    code: 378,
    isoCodes: 'SM',
    code3: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    code: 239,
    isoCodes: 'ST',
    code3: 'STP',
  },
  {
    name: 'Saudi Arabia',
    code: 966,
    isoCodes: 'SA',
    code3: 'SAU',
  },
  {
    name: 'Senegal',
    code: 221,
    isoCodes: 'SN',
    code3: 'SEN',
  },
  {
    name: 'Serbia',
    code: 381,
    isoCodes: 'RS',
    code3: 'SRB',
  },
  {
    name: 'Seychelles',
    code: 248,
    isoCodes: 'SC',
    code3: 'SYC',
  },
  {
    name: 'Sierra Leone',
    code: 232,
    isoCodes: 'SL',
    code3: 'SLE',
  },
  {
    name: 'Singapore',
    code: 65,
    isoCodes: 'SG',
    code3: 'SGP',
  },
  {
    name: 'Sint Maarten',
    code: '1-721',
    isoCodes: 'SX',
    code3: 'SXM',
  },
  {
    name: 'Slovakia',
    code: 421,
    isoCodes: 'SK',
    code3: 'SVK',
  },
  {
    name: 'Slovenia',
    code: 386,
    isoCodes: 'SI',
    code3: 'SVN',
  },
  {
    name: 'Solomon Islands',
    code: 677,
    isoCodes: 'SB',
    code3: 'SLB',
  },
  {
    name: 'Somalia',
    code: 252,
    isoCodes: 'SO',
    code3: 'SOM',
  },
  {
    name: 'South Africa',
    code: 27,
    isoCodes: 'ZA',
    code3: 'ZAF',
  },
  {
    name: 'South Korea',
    code: 82,
    isoCodes: 'KR',
    code3: 'KOR',
  },
  {
    name: 'South Sudan',
    code: 211,
    isoCodes: 'SS',
    code3: 'SSD',
  },
  {
    name: 'Spain',
    code: 34,
    isoCodes: 'ES',
    code3: 'ESP',
  },
  {
    name: 'Sri Lanka',
    code: 94,
    isoCodes: 'LK',
    code3: 'LKA',
  },
  {
    name: 'Sudan',
    code: 249,
    isoCodes: 'SD',
    code3: 'SDN',
  },
  {
    name: 'Suriname',
    code: 597,
    isoCodes: 'SR',
    code3: 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 47,
    isoCodes: 'SJ',
    code3: 'SJM',
  },
  {
    name: 'Swaziland',
    code: 268,
    isoCodes: 'SZ',
    code3: 'SWZ',
  },
  {
    name: 'Sweden',
    code: 46,
    isoCodes: 'SE',
    code3: 'SWE',
  },
  {
    name: 'Switzerland',
    code: 41,
    isoCodes: 'CH',
    code3: 'CHE',
  },
  {
    name: 'Syria',
    code: 963,
    isoCodes: 'SY',
    code3: 'SYR',
  },
  {
    name: 'Taiwan',
    code: 886,
    isoCodes: 'TW',
    code3: 'TWN',
  },
  {
    name: 'Tajikistan',
    code: 992,
    isoCodes: 'TJ',
    code3: 'TJK',
  },
  {
    name: 'Tanzania',
    code: 255,
    isoCodes: 'TZ',
    code3: 'TZA',
  },
  {
    name: 'Thailand',
    code: 66,
    isoCodes: 'TH',
    code3: 'THA',
  },
  {
    name: 'Togo',
    code: 228,
    isoCodes: 'TG',
    code3: 'TGO',
  },
  {
    name: 'Tokelau',
    code: 690,
    isoCodes: 'TK',
    code3: 'TKL',
  },
  {
    name: 'Tonga',
    code: 676,
    isoCodes: 'TO',
    code3: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    code: '1-868',
    isoCodes: 'TT',
    code3: 'TTO',
  },
  {
    name: 'Tunisia',
    code: 216,
    isoCodes: 'TN',
    code3: 'TUN',
  },
  {
    name: 'Turkey',
    code: 90,
    isoCodes: 'TR',
    code3: 'TUR',
  },
  {
    name: 'Turkmenistan',
    code: 993,
    isoCodes: 'TM',
    code3: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: '1-649',
    isoCodes: 'TC',
    code3: 'TCA',
  },
  {
    name: 'Tuvalu',
    code: 688,
    isoCodes: 'TV',
    code3: 'TUV',
  },
  {
    name: 'U.S. Virgin Islands',
    code: '1-340',
    isoCodes: 'VI',
    code3: 'VIR',
  },
  {
    name: 'Uganda',
    code: 256,
    isoCodes: 'UG',
    code3: 'UGA',
  },
  {
    name: 'Ukraine',
    code: 380,
    isoCodes: 'UA',
    code3: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    code: 971,
    isoCodes: 'AE',
    code3: 'ARE',
  },
  {
    name: 'United Kingdom',
    code: 44,
    isoCodes: 'GB',
    code3: 'GBR',
  },
  {
    name: 'United States',
    code: 1,
    isoCodes: 'US',
    code3: 'USA',
  },
  {
    name: 'Uruguay',
    code: 598,
    isoCodes: 'UY',
    code3: 'URY',
  },
  {
    name: 'Uzbekistan',
    code: 998,
    isoCodes: 'UZ',
    code3: 'UZB',
  },
  {
    name: 'Vanuatu',
    code: 678,
    isoCodes: 'VU',
    code3: 'VUT',
  },
  {
    name: 'Vatican',
    code: 379,
    isoCodes: 'VA',
    code3: 'VAT',
  },
  {
    name: 'Venezuela',
    code: 58,
    isoCodes: 'VE',
    code3: 'VEN',
  },
  {
    name: 'Vietnam',
    code: 84,
    isoCodes: 'VN',
    code3: 'VNM',
  },
  {
    name: 'Wallis and Futuna',
    code: 681,
    isoCodes: 'WF',
    code3: 'WLF',
  },
  {
    name: 'Western Sahara',
    code: 212,
    isoCodes: 'EH',
    code3: 'ESH',
  },
  {
    name: 'Yemen',
    code: 967,
    isoCodes: 'YE',
    code3: 'YEM',
  },
  {
    name: 'Zambia',
    code: 260,
    isoCodes: 'ZM',
    code3: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    code: 263,
    isoCodes: 'ZW',
    code3: 'ZWE',
  },
];
