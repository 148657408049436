import { Injectable } from '@angular/core';
import { AppLoggerService } from './app-logger.service';
import { DeviceManagementService } from './device-management.service';
import { StorageService } from './storage.service';
import { EventManagerService } from './event-manager.service';
import { AppConfigService } from './app-configuration.service';
import { APIGatewayService } from './api-gateway.service';
import { ComponentPageService } from './component-pages.service';

// const LOGOUT_TARGET_WINDOW = '_self';
// const ROOT_ROUTE = '/';
const CONFIG_LOADED = 'configLoaded';
const SESSION_ID = 'sessionId';
const SESSION_OBTAINED = 'sessionObtained';
const SESSION_ESTABLISHED = 'sessionEstablished';
const SESSION_ENDED = 'sessionEnded';
const API_READY = 'apiReady';
const FORM_ACTIVITY = 'formActivity';
const SESSION_SIGN_KEY_RECEIVED = 'sessionSignKeyReceived';
const SESSION_ENCRYPT_KEY_RECEIVED = 'sessionEncryptKeyReceived';
const VERIFICATION_FREQUENCY = 'verificationFrequency';
const SESSION_TIMEOUT = 'sessionTimeout';
const SESSION_TOBE_CLOSED = 'sessionToBeClosed';
const MENU_OBTAINED = 'menuObtained';

export const activeSessionErrors = Object.freeze({
  validSession: 0,
  expiredOnServer: 1,
  concurrentSession: 2,
  missingUserFunctions: 3,
  noSession: 4,
});
@Injectable({ providedIn: 'root' })
export class UserSessionService {
  sessionData: any = null;
  sessionTimeout: number;
  userOptions: any;
  lastAppActivityDate: Date;
  lastAppActivityForm: string;
  sessionActivityTimer: any;
  appMenus: any;
  customSessionData: any = {};

  constructor(
    private _eventManager: EventManagerService,
    private _appConfig: AppConfigService,
    private _apiGateway: APIGatewayService,
    private _storageService: StorageService,
    private _logger: AppLoggerService,
    private _componentPageService: ComponentPageService,
    private _deviceManagementService: DeviceManagementService,
  ) {
    this.startSessionService();
  }

  startSessionService() {
    this.customSessionData = {};
    this._eventManager.subscribe(CONFIG_LOADED, (data) => { data && this.configSessionVerification(); });
    this._eventManager.subscribe(SESSION_OBTAINED, (data) => { data && this.completeSessionEstablish(data); });
    this._eventManager.subscribe(SESSION_ENDED, (data) => { data && this.clearService(); });
    this._eventManager.subscribe(API_READY, () => this.activeSession());
    this._eventManager.subscribe(FORM_ACTIVITY, formData => this.traceActivity(formData));
  }

  updateLastActivityDate() {
    this.lastAppActivityDate = new Date();
    this._eventManager.next('updateLastActivityDate', this.lastAppActivityDate);
  }

  configSessionVerification() {
    const verificationFrequency = this._appConfig.getParameter(VERIFICATION_FREQUENCY);
    this.sessionTimeout = this._appConfig.getParameter(SESSION_TIMEOUT);
    this.updateLastActivityDate();
    this.lastAppActivityForm = 'none';
    this.sessionActivityTimer = setInterval(() => this.checkAppActivity(), verificationFrequency);
  }

  addCustomSessionData(name, value) {
    this.customSessionData[name] = value;
  }

  delCustomSessionData(name) {
    delete this.customSessionData?.[name];
  }

  getCustomSessionData(name) {
    return this.customSessionData?.[name] ?? null;
  }

  clearService() {
    this.sessionData = null;
    this.userOptions = null;
    this.customSessionData = {};
    this._eventManager.next(SESSION_ESTABLISHED, null);
  }

  async activeSession() {
    const currentSessionInApp = (this.sessionData && this.userOptions?.userFunctions)
      ? this.sessionData?.sessionId : null;
    const { data: serverSessionData } = await this._apiGateway.obtainCurrentSession();
    const { sessionId: currentSessionInServer } = serverSessionData;
    this.storeNewSessionId(currentSessionInServer);
    if (currentSessionInApp) {
      if (!currentSessionInServer) {
        this.clearStoredSessionId();
        return activeSessionErrors.expiredOnServer;
      }
      if (currentSessionInServer !== currentSessionInApp) {
        // this.clearStoredSessionId();
        return activeSessionErrors.concurrentSession;
      }
      return activeSessionErrors.validSession;
    }
    if (currentSessionInServer) {
      return this.completeSessionEstablish(serverSessionData, false);
    }
    this.clearStoredSessionId();
    return activeSessionErrors.noSession;
  }

  async completeSessionEstablish(sessionData, changeForm = true) {
    if (!sessionData) {
      return activeSessionErrors.noSession;
    }
    const signCryptogram = sessionData?.sessionKeys?.signKeyCrypto ?? null;
    const encryptKeyCryptogram = sessionData?.sessionKeys?.encryptKeyCrypto ?? null;
    if (signCryptogram && encryptKeyCryptogram) {
      const signKey = await this._deviceManagementService.decryptData(signCryptogram);
      const encryptKey = await this._deviceManagementService.decryptData(encryptKeyCryptogram);
      this._eventManager.next(SESSION_SIGN_KEY_RECEIVED, signKey);
      this._eventManager.next(SESSION_ENCRYPT_KEY_RECEIVED, encryptKey);
    }
    this.storeNewSessionId(sessionData.sessionId);
    this.customSessionData = {};
    this.sessionData = sessionData;
    const userOptionsResp = await this._apiGateway.getUserPrivileges();
    const frontendMenusRes = await this._apiGateway.getMenuData();
    const frontendMenus = frontendMenusRes.getData();
    this.userOptions = userOptionsResp.getData();
    if (!this.userOptions) {
      return activeSessionErrors.missingUserFunctions;
    }
    const userFunctions = this.userOptions?.userFunctions ?? [];
    const fullSessionData = { sessionData, privileges: userFunctions, changeForm };
    this.storeNewSessionId(sessionData?.sessionId);
    this._eventManager.next(SESSION_ESTABLISHED, fullSessionData);
    // Se personalizan  los menus obtenidos para generar el evento de notificación de menus
    this.appMenus = {};
    for (let index = 0; index < frontendMenus.length; index++) {
      const { name, items } = frontendMenus[index];
      this.appMenus[name] = this.filterMenuItems(items, userFunctions);
    }
    this._eventManager.next(MENU_OBTAINED, this.appMenus);
    return activeSessionErrors.validSession;
  }

  async buildFakeSession(sessionData) {
    this.sessionData = sessionData;
    this.storeNewSessionId(sessionData.sessionId);
    this.configSessionVerification();
  }

  getAppMenus() {
    return this.appMenus;
  }

  userCanNavigate(fullRequestedRoute, requiredFunctions = []) {
    const funRequired = [...(this._componentPageService.pathFunctions(fullRequestedRoute) ?? [])];
    funRequired.push(...requiredFunctions);
    if (!funRequired || funRequired.length === 0) {
      return true;
    }
    const userFunctions = this.userOptions?.userFunctions;
    const hasAllFunctions = userFunctions
      ? funRequired.reduce((hasAll, name) => hasAll && userFunctions.includes(name), true)
      : false;
    return hasAllFunctions;
  }

  filterMenuItems(menuObject, userFunctions) {
    const outputMenu = menuObject.filter(item =>
      this.allFunctionsIncluded(item.functions, userFunctions),
    );
    for (let index = 0; index < outputMenu.length; index++) {
      const menuItem = outputMenu[index];
      if (menuItem.children) {
        menuItem.children = this.filterMenuItems(menuItem.children, userFunctions);
      }
    }
    return outputMenu;
  }

  allFunctionsIncluded(functions, userFunctions) {
    if (functions?.length > 0 && userFunctions?.length > 0) {
      for (let index = 0; index < functions.length; index++) {
        if (!userFunctions.includes(functions[index])) {
          return false;
        }
      }
    }
    return true;
  }

  async storeNewSessionId(sessionId) {
    if (sessionId) {
      return await this._storageService.setItem(SESSION_ID, { sessionId });
    }
  }

  clearStoredSessionId() {
    return this._storageService.removeItem(SESSION_ID);
  }

  getUserData() { return { sessionData: this.sessionData, userOptions: this.userOptions }; }
  getSessionData() { return this.sessionData; }

  traceActivity(formData) {
    this.updateLastActivityDate();
    this.lastAppActivityForm = formData?.code;
  }

  checkAppActivity() {
    if (!this.sessionData) {
      return;
    }
    const currentDate = new Date();
    const inactivityPeriod: number = currentDate.getTime() - this.lastAppActivityDate.getTime();
    if (inactivityPeriod > this.sessionTimeout) {
      this._eventManager.next(SESSION_TOBE_CLOSED, null);
    } else {
      this.activeSession();
    }
  }
}
