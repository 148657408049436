import { Component, Injector } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import { routeForm } from 'src/app/services/component-pages.service';

const SESSION_ESTABLISHED = 'sessionEstablished';
const SESSION_ENDED = 'sessionEnded';
const subMenuName = 'transactionalMenu'; // contextMobile
const checkFrequency = 60000;

const frm = {
  name: 'activityCheck',
  states: { default: 'default' },
  sections: { default: 'default' },
  subsections: { default: 'default' },
  fields: {
    lastCheckDate: 'lastCheckDate',
    agentPendingTickets: 'agentPendingTickets',
    agentAccountBalance: 'agentAccountBalance',
    agentPendingNotifications: 'agentPendingNotifications',
  },
  actions: { checkActivity: 'checkActivity' },
  tables: {},
};

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent extends AppFormComponent {
  menuData: any[];
  currentForm: any;
  checkTimer: any = null;
  menuOptions: any[] = [];
  pendingTickets: number = 0;
  activatedCheck: boolean = false;
  pendingNotifications: number = 0;
  activityCheckStart: Date;

  constructor(injector: Injector) {
    super(injector);
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentForm = routeForm(event?.url);
      }
    });
  }

  override preStart() { this.name = frm.name; }

  override start() {
    super.start();
    this.activityCheckStart = new Date();
    this.disableActivityNotification();
    this.disableServerErrorPopup();
    this.buildMenu(this._userSession.getAppMenus());
    this.subscribeAppEvents(SESSION_ESTABLISHED, (sessionInfo) => this.activateChecking(sessionInfo));
    this.subscribeAppEvents(SESSION_ENDED, event => this.deactivateChecking(event));
    this.subscribeAppEvents('menuObtained', menuData => this.buildMenu(menuData));
    this.subscribeAppEvents('pendingTickets', num => this.updatePendingTickets(num));
    this.subscribeAppEvents('pendingNotifications', num => this.updatePendingNotifications(num));
    this.onActionFinish(frm.actions.checkActivity, this.updateObtaindedActivity.bind(this));
    this.startAction(frm.actions.checkActivity);
  }

  deactivateChecking(event) {
    const { endDate } = event ?? {};
    if (endDate && endDate > this.activityCheckStart) {
      this.activatedCheck = false;
      clearTimeout(this.checkTimer);
    }
  }

  activateChecking(sessionInfo) {
    // Se activa la verificación si la sesión es de corresponsal
    if (sessionInfo?.sessionData?.agentId && !this.activatedCheck) {
      this.activatedCheck = true;
      this.restartTimer();
    }
  }

  restartTimer() {
    if (this.activatedCheck) {
      clearTimeout(this.checkTimer);
      this.checkTimer = setTimeout(() => this.startAction(frm.actions.checkActivity), checkFrequency);
    }
  }

  updateObtaindedActivity() {
    if (this.activatedCheck && !this.errorOccured()) {
      const pendingTickets = this.getFieldValue(frm.fields.agentPendingTickets);
      if (!Number.isNaN(pendingTickets) && pendingTickets !== this.pendingTickets) {
        this.updatePendingTickets(pendingTickets);
      }
      const pendingNotifications = this.getFieldValue(frm.fields.agentPendingNotifications);
      if (!Number.isNaN(pendingNotifications) && pendingNotifications !== this.pendingNotifications) {
        this.updatePendingNotifications(pendingNotifications);
      }
      this.restartTimer();
    }
  }

  updatePendingTickets(pendingTickets) {
    if (this.pendingTickets !== pendingTickets) {
      this.pendingTickets = pendingTickets;
      // this._eventManager.next('updateTickets', pendingTickets);
      const ticketOption = this.menuOptions?.find(opt => opt.form === 'ticketList') ?? null;
      ticketOption && (ticketOption.badge = pendingTickets);
    }
  }

  updatePendingNotifications(pendingNotifications) {
    if (this.pendingNotifications !== pendingNotifications) {
      this.pendingNotifications = pendingNotifications;
      const notificationOption = this.menuOptions?.find(opt => opt.form === 'notifications') ?? null;
      notificationOption && (notificationOption.badge = pendingNotifications);
      // this._eventManager.next('updateNotifications', pendingNotifications);
    }
  }

  buildMenu(inputData) {
    const data = JSON.parse(JSON.stringify(inputData?.[subMenuName]));
    this.menuOptions = [];
    if (!data) { return; }
    this.menuData = this.preprocessMenudata(data);
    this.menuData?.[0]?.children?.forEach(menuEntry => {
      menuEntry.key && (this.menuOptions.push({ ...menuEntry, badge: 0 }));
    });
  }

  preprocessMenudata(menuData) {
    menuData?.forEach((item) => {
      if (item.icon) {
        let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
        item.url = pageUrl;
        item.iconName = item.icon;
        item.key = item.form;
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }

  activateOption(option) {
    const formToActivate = option?.form;
    if (formToActivate) {
      this.openForm(formToActivate);
    }
  }
}
