<div [ngSwitch]="true">
  <div *ngSwitchCase="customAttributes().displayType === 'readOnly'">
    <div [class]="value() ? 'reviewOk baseReview' : 'reviewFail baseReview'">
      <ion-icon [name]="value() ? 'checkmark-circle' : 'close-circle'" size="large"></ion-icon>
      <label style="font-size: 1rem">{{ title() }}</label>
      <div class="formItemReviewPass">
        <div
          style="display: none"
          [class]="value() ? 'reviewOk' : 'reviewFail'"
          [nzValidateStatus]="onValidation ? 'validating' : errorType"
          [nzErrorTip]="errorMessage">
          <nz-switch
            class="mb-3 mr-3"
            [(ngModel)]="value"
            [nzDisabled]="disabled()"
            (ngModelChange)="inputChanged()"
            nzCheckedChildren="Si"
            nzUnCheckedChildren="No"
            [disabled]="disabled()">
          </nz-switch>
        </div>
      </div>
    </div>
  </div>

  <div class="formField" *ngSwitchCase="customAttributes().displayType === 'includeLink'">
    <div [lines]="fieldLines" [class]="customAttributes().displayClass">
      <label style="white-space: unset">
        <button
          id="openCheckModal"
          style="all: unset"
          (click)="updateContent(customAttributes().modalTitle, customAttributes().modalMessage)">
          {{ title() }}
        </button>
      </label>
      <nz-switch
        labelPlacement="start"
        [disabled]="disabled()"
        class="includeLink"
        justify="space-between"
        [(ngModel)]="value"
        (ngModelChange)="inputChanged()">
      </nz-switch>
    </div>
  </div>

  <div class="formField" *ngSwitchCase="customAttributes().displayType === 'checkBoxLeft'">
    <div [lines]="fieldLines" [class]="customAttributes().displayClass">
      <label nz-checkbox [(ngModel)]="value" (ngModelChange)="inputChanged()" [disabled]="disabled()">
        ABC {{ title() }}{{ required() ? '*' : '' }}
      </label>
    </div>
  </div>

  <div *ngSwitchDefault>
    <nz-form-item [class]="customAttributes().displayClass">
      <nz-form-control [nzValidateStatus]="onValidation ? 'validating' : errorType" [nzErrorTip]="errorMessage">
        <nz-switch
          class="mb-3 mr-3"
          [(ngModel)]="value"
          [nzDisabled]="disabled()"
          (ngModelChange)="inputChanged()"
          nzCheckedChildren="Si"
          nzUnCheckedChildren="No"
          [disabled]="disabled()">
        </nz-switch>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
