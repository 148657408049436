import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { ciiuCodes } from './ciiu';

@Component({
  selector: 'app-ciiu',
  templateUrl: './app-ciiu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCiiuComponent extends FieldComponent {
  ciiuList: any[];
  override start() {
    super.start();
    this.ciiuList = ciiuCodes.map(ciiuItem => ({
      value: ciiuItem.value,
      text: `${ciiuItem.value} - ${ciiuItem.description}`,
    }));
    this.field.options = this.ciiuList;
    this.field.value || (this.field.value = []);
  }

  filterInput(inputText, option) {
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    const ciiuCode = ciiuCodes.find(item => item.value === nzValue);
    return (!nzDisabled && !nzHide && (nzLabel.includes(inputText)
      || nzValue?.toString()?.includes(inputText) || ciiuCode?.description.includes(inputText)));
  }
}
