<div *ngIf="globalSearch()">
  <nz-input-group [nzAddOnBefore]="prefixIconSearch">
    <input nzType="text" nz-input [(ngModel)]="globalFilterString" (keyup)="globalFilterChanged()"
      (change)="globalFilterCompleted()" placeholder="Buscar..." autocomplete="off" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <app-icon iconName="search"></app-icon>
  </ng-template>
</div>

<div class="mb-2">
  <ng-container *ngFor="let actions of globalActions">
    <button type="button" nz-button (click)="tableGlobalAction(actionCode)">
      <app-icon iconName=""></app-icon><span>&nbsp; {{ action.actionTitle }}</span>
    </button>
  </ng-container>
  <ng-container *ngFor="let actions of selectionActions">
    <button nzType="text" nz-button [disabled]="selectedRecords().length === 0"
      (click)="tableSelectionAction(actionCode)">
      <app-icon iconName=""></app-icon><span>&nbsp; {{ action.actionTitle }}</span>
    </button>
  </ng-container>
</div>
<!-- [nzScroll]="{ x: '1150px'}" -->
<nz-table nzTemplateMode [nzLoading]="waiting()" [nzSize]="'small'" nzTableLayout="auto">
  <thead>
    <tr>
      <th nzLeft *ngIf="selectable" [nzSortFn]="selectionSortable" [nzShowSort]="column?.sortable"
        [nzSortDirections]="selectionColumn?.sortDirections"
        (nzSortOrderChange)="sortOrderHasChanged(selectionColumn, $event)" [nzAlign]="selectionColumn?.fieldAlignment">
        <ng-container *ngIf="!disabled && !selectionBackend">
          <button nzType="text" class="btn btn-icon btn-link" (click)="toggleSelectAll()">
            <app-icon [iconName]="allSelected() ? 'CHECKED' : 'UNCHECKED'"></app-icon>
          </button>
        </ng-container>
      </th>
      <ng-container *ngFor="let column of columns(); let i = index">
        <ng-container *ngIf="column.visible">
          <ng-container *ngIf="column.filterDef && column.filterDef.operators; else noFilterColumn">
            <ng-container *ngIf="column.filterDef.operators[0] === 'IN'">
              <th [nzSortFn]="column?.sortable" [nzShowSort]="column?.sortable"
                [nzSortDirections]="column.sortDirections" (nzSortOrderChange)="sortOrderHasChanged(column, $event)"
                [nzFilterMultiple]="true" [nzShowFilter]="column.filterDef" [nzFilterFn]="true"
                [nzAlign]="column.fieldAlignment" (nzFilterChange)="filterHasChanged(column, $event)"
                [nzFilters]="column.options">
                {{ column.fieldTitle }}
              </th>
            </ng-container>
            <ng-container *ngIf="column.filterDef.operators[0] === 'HAS'">
              <th nzCustomFilter [nzAlign]="column.fieldAlignment" [nzSortFn]="column?.sortable"
                [nzShowSort]="column?.sortable" [nzSortDirections]="column.sortDirections"
                (nzSortOrderChange)="sortOrderHasChanged(column, $event)">
                {{ column.fieldTitle }}
                <nz-filter-trigger [(nzVisible)]="column.filterVisible" [nzActive]="column.filter"
                  [nzDropdownMenu]="textSearch" (nzVisibleChange)="openSearch(column, $event)">
                  <app-icon iconName="search"></app-icon>
                </nz-filter-trigger>
              </th>
            </ng-container>
          </ng-container>

          <ng-template #noFilterColumn>
            <ng-container>
              <th [nzLeft]="i <= leftFixedColumns" [nzRight]="i >= totalColumns - rightFixedColumns"
                [nzSortFn]="column?.sortable" [nzShowSort]="column?.sortable" [nzSortDirections]="column.sortDirections"
                (nzSortOrderChange)="sortOrderHasChanged(column, $event)" [nzAlign]="column.fieldAlignment">
                {{ column.fieldTitle }}
              </th>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- <th *ngIf="hasActions && !disabled" style="text-align: center; vertical-align: middle;">Acciones</th> -->
      <th nzRight *ngIf="hasActions" style="text-align: center; vertical-align: middle">Acciones</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let record of visibleRecords()">
      <td *ngIf="selectable" nowrap="nowrap" style="text-align: center">
        <app-icon *ngIf="true || !table.disabled" [iconName]="record.selected ? 'CHECKED' : 'UNCHECKED'"
          (click)="tableSelectionToggle(record.recordId)">
        </app-icon>
        <span>&nbsp;</span>
      </td>
      <ng-container *ngFor="let column of columns(); let i = index">
        <td *ngIf="column.visible" [nzAlign]="column.fieldAlignment" [nzEllipsis]="column?.customAttributes.ellipsis"
          [nzLeft]="i <= leftFixedColumns" [nzRight]="i >= totalColumns - rightFixedColumns">
          <row-field [fieldCode]="column.fieldCode" [fieldType]="column.fieldType"
            [fieldValue]="record.recordData[column.fieldCode]">
          </row-field>
        </td>
      </ng-container>

      <!-- <td *ngIf="hasActions && !disabled" nowrap="nowrap" style="text-align: center;"> -->
      <td nzRight *ngIf="hasActions" nowrap="nowrap" style="text-align: center">
        <ng-template ngFor let-action [ngForOf]="inlineActions">
          <ng-container *ngIf="action?._visible">
            <row-action [action]="action" [recordData]="record.recordData" [recordId]="record.recordId"
              [disabled]="action?.disabled" (actionSelected)="tableActionSelected($event)">
            </row-action>
          </ng-container>
        </ng-template>
      </td>
    </tr>
  </tbody>
</nz-table>
<div class="mt-2" *ngIf="currentPage()">
  <nz-pagination class="mb-3" *ngIf="totalRecordsNumber() > recordsPerPage()" [nzPageIndex]="currentPage()"
    [nzTotal]="totalRecordsNumber()" [nzPageSize]="recordsPerPage()" (nzPageIndexChange)="changePage($event)">
  </nz-pagination>
</div>

<!-- Templates para el manejo de los filtros de las columnas de tablas -->

<nz-dropdown-menu #textSearch>
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input nzType="text" nz-input placeholder="Buscar" [(ngModel)]="activeSearchText" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Buscar</button>
      <button nz-button nzSize="small" (click)="reset()">Borrar</button>
    </div>
  </div>
</nz-dropdown-menu>