<div class="formHeader site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false" style="padding: 0">
    <nz-page-header-title *ngIf="form?.title">
      <div>
        <span class="me-3" *ngIf="form?.viewGoBack" (click)="form.goBackForm?.()">
          <i class="fa-solid fa-arrow-left" style="cursor: pointer"></i>
        </span>
        <span class="titleUnderShort">{{ showTitle ? form?.title : '' }}</span>
      </div>
    </nz-page-header-title>

    <!-- Acciones -->
    <nz-page-header-extra>
      <ng-container *ngIf="form?.visibleHeaderActions?.length < 4">
        <ng-container *ngFor="let action of form?.visibleHeaderActions">
          <span *ngIf="action.visible" class="headerBtnContainer">
            <button
              *ngIf="!action?.customAttributes.preConfirm"
              nz-button
              nzType="primary"
              [disabled]="action?.disabled || form?.busy()"
              (click)="execAction(action)">
              {{ action.actionName }}
            </button>
            <button
              *ngIf="action?.customAttributes.preConfirm"
              nz-button
              nzType="primary"
              [disabled]="disabled || form?.busy"
              class="mr-3 mb-3"
              nz-popconfirm
              [nzIcon]="iconTpl"
              [nzPopconfirmTitle]="
                action?.customAttributes.preConfirm + ' ' + (action?.customAttributes.preConfirmMsg ?? '')
              "
              (nzOnConfirm)="execAction(action)"
              nzPopconfirmPlacement="leftTop">
              {{ action.actionName }}
            </button>
            <ng-template #iconTpl>
              <span
                nz-icon
                nzType="question-circle-o"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'red'"
                style="font-size: 20px">
              </span>
            </ng-template>
          </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="form?.visibleHeaderActions?.length >= 4">
        <span class="btnContainer">
          <button nz-button nzNoAnimation nz-dropdown [nzDropdownMenu]="menu">
            Acciones &nbsp;&nbsp;
            <app-icon iconName="arrowDown"></app-icon>
          </button>
        </span>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <ng-container *ngFor="let action of form?.visibleHeaderActions">
              <li
                nz-menu-action
                class="menuItem"
                *ngIf="action.visible"
                [nzDisabled]="action?.disabled"
                (click)="execAction(action)">
                {{ action.actionName }}
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
      </ng-container>
    </nz-page-header-extra>
  </nz-page-header>
</div>
