import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LibTableComponent } from 'tuain-ng-forms-lib';
import * as moment from 'moment';
import { formConfig } from 'src/app/components/tuain/forms.module.config';

@Component({
  selector: 'app-time-line-table',
  templateUrl: './app-time-line-table.component.html',
  styleUrls: ['./app-time-line-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTimeLineTableComponent extends LibTableComponent {
  dateFormat: string;
  momentFormat: string;
  customerIcon: string = '';
  backIcon: string = '';
  selectorField: string = '';
  timeField: string = '';
  customerSelectorValue: string = '';
  backSelectorValue: string = '';
  defaultAction: any = null;
  leftFields: any[];
  detailFields: any[];
  avatarField: any;
  tableStyle: any;
  leftStyle: any;
  rightStyle: any;

  inlineActIcn = {};

  override start() {
    super.start();
    this.table.setTableAppend(false);
    this.dateFormat = formConfig?.defaultFieldAttributes?.tableDateFormat ?? 'YYYY/MM/DD';
    this.momentFormat = formConfig?.defaultFieldAttributes?.tableMomentFormat ?? 'YY-MM-DD HH:mm';

    if (this.inlineActions?.length === 1) {
      this.defaultAction = this.inlineActions?.[0]?.actionCode;
    } else {
      this.defaultAction = this.table?.getCustomAttribute('defaultAction');
    }
    this.timeField = this.table?.getCustomAttribute('timeField');
    const { fieldName, customerSelectorValue, backSelectorValue } = this.table?.getCustomAttribute('iconSelector');

    this.selectorField = fieldName;
    this.customerSelectorValue = customerSelectorValue;
    this.backSelectorValue = backSelectorValue;

    this.customerIcon = this.table?.getCustomAttribute('customerImage');
    this.backIcon = this.table?.getCustomAttribute('backImage');
    const detailFieldNames = this.table?.getCustomAttribute('detailFields');
    this.detailFields = [];
    detailFieldNames?.forEach(fieldName => {
      const column = this.table?.columns?.find(col => col.fieldCode === fieldName);
      column && this.detailFields.push(column);
    });

    this.tableStyle = this.table?.getCustomAttribute('styles') ?? '';
    this.leftStyle = this.table?.getCustomAttribute('leftStyles') ?? '';
    this.rightStyle = this.table?.getCustomAttribute('rightStyles') ?? '';
  }

  selectRecord(recordId) {
    const selectedRecords = this.table.getSelectedRecords();
    if (!selectedRecords.includes(recordId)) {
      this.table.unSelectAll();
    }
    this.table.notifyRecordSelection(recordId);
  }

  execDefaultAction(record) {
    this.defaultAction && this.execAction({ actionCode: this.defaultAction }, record);
  }

  execAction(action, record) {
    if (action && action?.actionCode && record) {
      this.tableActionSelected({ actionCode: action?.actionCode, ...record });
    }
  }

  parseOnlyDate = date => moment.utc(date).format(this.dateFormat);
  parseMoment = date => moment.utc(date).format(this.momentFormat);
  parseMomentUtc = date => moment(date).format(this.momentFormat);
}