import { Component, Injector } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import { routeForm } from 'src/app/services/component-pages.service';
import { DeviceManagementService } from 'src/app/services/device-management.service';

const AVOID_DUPLICATES = true;
const NOTIFY_FORM_AFTER_SESSION_START = 'notifyFormAfterSessionStart';
const END_USER_REQUIRE_SESSION = 'endUserRequireSession';

const frm = {
  name: 'endUserNonCustomer',
  states: { default: 'default' },
  sections: { default: 'default' },
  subsections: { default: 'default' },
  fields: {
    lastCheckDate: 'lastCheckDate',
    agentPendingTickets: 'agentPendingTickets',
    agentPendingNotifications: 'agentPendingNotifications',
    agentAccountBalance: 'agentAccountBalance',
  },
  actions: { checkActivity: 'checkActivity' },
  tables: {},
};

@Component({
  selector: 'app-eu-side-menu',
  templateUrl: './eu-side-menu.component.html',
  styleUrls: ['./eu-side-menu.component.scss']
})
export class EndUserSideMenuComponent extends AppFormComponent {
  menuData: any[];
  currentForm: any;
  menuOptions: any[] = [];
  checkTimer: any = null;
  activatedCheck: boolean = false;
  pendingTickets: number = 0;
  pendingNotifications: number = 0;
  iosClass = '';

  constructor(injector: Injector,
    public _deviceManagementService: DeviceManagementService,
  ) {
    super(injector);
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentForm = routeForm(event?.url);
      }
    });
  }

  override preStart() { this.name = frm.name; }

  override start() {
    super.start();
    const transactionalMenu = this.getExtraInfo('transactionalMenu');
    this.buildMenu(transactionalMenu);
    this.subscribeAppEvent(END_USER_REQUIRE_SESSION, (data) => { data && this.openForm('endUserRegister'); });
  }

  buildMenu(data) {
    this.menuOptions = [];
    if (!data) { return; }
    this.menuData = this.preprocessMenudata(data);
    this.menuData?.[0]?.children?.forEach((menuEntry) => {
      menuEntry.key && (this.menuOptions.push({ ...menuEntry, badge: 0 }));
    });
  }

  preprocessMenudata(menuData) {
    const levelForms = [];
    menuData?.forEach((item) => {
      if (item.icon) {
        const currentForm = item?.form;
        if (!AVOID_DUPLICATES || !levelForms.includes(currentForm)) {
          levelForms.push(currentForm);
          let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
          item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
            ? [item.additionalInfo] : item.additionalInfo;
          item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
          item.url = pageUrl;
          item.iconName = item.icon;
          item.key = item.form;
        }
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }

  activateOption(option) {
    const form = option?.form;
    this.emitAppEvents(NOTIFY_FORM_AFTER_SESSION_START, form);
    this.openForm(form);
  }
}
