import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { cities } from './cities';

@Component({
  selector: 'app-city',
  templateUrl: './app-city.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCityComponent extends FieldComponent {
  departaments = Object.keys(cities).map(key => cities[key]);
  cities = [];
  override start() {
    super.start();
    this.field.value || (this.field.value = []);
  }

  onChooseDept() {
    this.cities = cities[this.value()[0]]?.cities;
    this.updateObject();
    this.onChangeContent();
  }
}
