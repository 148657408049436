import { Injectable } from '@angular/core';
import { iconDictionary } from './icon-dictionary.config';
import * as changeCase from "change-case";

const DEFAULT_ICON = 'default';

@Injectable({ providedIn: 'root' })
export class IconDictionaryService {
  collections: string[];
  defaultCollection: string;
  constructor() {
    this.collections = Object.keys(iconDictionary) ?? [];
    this.defaultCollection = this.collections[0];
  }

  getIconInfo(name, collectionName = null) {
    let collection = collectionName;
    let realIcon = '';
    let defaultIcon = iconDictionary?.[this.defaultCollection]?.[DEFAULT_ICON];
    if (!collection) {
      for (let index = 0; index < this.collections.length; index++) {
        collection = this.collections[index];
        realIcon = iconDictionary?.[collection]?.[name] ?? null;
        if (realIcon) {
          break;
        }
      }
    } else {
      defaultIcon = (iconDictionary?.[collection]?.[DEFAULT_ICON])
        ? iconDictionary?.[collection]?.[DEFAULT_ICON] : defaultIcon;
      realIcon = iconDictionary?.[collection]?.[name] ?? null;
    }
    return { icon: realIcon ?? defaultIcon, collection };
  }

  mapIcon(name, collectionName = null) {
    const iconName = (name && typeof name === 'string') ? changeCase.camelCase(name) : 'default';
    return this.getIconInfo(iconName, collectionName);
  }
}
