<nz-form-item>
  <div style="width: 100%; text-align: center">
    <div style="display: inline-block; margin: 0 auto; padding: 3px">
      <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
        <nz-segmented
          [nzOptions]="segmentOptions()"
          [id]="field.code"
          [(ngModel)]="selectedOption"
          (ngModelChange)="segmentChanged()">
        </nz-segmented>
      </nz-form-control>
    </div>
  </div>
</nz-form-item>
