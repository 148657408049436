<div #chat class="chat">
  <ng-container *ngFor="let record of visibleRecords()">
    <div class="message" [ngClass]="record.recordData.messageOwnerClass">
      <div class="avatar">
        <img [src]="record.recordData.messageAvatar" draggable="false" alt="" />
      </div>
      <div class="msg" (click)="messageActivity(record.recordData)">
        <img
          *ngIf="record.recordData.messageImage"
          nz-image
          [nzSrc]="record.recordData.messageImage"
          [nzFallback]="fallback"
          draggable="false"
          alt="" />
        <div *ngIf="record.recordData.messageFile" class="fileDownload">
          <div class="fileDownloadIcon">
            <img
              *ngIf="record.recordData.messageFile?.fileUrl"
              class="fileDownloadImg"
              src="{{ assetsBase }}/images/pages/ticket-detail/download-arrow.png"
              draggable="false"
              alt="" />
          </div>
          <div class="fileDownloadName">{{ record.recordData.messageFile?.fileName }}</div>
        </div>
        <p *ngIf="record.recordData.messageText" [innerHtml]="record.recordData.messageText"></p>
        <span class="msg-time">{{ record.recordData.messageTime }}</span>
      </div>
    </div>
  </ng-container>
</div>
