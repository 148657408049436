/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import urlJoin from 'proper-url-join';
import { nanoid } from 'nanoid';
import * as moment from 'moment';
import CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { EventManagerService } from './event-manager.service';
import { StorageService } from './storage.service';
import { AppLoggerService } from './app-logger.service';
import { defaultConfig } from '../app.config';

const TUAIN_FROM_VERSION = 'TUAINH2H256';
const COMMUNICATION_ERROR = '999';
const SESSION_ID = 'sessionId';

const SESSION_ESTABLISHED = 'sessionEstablished';
const CONFIG_LOADED = 'configLoaded';

const DEFAULT_ERROR = {
  errorCode: COMMUNICATION_ERROR,
  errorFullCode: '',
  errorName: 'Error procesando la solicitud',
  errorMessage: '',
  errorDetail: '',
};

const API_METHODS = {
  openSession: 'session/openSession/',
  getUserPrivileges: 'getUserPrivileges/',
  obtainCurrentSession: 'session/obtainCurrentSession/',
  getMenuData: 'session/getMenuData/',
  closeSession: 'session/closeSession/',
  getSignatures: 'forms/getSignatures',
  getFormDefinition: 'forms/getFormDefinition/',
  executeFormAction: 'forms/executeFormAction/',
};

export class ServerResponseError {
  public _errorCode = '';
  public _errorFullCode = '';
  public _errorName = '';
  public _errorMessage = '';
  public _errorDetail = '';
  public _errorType = '';

  constructor(err?: any) {
    this._errorType = err && err.errorType ? err.errorType : '';
    this._errorCode = err && err.errorCode ? err.errorCode : '';
    this._errorFullCode = err && err.errorFullCode ? err.errorFullCode : '';
    this._errorName = err && err.errorName ? err.errorName : '';
    this._errorMessage = err && err.errorMessage ? err.errorMessage : '';
    this._errorDetail = err && err.errorDetail ? err.errorDetail : '';
  }

  get errorType() { return this._errorType; }
  set errorType(errorType) { this._errorType = errorType ?? ''; }
  get errorCode() { return this._errorCode; }
  set errorCode(errorCode) { this._errorCode = errorCode ?? '999'; }
  get errorFullCode() { return this._errorFullCode; }
  set errorFullCode(errorFullCode) { this._errorFullCode = errorFullCode ?? ''; }
  get errorName() { return this._errorName; }
  set errorName(errorName) { this._errorName = errorName ?? ''; }
  get errorMessage() { return this._errorMessage; }
  set errorMessage(errorMessage) { this._errorMessage = errorMessage ?? ''; }
  get errorDetail() { return this._errorDetail; }
  set errorDetail(errorDetail) { this._errorDetail = errorDetail ?? ''; }
}

export class ServerResponse {
  public data: any;
  public error: ServerResponseError;

  constructor(serverResponse?: any) {
    const error = serverResponse && serverResponse.error ? serverResponse.error : null;
    this.error = new ServerResponseError(error);
    this.data = serverResponse && serverResponse.data ? serverResponse.data : {};
  }

  getError() { return this.error; }
  getData() { return this.data; }
  getMessage() { return this.error.errorMessage; }
  getErrorCode() { return this.error.errorCode; }
  getErrorMessage() { return this.error.errorDetail; }
  hasError() { return this.error.errorCode !== '00'; }

  setMessage(message, detail?) {
    this.error.errorMessage = message;
    this.error.errorDetail = detail ?? message;
  }

  setError(errorCode, errorMessage, errorDetail?) {
    this.error.errorCode = errorCode;
    this.setMessage(errorMessage, errorDetail);
  }
}

@Injectable({ providedIn: 'root' })
export class APIGatewayService {
  private apiRootPath = defaultConfig?.apiRootPath;
  private clientAppCode = defaultConfig?.clientAppCode;
  private signatureKey = defaultConfig?.signatureKey;
  private baseUrl: string;
  private sessionId: string;
  private connectionLost: boolean = false;

  private axiosClient: AxiosInstance;
  constructor(
    private _eventManager: EventManagerService,
    private _storageService: StorageService,
    private _logger: AppLoggerService,
  ) {
    this.baseUrl = this.setBaseUrlApi(defaultConfig?.serverEntryPoint);
    this.createWebClient();
    this._eventManager.subscribe(SESSION_ESTABLISHED, (data) => { data && this.setSessionId(data?.sessionData?.sessionId); });
    this._eventManager.subscribe(CONFIG_LOADED, (data) => { data && this.updateConfiguration(data); });
    this._eventManager.subscribe('sessionSignKeyReceived', data => this.updateSignKey(data));
  }

  updateConfiguration(configData) {
    this._eventManager.next('apiReady', null);
  }

  setBaseUrlApi(serverEntryPoint) {
    let protocol = serverEntryPoint.protocol ?? window.location.protocol;
    protocol = protocol.endsWith(':') ? protocol.slice(0, -1) : protocol;
    let server = serverEntryPoint.server ?? window.location.hostname;
    server = server.endsWith('/') ? server.slice(0, -1) : server;
    let baseUrl = `${protocol}://${server}`;
    const port = serverEntryPoint.port ?? window.location.port;
    baseUrl += port ? `:${port}` : '';
    return urlJoin(baseUrl, this.apiRootPath);
  }

  updateSignKey(signKey) {
    this.signatureKey = signKey;
  }

  setSessionId(id) { this.sessionId = id; }
  openSession(data) { return this.callSrv(API_METHODS.openSession, data); }
  getUserPrivileges() { return this.callSrv(API_METHODS.getUserPrivileges, {}); }
  obtainCurrentSession() { return this.callSrv(API_METHODS.obtainCurrentSession, {}); }
  getMenuData() { return this.callSrv(API_METHODS.getMenuData, {}); }
  closeSession() { return this.callSrv(API_METHODS.closeSession, {}); }
  getSignatures() { return this.callSrv(API_METHODS.getSignatures, {}); }
  getFormDefinition(data) { return this.callSrv(API_METHODS.getFormDefinition, data); }

  async executeFormAction(data) {
    const { formCode } = data;
    const actionResponse = await this.callSrv(urlJoin(API_METHODS.executeFormAction, formCode), data);
    if (actionResponse?.error?._errorName === 'forms-lib-formActionExec-sessionNotFound'
      && actionResponse?.error?._errorCode === '1004') {
      this._eventManager.next('sessionEnded', null);
    }
    return actionResponse;
  }

  createWebClient() {
    const axiosClientParams = {
      timeout: 130000,
      headers: { 'Content-Type': 'application/json' },
      validateStatus: status => (status >= 200 && status < 500),
    };
    this.axiosClient = axios.create(axiosClientParams);
  }

  async getStoredSessionId() {
    const getSession = await this._storageService.getItem(SESSION_ID);
    return getSession?.sessionId ?? null;
  }

  async composeRequest(data) {
    const sessionId = await this.getStoredSessionId();
    const meta: any = {
      version: TUAIN_FROM_VERSION,
      requestDate: moment().format('YYYYMMDDMMHHmmss.SSS'),
      clientAppCode: this.clientAppCode,
      requestReference: nanoid(10),
      sessionId: this.sessionId ?? sessionId,
    };
    let systemSignature = '';
    if (this.signatureKey) {
      const stringToSign = `${JSON.stringify(meta)}${JSON.stringify(data)}`;
      const hash = CryptoJS.HmacSHA256(stringToSign, this.signatureKey);
      systemSignature = hash.toString(CryptoJS.enc.Hex); // Base64
    }
    const deviceSignature = '';
    return { meta, data, requestSignature: { systemSignature, deviceSignature } };
  }

  async callSrv(url, reqData: any): Promise<ServerResponse> {
    let methodResponse: ServerResponse = null;
    const requestError = JSON.parse(JSON.stringify(DEFAULT_ERROR));
    try {
      const composedData = await this.composeRequest(reqData);
      const axiosRequest: AxiosRequestConfig = {
        url: urlJoin(this.baseUrl, url),
        method: 'post',
        data: composedData,
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 499,
      };
      const axiosResponse = await this.axiosClient?.request(axiosRequest);
      requestError.errorMessage = `Solicitud con respuesta ${axiosResponse?.status ?? ''} ${axiosResponse?.statusText ?? ''}`;
      const error = axiosResponse?.data.error ?? JSON.parse(JSON.stringify(requestError));
      const data = axiosResponse?.data.data ?? {};
      methodResponse = new ServerResponse({ error, data });
      this.connectionLost = false;
    } catch (err) {
      // requestError.errorMessage = `Error procesando la solicitud ${url}: ${err?.message}`;
      requestError.errorMessage = `Se perdió temporalmente la conexión, intenta de nuevo en unos minutos`;
      if (!this.connectionLost) {
        this.connectionLost = true;
        this._eventManager.next('communicationFailure', {});
      }
      methodResponse = new ServerResponse({ error: requestError, data: {} });
    }
    return methodResponse;
  }
}
