<div class="timeline">
  <nz-timeline nzMode="left">
    <ng-container *ngFor="let record of visibleRecords()">
      <nz-timeline-item
        [nzPosition]="record.recordData[selectorField] === customerSelectorValue ? 'right' : 'left'"
        [nzDot]="record.recordData[selectorField] === customerSelectorValue ? customerTemplate : bankTemplate"
        [nzLabel]="parseMomentUtc(record.recordData[timeField])">
        <div *ngFor="let column of detailFields">
          <row-field
            [column]="column"
            [fieldCode]="column.fieldCode"
            [fieldType]="column.fieldType"
            [fieldValue]="record.recordData[column.fieldCode]">
          </row-field>
        </div>
      </nz-timeline-item>
    </ng-container>
  </nz-timeline>
</div>

<!-- Plantillas para los mensajes -->
<ng-template #customerTemplate>
  <img [src]="customerIcon" class="dotTemplateIcon" />
</ng-template>

<ng-template #bankTemplate>
  <img [src]="backIcon" class="dotTemplateIcon" />
</ng-template>
