<nz-form-item *ngIf="visible()" style="padding: 0; margin: 0" [class]="customAttributes().displayClass">
  <nz-form-label
    *ngIf="labelVisible && widgetType() !== 'OTPINPUT'"
    style="white-space: normal"
    [nzSpan]="labelSpan"
    [nzRequired]="required()"
    [nzFor]="field.code"
    [nzTooltipTitle]="tooltip()"
    [class]="customAttributes().labelClass">
    {{ title() }}
  </nz-form-label>
  <div nz-col [nzSpan]="fieldSpan" [ngSwitch]="widgetType()">
    <app-array *ngSwitchCase="'ARRAY'" [field]="field"> </app-array>
    <app-alpha-numeric *ngSwitchCase="'ALPHANUM'" [field]="field"></app-alpha-numeric>
    <app-avatar *ngSwitchCase="'AVATAR'" [field]="field"></app-avatar>
    <app-barcode *ngSwitchCase="'BARCODE'" [field]="field"> </app-barcode>
    <app-check *ngSwitchCase="'CHECK'" [field]="field"> </app-check>
    <app-ciiu *ngSwitchCase="'CIIU'" [field]="field"> </app-ciiu>
    <app-city *ngSwitchCase="'CITY'" [field]="field"> </app-city>
    <app-country *ngSwitchCase="'COUNTRY'" [field]="field"> </app-country>
    <app-currency *ngSwitchCase="'CURRENCY'" [field]="field"> </app-currency>
    <app-date *ngSwitchCase="'DATE'" [field]="field"> </app-date>
    <app-date-month *ngSwitchCase="'DATEMONTH'" [field]="field"> </app-date-month>
    <app-daterange *ngSwitchCase="'DATERANGE'" [field]="field"> </app-daterange>
    <app-email *ngSwitchCase="'EMAIL'" [field]="field"> </app-email>
    <app-file-upload *ngSwitchCase="'FILECONTENT'" [field]="field"> </app-file-upload>
    <app-image *ngSwitchCase="'IMAGE'" [field]="field"></app-image>
    <app-input *ngSwitchCase="'INPUT'" [field]="field"> </app-input>
    <app-input-search *ngSwitchCase="'INPUTSEARCH'" [field]="field"> </app-input-search>
    <app-label *ngSwitchCase="'LABEL'" [field]="field"></app-label>
    <app-link *ngSwitchCase="'LINK'" [field]="field"></app-link>
    <app-map *ngSwitchCase="'MAP'" [field]="field"> </app-map>
    <app-message *ngSwitchCase="'MESSAGE'" [field]="field"> </app-message>
    <app-number *ngSwitchCase="'NUMBER'" [field]="field"> </app-number>
    <app-password *ngSwitchCase="'PASSWORD'" [field]="field"> </app-password>
    <app-pdfviewer *ngSwitchCase="'PDF'" [field]="field"></app-pdfviewer>
    <app-phone *ngSwitchCase="'PHONE'" [field]="field"> </app-phone>
    <app-progress *ngSwitchCase="'PROGRESS'" [field]="field"> </app-progress>
    <app-qrcode *ngSwitchCase="'QRCODE'" [field]="field"> </app-qrcode>
    <app-radio *ngSwitchCase="'RADIO'" [field]="field"> </app-radio>
    <app-select *ngSwitchCase="'LIST'" [field]="field"> </app-select>
    <app-segment *ngSwitchCase="'SEGMENT'" [field]="field"></app-segment>
    <app-html *ngSwitchCase="'HTML'" [field]="field"></app-html>
    <app-textarea *ngSwitchCase="'TEXTAREA'" [field]="field"> </app-textarea>
    <app-textformat *ngSwitchCase="'TEXTFORMAT'" [field]="field"> </app-textformat>
    <app-textnumber *ngSwitchCase="'TEXTNUMBER'" [field]="field"> </app-textnumber>
    <app-timerange *ngSwitchCase="'TIMERANGE'" [field]="field"> </app-timerange>
    <app-timerange-pckrs *ngSwitchCase="'TIMERANGEPCKRS'" [field]="field"> </app-timerange-pckrs>
    <app-timerangeslider *ngSwitchCase="'TIMERANGESLIDER'" [field]="field"> </app-timerangeslider>
    <app-time *ngSwitchCase="'TIME'" [field]="field"> </app-time>
    <app-typeahead *ngSwitchCase="'TYPEAHEAD'" [field]="field"> </app-typeahead>
    <app-warning *ngSwitchCase="'WARNING'" [field]="field"></app-warning>
    <app-yearmonth *ngSwitchCase="'YEARMONTH'" [field]="field"></app-yearmonth>
    <app-otp-input *ngSwitchCase="'OTPINPUT'" [field]="field"> </app-otp-input>
  </div>
</nz-form-item>
