export function formatCurrency(inputValue) {
  if (!inputValue) { return null; }
  const numeralDecimalMark = '.';
  const numeralPositiveOnly = false;
  const stripLeadingZeroes = true;
  const numeralIntegerScale = 12;
  const numeralDecimalScale = 2;
  const delimiter = ',';
  let parts;
  let partInteger;
  let partDecimal = '';

  // Se eliminan los caracteres alfabéticos
  let outputValue = inputValue.toString();
  if (!outputValue) {
    return;
  }
  /**
   * Se remplaza el primer caracter de marca decimal con el marcador reservado
   * Se eliminan los caractertes no numéricos excepto el signo menos y 'M'
   * utilizado como separador decimal en este punto, eliminando los prefijos
   * Se remplaza el caracter menos por un marcador resertvado
   * Se eliminan todos los demás caracteres menos en caso de estar presentes
   * Se remplaza el marcador de caracter menos (si está presente)
   * Se remplaza el marcador de separación decimal
   */
  let value = outputValue.replace(/[A-Za-z]/g, '')
    .replace(numeralDecimalMark, 'M')
    .replace(/[^\dM-]/g, '')
    .replace(/^\-/, 'N')
    .replace(/\-/g, '')
    .replace('N', numeralPositiveOnly ? '' : '-')
    .replace('M', numeralDecimalMark);
  // Se eliminan los ceros a la izquierda
  if (stripLeadingZeroes) {
    value = value.replace(/^(-)?0+(?=\d)/, '$1');
  }
  // Se separa la parte entera de la parte decimal
  partInteger = value;
  if (value.indexOf(numeralDecimalMark) >= 0) {
    parts = value.split(numeralDecimalMark);
    partInteger = parts[0];
    partDecimal = numeralDecimalMark + parts[1].slice(0, numeralDecimalScale);
  }
  if (numeralIntegerScale > 0) {
    partInteger = partInteger.slice(0, numeralIntegerScale + (value.slice(0, 1) === '-' ? 1 : 0));
  }
  // Se separan los digitos de acuerdo a la configuraciñon de agrupación
  partInteger = partInteger.replace(/(\d)(?=(\d{3})+$)/g, '$1' + delimiter);
  outputValue = partInteger.toString() + (numeralDecimalScale > 0 ? partDecimal.toString() : '');
  return outputValue;
}

export function formatNumber(value): string {
  const stringValue = `${value}`;
  const list = stringValue.split('.');
  const prefix = list[0]?.charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export function formatCreditCard(value): string {
  let num = `${value}`;
  let result = '';
  while (num.length > 4) {
    result = `${result}${num.slice(0, 4)} `;
    num = num.slice(4, num.length);
  }
  if (num) {
    result = result + num;
  }
  return `${result}`;
}
