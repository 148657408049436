<nz-collapse nzGhost *ngIf="errorTitle">
  <nz-collapse-panel (nzActiveChange)="toggleDetail()" [nzHeader]="titleTemplate" nzShowArrow="false" [ngStyle]="errorStyle">
    </nz-collapse-panel>
</nz-collapse>

<!-- <ng-template > -->
  <nz-alert #titleTemplate *ngIf="errorTitle || errorMessage" nzShowIcon [nzType]="type" nzMessage="{{errorTitle}}"
    [nzDescription]="showDetail ? errorMessage : ''">
  </nz-alert>
<!-- </ng-template> -->
