<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <!-- [accept]="accept" [multiple]="'false'" [maxFileSize]="maxFileSize -->
    <ngx-dropzone multiple="false" (change)="onSelect($event)">
      <ngx-dropzone-label>{{ body }}</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </nz-form-control>
</nz-form-item>
