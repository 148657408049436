import { Injectable } from '@angular/core';
import { LibEventManagerService } from 'tuain-ng-forms-lib';

const eventTypes = {
  behaviour: 'behaviour',
  subject: 'subject',
  reply: 'reply'
};

const eventClasses = [
  // { name, type, initialValue, windowTime, timestampProvider }
  { name: 'agentStatus', type: eventTypes.behaviour },
  { name: 'apiReady', type: eventTypes.subject },
  { name: 'balanceUpdated', type: eventTypes.behaviour },
  { name: 'chat', type: eventTypes.behaviour },
  { name: 'chatMessage', type: eventTypes.behaviour },
  { name: 'chgTxnActiveGroup', type: eventTypes.behaviour },
  { name: 'closeInvasiveNotification', type: eventTypes.subject },
  { name: 'communicationFailure', type: eventTypes.subject },
  { name: 'compromisedDevice', type: eventTypes.subject },
  { name: 'configLoaded', type: eventTypes.behaviour },
  { name: 'enableNotifications', type: eventTypes.subject },
  { name: 'endUserEndSession', type: eventTypes.subject },
  { name: 'endUserRequireSession', type: eventTypes.subject },
  { name: 'endUserSessionActive', type: eventTypes.behaviour },
  { name: 'endUserSessionEstablished', type: eventTypes.behaviour },
  { name: 'endUserSessionObtained', type: eventTypes.behaviour },
  { name: 'endUserStartedInFavs', type: eventTypes.behaviour },
  { name: 'endUserStartSession', type: eventTypes.subject },
  { name: 'formActivity', type: eventTypes.subject },
  { name: 'invasiveNotification', type: eventTypes.behaviour },
  { name: 'menuObtained', type: eventTypes.behaviour },
  { name: 'messageRead', type: eventTypes.behaviour },
  { name: 'notifyFormAfterSessionEnd', type: eventTypes.behaviour },
  { name: 'notifyFormAfterSessionStart', type: eventTypes.behaviour },
  { name: 'noSessionNext', type: eventTypes.behaviour },
  { name: 'notAuthorized', type: eventTypes.subject },
  { name: 'notification', type: eventTypes.behaviour },
  { name: 'pendingNotifications', type: eventTypes.behaviour },
  { name: 'pendingTickets', type: eventTypes.behaviour },
  { name: 'profileObtained', type: eventTypes.behaviour },
  { name: 'serverProcessEnded', type: eventTypes.subject },
  { name: 'serverProcessStarted', type: eventTypes.subject },
  { name: 'sessionEncryptKeyReceived', type: eventTypes.behaviour },
  { name: 'sessionEnded', type: eventTypes.subject },
  { name: 'sessionEstablished', type: eventTypes.behaviour },
  { name: 'sessionObtained', type: eventTypes.behaviour },
  { name: 'sessionSignKeyReceived', type: eventTypes.behaviour },
  { name: 'sessionToBeClosed', type: eventTypes.subject },
  { name: 'startApp', type: eventTypes.behaviour },
  { name: 'storeServices', type: eventTypes.behaviour },
  { name: 'txnGroupSelection', type: eventTypes.behaviour },
  { name: 'updateAgentBalance', type: eventTypes.behaviour },
  { name: 'updateLastActivityDate', type: eventTypes.subject },
  { name: 'updateNotifications', type: eventTypes.behaviour },
  { name: 'updateTickets', type: eventTypes.behaviour },
];

@Injectable({ providedIn: 'root' })
export class EventManagerService extends LibEventManagerService {
  constructor() { super(eventClasses); }
}
