export const moduleRoutes = {
  root: 'login',
  childForms: {
    login: {
      data: { title: 'Auth / Login' },
      requireAuth: false,
      functions: [],
      route: 'login',
    },
  },
  layout: 'login',
  loadChildren: () => import('src/app/page-modules/login/login.module').then(m => m.LoginModule),
};
