export const moduleRoutes = {
  root: 'eu-root',
  childForms: {
    endUserNonCustomer: {
      data: { title: 'Servicios' },
      requireAuth: false,
      functions: [],
      route: 'endUserNonCustomer',
    },
    endUserServices: {
      data: { title: 'Servicios' },
      requireAuth: false,
      functions: [],
      route: 'endUserServices',
    },
    endUserTransactions: {
      data: { title: 'Transacciones' },
      requireAuth: false,
      functions: [],
      route: 'endUserTransactions',
    },
    endUserLocations: {
      data: { title: 'Ubicación' },
      requireAuth: false,
      functions: [],
      route: 'endUserLocations',
    },
  },
  layout: 'eumain',
  loadChildren: () => import('src/app/page-modules/end-user/eu-root-page/eu-root-page.module').then(m => m.EndUserRootPageModule)
};
