<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
      <input
        #inputElement
        nz-input
        [id]="field.code"
        nzOverlayClassName="numeric-input"
        [ngModel]="phoneNumber"
        (ngModelChange)="onChangeModel($event)"
        [readOnly]="disabled()"
        [placeholder]="placeholder()"
        (blur)="onChangeContent()"
        autocomplete="off"
        [disabled]="disabled()"
        [attr.maxlength]="maxLength()" />
    </nz-input-group>
  </nz-form-control>
</nz-form-item>

<ng-template #addOnBeforeTemplate>
  <span *ngIf="phoneCountry" class="fi fi-{{ flag(phoneCountry) }}"></span>
  &nbsp;
  <nz-select
    [nzNotFoundContent]="nzEmpty"
    style="min-width: 80px"
    [nzDropdownStyle]="{ 'min-width': '250px' }"
    [id]="field.code"
    [(ngModel)]="phoneCountry"
    (ngModelChange)="inputChanged()"
    nzShowSearch
    nzPlaceHolder="País"
    [nzAllowClear]="!required()"
    [nzFilterOption]="filterInput"
    [disabled]="disabled()">
    <nz-option
      *ngFor="let country of countries"
      nzCustomContent
      nzLabel="+{{ country.code }}"
      [nzValue]="country.code"
      [nzDisabled]="disabled()">
      <span class="fi fi-{{ country.isoCodes.toLowerCase() }}"></span>
      (+{{ country.code }})
      {{ country.name }}
    </nz-option>
  </nz-select>
</ng-template>
<ng-template #nzEmpty>
  <div style="display: flex; justify-content: center">
    <span nz-icon nzType="cloud" nzTheme="twotone" style="font-size: 35px"></span>
  </div>
  <div style="display: flex; justify-content: center">Sin resultados</div>
</ng-template>
