import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

const encryptKey = CryptoJS.enc.Utf8.parse('b75524255a7f54d2726a951bb39204df');
const iv = CryptoJS.enc.Utf8.parse('1583288699248111');

function hash(value, key) {
  const hashValue = CryptoJS.SHA256(value, key);
  return hashValue.toString();
}

function encrypt(data) {
  data = CryptoJS.AES.encrypt(data, encryptKey, { iv: iv });
  return data.toString(CryptoJS.enc.Utf8);
}

function decrypt(data) {
  data = CryptoJS.AES.decrypt(data, encryptKey, { iv: iv });
  return data.toString(CryptoJS.enc.Utf8);
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  getItem(key, options = null) {
    const cypherKey = options?.cypherKey ?? false;
    const decryptContent = options?.encrypted ?? false;
    const newKey = (cypherKey) ? hash(key, encryptKey) : key;
    const value = localStorage.getItem(newKey);
    const itemValue = (value && decryptContent) ? decrypt(value) : value;
    return (itemValue) ? JSON.parse(itemValue) : null;
  }

  setItem(key, value, options = null) {
    const cypherKey = options?.cypherKey ?? false;
    const encryptContent = options?.encrypted ?? false;
    const newKey = (cypherKey) ? hash(key, encryptKey) : key;
    let valueToStore = JSON.stringify(value);
    valueToStore = (encryptContent) ? encrypt(valueToStore) : valueToStore;
    return localStorage.setItem(newKey, valueToStore);
  }

  removeItem(key, options = null) {
    const cypherKey = options?.cypherKey ?? false;
    const newKey = (cypherKey) ? hash(key, encryptKey) : key;
    return localStorage.removeItem(newKey);
  }
}
