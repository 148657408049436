<div class="keyValueTable">
  <nz-list [id]="table.tableCode">
    <ng-container *ngFor="let record of visibleRecords()">
      <nz-list-item>
        <div nz-row style="width: 100%">
          <div nz-col nzSpan="12">
            <span class="columnKey">
              {{ record?.recordData?.key }}
            </span>
          </div>
          <div nz-col nzSpan="12">
            <span class="columnValue">
              {{ record?.recordData?.value }}
            </span>
          </div>
        </div>
      </nz-list-item>
    </ng-container>
  </nz-list>
</div>
