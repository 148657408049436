import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { GoogleMap, MapMarker, MapInfoWindow } from '@angular/google-maps'
import { Observable, of, from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { defaultConfig } from '../app.config';

export class GeocoderResponse {
  status: string;
  error_message: string;
  results: google.maps.GeocoderResult[];

  constructor(status: string, results: google.maps.GeocoderResult[]) {
    this.status = status;
    this.results = results;
  }
}

@Injectable({ providedIn: 'root' })
export class GeocodeService {
  private axiosClient: AxiosInstance;

  constructor(private http: HttpClient) {
    // this.axiosClient = axios.create();
  }

  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              resolve({
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude
              });
            }
          },
          (error) => console.log(error)
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }

  geocodeLatLng(location: google.maps.LatLngLiteral): Promise<GeocoderResponse> {
    let geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results, status) => {
        const response = new GeocoderResponse(status, results);
        resolve(response);
      });
    });
  }

  getLocation(term: string): Observable<GeocoderResponse> {
    // const axiosRequest: AxiosRequestConfig = {
    //   url: `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${defaultConfig.googleApiKey}`,
    //   method: 'get',
    //   data: this.composeRequest(reqData),
    //   withCredentials: true,
    // };
    const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${defaultConfig.googleApiKey}`;
    return this.http.get<GeocoderResponse>(url);
  }
}