<div *ngIf="value()" class="row d-flex justify-content-center qrcodeImage">
  <div>
    <qrcode
      class="qrCode_"
      [qrdata]="value()"
      [colorDark]="'#1e97a0'"
      [colorLight]="'#ffffffff'"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'M'"
      [width]="300">
    </qrcode>
  </div>
  <div class="numericCode">{{ value() }}</div>
</div>
