import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-time',
  templateUrl: './app-time.component.html',
  styles: [`nz-date-picker ::ng-deep .ant-calendar-picker { width: 100%; }
    nz-date-picker, nz-time-picker { width: 100%; }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTimeComponent extends FieldComponent {
  style: string;
}
