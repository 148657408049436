import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { countries } from '../phone/country';

@Component({
  selector: 'app-country',
  templateUrl: './app-country.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCountryComponent extends FieldComponent {
  countries = countries;

  filterInput(inputText, option) {
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    const country = countries.find(item => item.code === nzValue);
    return (!nzDisabled && !nzHide && (nzLabel.includes(inputText)
      || nzValue?.toString()?.includes(inputText) || country?.name.includes(inputText)));
  }
}
