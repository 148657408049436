import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { TuainNgFormsLibModule } from 'tuain-ng-forms-lib';
import { CustomComponentsModule } from '../custom/custom-component.module';
import { AntdModule } from 'src/app/antd.module';

import { TopbarComponent } from './top-bar/topbar.component';
import { TopbarMessagesComponent } from './messages/messages.component';
import { TopbarNotificationsComponent } from './notifications/notifications.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { TxnMenucomponent } from './txn-menu/txn-menu.component';

import { GreetingsComponent } from './greetings/greetings.component';
import { BalanceCardComponent } from './balance-card/balance-card.component';
import { OtherServicesComponent } from './other-services/other-services.component';
// ENDUSER
import { EndUserSideMenuComponent } from './eu-side-menu/eu-side-menu.component';

const COMPONENTS = [
  TopbarComponent,
  TopbarMessagesComponent,
  TopbarNotificationsComponent,
  TopMenuComponent,
  TxnMenucomponent,
  GreetingsComponent,
  BalanceCardComponent,
  OtherServicesComponent,
  EndUserSideMenuComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AntdModule,
    TranslateModule,
    NgScrollbarModule,
    FontAwesomeModule,
    NgxDropzoneModule,
    TuainNgFormsLibModule,
    CustomComponentsModule,
    PickerModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  bootstrap: [],
  schemas: [],
})
export class FormBasedComponentsModule { }
