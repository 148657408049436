import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-textarea',
  templateUrl: './app-textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTextAreaComponent extends FieldComponent {
  @Input() rows: Number = 4;
}
