<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <a nz-button nzType="link" (click)="inputChanged()">
      <nz-avatar
        *ngIf="!value() || !customAttributes().img"
        [nzSize]="64"
        [nzIcon]="customAttributes().icon ?? 'user'"
        [nzShape]="customAttributes().shape"
        [nzSrc]="value()"></nz-avatar>
      <img *ngIf="customAttributes().img && value()" [src]="value()" [alt]="title()" style="max-height: 3rem" />
    </a>
    <br />
    <br />
  </nz-form-control>
</nz-form-item>
