export const defaultForms = {
  login: {
    form: {
      formCode: 'login',
      formTitle: 'Login Universal',
      requireSession: false,
    },
    relatedFunctions: [],
    requiredFunctions: [],
    states: [
      {
        name: 'credentialInput',
        description: 'Ingreso de credenciales',
      },
      {
        name: 'credentialInputUnlock',
        description: 'Ingreso de credenciales',
      },
      {
        name: 'complementaryInput',
        description: 'Ingreso de información complementaria',
      },
      {
        name: 'sessionOpenning',
        description: 'Apertura de sesión',
      },
    ],
    transitions: [
      {
        name: 'lockedUser',
        description: 'Usuario Bloqueado',
        source: 'credentialInput',
        destination: 'credentialInputUnlock',
      },
      {
        name: 'validateCredential',
        description: 'Validar Credenciales',
        source: 'credentialInput',
        destination: 'complementaryInput',
      },
      {
        name: 'openSessionFromCredentials',
        description: 'Abrir sesión desde credenciales',
        source: 'credentialInput',
        destination: 'sessionOpenning',
      },
      {
        name: 'enterComplementaryData',
        description: 'Ingreso de datos complementarios',
        source: 'credentialInput',
        destination: 'complementaryInput',
      },
      {
        name: 'openSessionFromExtra',
        description: 'Abrir sesión desde datos extra',
        source: 'complementaryInput',
        destination: 'sessionOpenning',
      },
    ],
    sections: [
      {
        sectionCode: 'credentialsInfo',
        sectionTitle: 'Información de credenciales',
        visibleStates: [
          'credentialInput',
          'credentialInputUnlock',
          'complementaryInput',
          'sessionOpenning',
        ],
        subsections: [
          {
            subsectionCode: 'credentials',
            subsectionTitle: 'Credenciales',
            visibleStates: [
              'credentialInput',
              'credentialInputUnlock',
              'complementaryInput',
              'sessionOpenning',
            ],
            elements: [
              {
                elementTypeName: 'FIELD',
                elementCode: 'userName',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'userPassword',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'loginOptions',
              },
            ],
          },
        ],
      },
      {
        sectionCode: 'complementaryInfo',
        sectionTitle: 'Información complementaria',
        visibleStates: [],
        subsections: [
          {
            subsectionCode: 'deviceInfo',
            subsectionTitle: 'Información del dispositivo',
            visibleStates: [],
            elements: [
              {
                elementTypeName: 'FIELD',
                elementCode: 'deviceChallenge',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'deviceChallengeControl',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'signedChallenge',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'deviceId',
              },
            ],
          },
          {
            subsectionCode: 'sessionData',
            subsectionTitle: 'Información de la sesión',
            visibleStates: [],
            elements: [
              {
                elementTypeName: 'FIELD',
                elementCode: 'sessionCode',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'sessionHandler',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'sessionCipheredSignKey',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'sessionCipheredEncryptKey',
              },
              {
                elementTypeName: 'FIELD',
                elementCode: 'sessionPublicData',
              },
            ],
          },
        ],
      },
      {
        sectionCode: 'loginControls',
        sectionTitle: 'Controles de acceso',
        visibleStates: [
          'credentialInput',
          'credentialInputUnlock',
          'complementaryInput',
          'sessionOpenning',
        ],
        subsections: [
          {
            subsectionCode: 'loginActions',
            subsectionTitle: 'Acciones de ingreso',
            visibleStates: [
              'credentialInput',
              'credentialInputUnlock',
              'complementaryInput',
              'sessionOpenning',
            ],
            elements: [
              {
                elementTypeName: 'ACTION',
                elementCode: 'prepareLogin',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'validateCredentials',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'completeUserLogin',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'recoverUser',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'unlockUser',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'recoverPassword',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'registerUser',
              },
              {
                elementTypeName: 'ACTION',
                elementCode: 'removeDevice',
              },
            ],
          },
        ],
      },
    ],
    fields: [
      {
        fieldCode: 'userName',
        fieldTitle: 'Correo electrónico',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: true,
        editable: true,
        required: true,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [
          'credentialInput',
          'credentialInputUnlock',
          'complementaryInput',
          'sessionOpenning',
        ],
        editableStates: [
          'credentialInput',
        ],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'credentialsInfo',
        subsectionCode: 'credentials',
      },
      {
        fieldCode: 'userPassword',
        fieldTitle: 'Contraseña',
        fieldTypeCode: 'TEXT',
        captureType: 'PASSWORD',
        placeholder: 'Ingresa tu contraseña',
        visible: true,
        editable: true,
        required: true,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [
          'credentialInput',
          'credentialInputUnlock',
          'complementaryInput',
          'sessionOpenning',
        ],
        editableStates: [
          'credentialInput',
        ],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'credentialsInfo',
        subsectionCode: 'credentials',
      },
      {
        fieldCode: 'loginOptions',
        fieldTitle: 'loginOptions',
        fieldTypeCode: 'TEXT',
        captureType: 'LIST',
        visible: false,
        editable: true,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [
          'complementaryInput',
          'sessionOpenning',
        ],
        editableStates: [
          'complementaryInput',
        ],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'credentialsInfo',
        subsectionCode: 'credentials',
      },
      {
        fieldCode: 'deviceChallenge',
        fieldTitle: 'deviceChallenge',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'deviceInfo',
      },
      {
        fieldCode: 'deviceChallengeControl',
        fieldTitle: 'deviceChallengeControl',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'deviceInfo',
      },
      {
        fieldCode: 'signedChallenge',
        fieldTitle: 'signedChallenge',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'deviceInfo',
      },
      {
        fieldCode: 'sessionCode',
        fieldTitle: 'sessionCode',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'sessionData',
      },
      {
        fieldCode: 'sessionHandler',
        fieldTitle: 'sessionHandler',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'sessionData',
      },
      {
        fieldCode: 'sessionCipheredSignKey',
        fieldTitle: 'sessionCipheredSignKey',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'sessionData',
      },
      {
        fieldCode: 'sessionCipheredEncryptKey',
        fieldTitle: 'sessionCipheredEncryptKey',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'sessionData',
      },
      {
        fieldCode: 'sessionPublicData',
        fieldTitle: 'sessionPublicData',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'sessionData',
      },
      {
        fieldCode: 'deviceId',
        fieldTitle: 'deviceId',
        fieldTypeCode: 'TEXT',
        captureType: 'INPUT',
        visible: false,
        editable: false,
        required: false,
        serverAction: false,
        restricted: false,
        fieldOptions: [],
        visibleStates: [],
        editableStates: [],
        alignment: 'LEFT',
        format: '',
        defaultValue: '',
        tooltip: '',
        sectionCode: 'complementaryInfo',
        subsectionCode: 'deviceInfo',
      },
    ],
    actions: [
      {
        actionCode: 'prepareLogin',
        actionTitle: 'prepareLogin',
        visible: false,
        serverAction: true,
        visibleStates: [],
        enabledStates: [],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGN',
        newState: '',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
      },
      {
        actionCode: 'validateCredentials',
        actionTitle: 'Ingresar aquí',
        visible: true,
        serverAction: true,
        visibleStates: [
          'credentialInput',
          'sessionOpenning',
        ],
        enabledStates: [
          'credentialInput',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGIN',
        newState: '', // 'sessionOpenning',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
      },
      {
        actionCode: 'completeUserLogin',
        actionTitle: 'Ingresar',
        visible: true,
        serverAction: true,
        visibleStates: [
          'complementaryInput',
        ],
        enabledStates: [
          'complementaryInput',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGIN',
        newState: '', // 'sessionOpenning',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
      },
      {
        actionCode: 'recoverUser',
        actionTitle: 'Olvidé mi usuario',
        visible: true,
        serverAction: false,
        visibleStates: [
          'credentialInput',
        ],
        enabledStates: [
          'credentialInput',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGIN',
        newState: '',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
        customAttributes: {
          displayType: 'link',
        },
      },
      {
        actionCode: 'unlockUser',
        actionTitle: 'Desbloquear usuario',
        visible: true,
        serverAction: false,
        visibleStates: [
          'credentialInputUnlock',
        ],
        enabledStates: [
          'credentialInputUnlock',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGIN',
        newState: '',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
        customAttributes: {
          inline: true,
        },
      },
      {
        actionCode: 'recoverPassword',
        actionTitle: 'Olvidé mi contraseña',
        visible: true,
        serverAction: false,
        visibleStates: [
          'credentialInput',
        ],
        enabledStates: [
          'credentialInput',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'LOGIN',
        newState: '',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
        customAttributes: {
          displayType: 'link',
        },
      },
      {
        actionCode: 'registerUser',
        actionTitle: 'Regístrate aquí',
        visible: true,
        serverAction: false,
        visibleStates: [
          'credentialInput',
        ],
        enabledStates: [
          'credentialInput',
        ],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: 'REGISTER',
        newState: '', // 'sessionOpenning',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
        customAttributes: {
          inline: true,
        },
      },
      {
        actionCode: 'removeDevice',
        actionTitle: 'removeDevice',
        visible: false,
        serverAction: true,
        visibleStates: [],
        enabledStates: [],
        position: 'BODY',
        fieldRestrictedCode: null,
        operatorRestricted: null,
        valueRestricted: null,
        iconName: '',
        newState: '',
        sectionCode: 'loginControls',
        subsectionCode: 'loginActions',
      },
    ],
    tables: [],
  },
};
