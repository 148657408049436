<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-select
      [id]="field.code"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      nzShowSearch
      [nzPlaceHolder]="placeholder()"
      [nzAllowClear]="!required()"
      [disabled]="disabled()"
      [nzFilterOption]="filterInput">
      <nz-option
        *ngFor="let country of countries"
        nzCustomContent
        [nzLabel]="country.name"
        [nzValue]="country.name"
        [nzDisabled]="disabled()">
        <span class="fi fi-{{ country.isoCodes.toLowerCase() }}"></span>
        {{ country.name }}
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
