<ng-container *ngIf="visible()">
  <div [ngSwitch]="customAttributes().displayType" lines="none">
    <app-multi-line-table *ngSwitchCase="'multiLine'" [table]="table"> </app-multi-line-table>
    <app-tri-column-table *ngSwitchCase="'dynamic'" [table]="table"> </app-tri-column-table>
    <app-key-values-table *ngSwitchCase="'keyValues'" [table]="table"> </app-key-values-table>
    <app-message-list-table *ngSwitchCase="'messageList'" [table]="table"></app-message-list-table>
    <app-message-table *ngSwitchCase="'message'" [table]="table"></app-message-table>
    <app-time-line-table *ngSwitchCase="'timeLine'" [table]="table"></app-time-line-table>
    <app-grid-table *ngSwitchDefault [table]="table"> </app-grid-table>
  </div>
</ng-container>