import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'app-image',
  templateUrl: './app-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppImageComponent extends FieldComponent {
  @Input() fieldClass: string = '';

  constructor(private nzImageService: NzImageService) {
    super();
  }

  preview() {
    this.nzImageService.preview([{ src: this.value() }], { nzZoom: 1.5, nzRotate: 0 });
  }
}
