<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-input-group [nzAddOnAfter]="addOnAfterTemplate" [nzPrefixIcon]="prefixIcon">
      <input
        nz-input
        [id]="field.code"
        [type]="inputType"
        [(ngModel)]="value"
        (change)="inputChanged()"
        (keyup)="inputTyped()"
        [attr.maxlength]="maxLength()"
        [disabled]="disabled()"
        [placeholder]="placeholder()"
        autocomplete="off" />
    </nz-input-group>
    <ng-template #addOnAfterTemplate>
      <app-icon [iconName]="icon" (click)="togglePwdView()"></app-icon>
    </ng-template>
  </nz-form-control>
</nz-form-item>
