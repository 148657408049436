import { Component } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

const SESSION_ENDED = 'sessionEnded';

@Component({
  selector: 'app-eu-layout-main',
  templateUrl: './eu-main.component.html',
  styleUrls: ['./eu-main.component.scss'],
})
export class LayoutEndUserMainComponent extends AppFormComponent {
  menuData: any[];
  headerMenuItem: any;
  showAlert: boolean;
  header: string;
  message: string;
  isAuthenticated: boolean;

  override preStart() { this.name = 'endUserNonCustomer'; }

  override start() {
    super.start();
    this.isAuthenticated = this.getExtraInfo('isAuthenticated');
    const endUserIdType = this._storage.getItem('endUserIdType');
    const endUserIdNumber = this._storage.getItem('endUserIdNumber');
    if (!endUserIdType || !endUserIdNumber) {
      this.openForm('endUserAccess');
    }
    const menuData = this.getExtraInfo('menuData');
    this.buildMenu(menuData);
  }

  buildMenu(data) {
    this.menuData = data ? this.preprocessMenudata(data) : [];
  }

  preprocessMenudata(menuData) {
    menuData?.forEach((item) => {
      if (item.icon) {
        let pageUrl = `/${this._componentPageService.formRoute(item?.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
        item.url = pageUrl;
        item.iconName = item.icon;
        item.key = item.form;
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }

  openItemForm(menuItem) {
    const { form: formName } = menuItem;
    const formData = menuItem?.formData ?? null;
    if (formName === 'welcome') {
      this.showModalDialog('Cierre de sesión', '¿Está seguro que desea cerrar la sesión actual?',
        ['Aceptar', 'Cancelar'], (opt) => {
          if (opt === 0) {
            this.emitAppEvents(SESSION_ENDED, { endDate: new Date() });
          }
        });
    } else {
      this.openForm(formName, formData);
    }
  }
}