<div *ngIf="value()" class="codeContainer">
  <ngx-barcode6
    bc-line-color="var(--antd-wave-shadow-color)"
    bc-width="3"
    bc-height="150"
    bc-text-margin="8"
    [bc-value]="value()"
    [bc-display-value]="true">
  </ngx-barcode6>
</div>
