import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LibTableComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-grid-table',
  templateUrl: './app-grid-table.component.html',
  styles: [`
      .search-box { padding: 8px; }
      .search-box input { width: 188px; margin-bottom: 8px; display: block; }
      .search-box button { width: 90px; }
      .search-button { margin-right: 8px; }
    `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppGridTableComponent extends LibTableComponent {
  activeColumnSearch: any;
  activeSearchText: string;
  selectionSortable: boolean;
  selectionColumn: any;

  leftFixedColumns: number = 0;
  rightFixedColumns: number = 0;
  totalColumns: number;

  override start() {
    super.start();
    const fixedColumns = this.table.getCustomAttribute('fixedColumns');
    this.leftFixedColumns = fixedColumns && Array.isArray(fixedColumns) ? fixedColumns?.[0] : 0;
    this.rightFixedColumns = fixedColumns && Array.isArray(fixedColumns) ? fixedColumns?.[0] : 0;
    this.totalColumns = this.columns().length;
    this.selectionColumn = this.table?.columns?.find(item => item.fieldCode === this.table.selectionField);
    this.selectionSortable = this.selectionColumn?.sortable;
  }

  openSearch(column, visible) {
    this.activeColumnSearch = visible ? column : null;
    this.activeSearchText = this.activeColumnSearch?.filter?.values?.[0] ?? '';
  }

  search() {
    this.activeColumnSearch && this.table.addColumnFilter(this.activeColumnSearch.fieldCode, [this.activeSearchText], 'HAS');
    this.activeColumnSearch.filterVisible = false;
  }

  reset() {
    this.activeSearchText = '';
    this.activeColumnSearch && this.table.removeColumnFilter(this.activeColumnSearch.fieldCode);
    this.activeColumnSearch.filterVisible = false;
  }

  sortOrderHasChanged(column, direction) {
    this.tableColumnSort(column.fieldCode, direction);
  }
}
