<div class="navigation cardBox">
  <nz-tabset [(nzSelectedIndex)]="currentSelection" (nzSelectedIndexChange)="transactionGroupChanged($event)">
    <ng-container *ngFor="let serviceGroup of services; index as i">
      <nz-tab [nzTitle]="serviceGroup.title">
        <div class="menuGroup">
          <ng-container *ngFor="let service of serviceGroup?.children">
            <div class="groupItem" [ngClass]="{
                itemSelected: (selectedItemMenu === service),
                itemStandard: (selectedItemMenu !== service),
                itemDisabled: (!agentBalance || !transactionalUser || !validTime || !validPosition)
              }"
              (click)="openItemForm(service)">
              <a class="itemIcon">
                <app-icon [iconName]="service.iconName" collection="awesome"></app-icon>
                {{service.title}}
              </a>
            </div>
          </ng-container>
        </div>
      </nz-tab>
    </ng-container>
  </nz-tabset>
</div>