import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ElementComponent } from 'tuain-ng-forms-lib';
import { componentConstants } from 'src/app/components/tuain/forms.module.config';

@Component({
  selector: 'app-element',
  templateUrl: './app-element.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppElementComponent extends ElementComponent {
  componentConstants: any = { ...componentConstants };
}
