<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <nz-input-group [nzAddOnAfter]="" [nzPrefix]="preffix">
      <input
        #inputElement
        nz-input
        [id]="field.code"
        nzOverlayClassName="numeric-input"
        [ngModel]="value()"
        (ngModelChange)="onChangeModel($event)"
        [readOnly]="disabled()"
        [placeholder]="placeholder()"
        [attr.maxlength]="maxLength()"
        (blur)="makeReady()"
        autocomplete="off"
        [disabled]="disabled()" />
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
