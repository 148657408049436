import { Component, Output, EventEmitter } from '@angular/core';
import { ComponentPageService } from 'src/app/services/component-pages.service';

const SESSION_ENDED = 'sessionEnded';

const menuData = [
  {
    functions: [],
    title: 'Actualizar contraseña',
    icon: 'CHANGEPASSWORD',
    form: 'endUserRegister',
    formData: {
      state: 'otpValidation',
      fields: {
        isChangePwdPath: true,
      },
    },
  },
  {
    functions: [],
    title: 'Cerrar sesión',
    icon: 'LOCK',
    form: 'welcome',
  },
];

@Component({
  selector: 'app-topbar-end-user-menu',
  templateUrl: './end-user-menu.component.html',
  styleUrls: ['./end-user-menu.component.scss'],
})
export class TopbarEndUserMenuComponent {
  userInfo: any = {};
  badgeCount = 7;
  menuData: any[];

  @Output() actionSelected = new EventEmitter();

  constructor(
    private _componentPageService: ComponentPageService,
  ) {
    this.buildMenu(menuData);
  }

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1;
  }

  buildMenu(data) {
    this.menuData = this.preprocessMenudata(data);
  }

  preprocessMenudata(menuData) {
    menuData.forEach((item) => {
      if (item.icon) {
        let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
        item.url = pageUrl;
        item.iconName = item.icon;
        item.key = item.form;
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }

  activateMenu(option) {
    const { form, formData } = option;
    this.actionSelected.emit({ form, formData });
  }
}
