import { Component, ElementRef, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

const SEARCH_ICON = 'SEARCH';
@Component({
  selector: 'app-input-search',
  templateUrl: './app-input-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInputSearchComponent extends FieldComponent {
  @Input() prefixIcon = '';
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  override focus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  openSearch() {
    this.onShowInfo('selectProduct');
  }
}
