import { Component, Input } from '@angular/core';
import { SectionComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-section',
  templateUrl: './app-section.component.html',
})
export class AppSectionComponent extends SectionComponent {
  standardSubsections: any[] = [];
  specialSubsections: any[] = [];
  @Input() showHeader = false;
  @Input() showSubsectionHeaders = false;
  @Input() attributes: any = {};

  override start(): void {
    this.section.subSections.forEach(sub => {
      const displayType = sub.getCustomAttribute('displayType');
      if (displayType === 'isolated') {
        this.specialSubsections.push(sub);
      } else {
        this.standardSubsections.push(sub);
      }
    });
    super.start();
  }
}
