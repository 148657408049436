import { Injectable } from '@angular/core';
import { EventManagerService } from './event-manager.service';
import { io } from 'socket.io-client';
import { defaultConfig } from '../app.config';

const MESSAGE = 'message';
const CLIENT_EVENT = 'clientEvent';
const DISCONNECT_EVENT = 'disconnect';
const CONFIG_LOADED = 'configLoaded';
const SESSION_ESTABLISHED = 'sessionEstablished';
const END_USER_END_SESSION = 'endUserEndSession';
const SESSION_ENDED = 'sessionEnded';

@Injectable({ providedIn: 'root' })
export class LiveConnectionService {
  socket: any;
  baseUrl: string;
  defaultEnabled: boolean = false;
  keepConnected: boolean = false;
  sessionData: boolean = false;

  constructor(
    private _eventManager: EventManagerService) {
    this.baseUrl = this.setBaseUrlApi(defaultConfig?.serverEntryPoint);
    this._eventManager.subscribe(CONFIG_LOADED, (data) => {
      this.defaultEnabled = data?.liveConnection ?? false;
      this.checkLiveConnection();
    });
    this._eventManager.subscribe(SESSION_ESTABLISHED, (data) => { data && this.registerSessionToConnect(data); });
    this._eventManager.subscribe(END_USER_END_SESSION, data => this.turnOffLiveConnection(data));
    this._eventManager.subscribe(SESSION_ENDED, (data) => { data && this.turnOffLiveConnection(data) });
  }

  registerSessionToConnect(sessionData) {
    if (sessionData) {
      this.keepConnected = true;
      this.sessionData = sessionData;
      this.checkLiveConnection();
    }
  }

  checkLiveConnection() {
    if (this.defaultEnabled && this.keepConnected) {
      this.openLiveConnection();
    }
  }

  setBaseUrlApi(serverEntryPoint) {
    let protocol = serverEntryPoint.protocol || window.location.protocol;
    protocol = protocol.endsWith(':') ? protocol.slice(0, -1) : protocol;
    let server = serverEntryPoint.server || window.location.hostname;
    server = server.endsWith('/') ? server.slice(0, -1) : server;
    let baseUrl = `${protocol}://${server}`;
    baseUrl += serverEntryPoint.port ? `:${serverEntryPoint.port}` : '';
    return baseUrl;
  }

  async openLiveConnection() {
    if (this.defaultEnabled && this.keepConnected) {
      console.log('Se cumplen las condiciones para conectar el websocket')
      if (!this.sessionData) { return false; }
      if (!this.socket) {
        this.socket = io(this.baseUrl, { path: '/liveconnect' });
        this.socket.emit(MESSAGE, this.sessionData);
        this.socket.on(CLIENT_EVENT, (eventData) => {
          console.log(`Se recibió el evento ${JSON.stringify(eventData)}`);
          this._eventManager.next(eventData?.eventClass, eventData);
        });
        this.socket.on(DISCONNECT_EVENT, (x) => {
          console.log('Se desconectó del server', x);
        });
      } else {
        this.socket.connect();
      }
    } else {
      this.socket && this.socket.emit('disconnect');
    }
  }

  turnOffLiveConnection(event) {
    if (event) {
      this.keepConnected = false;
      this.sessionData = null;
      this.socket && this.socket.emit('disconnect');
    }
  }
}
