<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <input
      nz-input
      [id]="field.code"
      [nzAutocomplete]="autoCustom"
      [(ngModel)]="value"
      (change)="inputChanged()"
      (keyup)="inputTyped()"
      [attr.maxlength]="maxLength()"
      [disabled]="disabled()"
      [placeholder]="placeholder()" />
    <nz-autocomplete #autoCustom>
      <nz-auto-option
        *ngFor="let option of options()"
        [nzLabel]="option.fieldOptionValue"
        [nzValue]="option.fieldOptionId">
        {{ option.fieldOptionValue }}</nz-auto-option
      >
    </nz-autocomplete>
  </nz-form-control>
</nz-form-item>
