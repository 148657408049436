import { Component } from '@angular/core';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';

const CONFIG_LOADED = 'configLoaded';

@Component({
  selector: 'app-layout-auth',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
})
export class LayoutOnboardComponent {
  assetsBase: String;
  logo: String;
  isGrayTopbar: Boolean;
  isCardShadow: Boolean;
  isSquaredBorders: Boolean;
  isBorderless: Boolean;
  authPagesColor: String;
  routerAnimation: String;
  selectedInitial = 0;

  constructor(
    // private _store: Store<any>,
    private eventManager: EventManagerService,
    private appConfig: AppConfigService,
  ) {
    this.eventManager.subscribe(CONFIG_LOADED, (data) => { data && (this.assetsBase = this.appConfig.getParameter('assetsBase')); });
  }
}
