import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-check',
  templateUrl: './app-check.component.html',
  styleUrls: ['./app-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCheckComponent extends FieldComponent {

  constructor(private _modalService: NzModalService) {
    super();
  }

  async updateContent(nzTitle, nzContent) {
    this._modalService.confirm({
      nzTitle,
      nzContent,
      nzClassName: 'checkModal',
      nzOkText: 'Confirmar',
      nzOnOk: () => { this.updateCheckValue(true) },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => { this.updateCheckValue(false) },
    });
  }

  updateCheckValue(value): void {
    this.value.set(value);
    this.inputChanged();
  }
}
