export const moduleRoutes = {
  root: 'learning',
  childForms: {
    agentLearnCampaigns: {
      data: { title: 'Aprende y Gana' },
      requireAuth: true,
      functions: [],
      route: 'campanias',
    },
    learnCampaignResponse: {
      data: { title: 'Campaña en curso' },
      requireAuth: true,
      functions: [],
      route: 'learnCampaignResponse',
    },
    externalLearningOpen: {
      data: { title: 'Plataforma de educación' },
      requireAuth: true,
      functions: [],
      route: 'externalLearningOpen',
    },
  },
  layout: 'main',
  loadChildren: () => import('../page-modules/learning/learning.module').then(m => m.LearningModule),
};
