import { Injectable } from '@angular/core';
import { LibFileManagementService } from 'tuain-ng-forms-lib';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileManagementService extends LibFileManagementService {

  constructor(private http: HttpClient) {
    super();
  }

  override openFile(fileBase64Data, fileName, fileType) {
    // data:application/pdf;
    const fileInfo = fileBase64Data.split(',');
    fileBase64Data = fileInfo[1];
    fileType = fileInfo[0].split(';')[0].split(':')[1];
    const fileData = this.base64toBlob(fileBase64Data, fileType);
    // Se construye un blob con los datos binarios del archivo
    const blob = new Blob([fileData], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    saveAs(blob, fileName);
  }

  override saveFileFromURL(fileUrl, fileName, fileType) {
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe(blob => saveAs(blob, fileName));
  }

  override saveFile(fileBase64Data, fileName, fileType, hasHeader = true) {
    let fileInfo = [];
    if (hasHeader) {
      fileInfo = fileBase64Data.split(',');
      fileBase64Data = fileInfo[1];
      fileType = fileInfo[0].split(';')[0].split(':')[1];
    }
    const fileData = this.base64toBlob(fileBase64Data, fileType);
    const blob = new Blob([fileData], { type: fileType });
    saveAs(blob, fileName);
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  override printPdfFile(pdfDataBuffer) {
    let blob = new Blob([pdfDataBuffer], { type: 'application/pdf' });
    const blobUrl = window.URL.createObjectURL((blob));
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
}
