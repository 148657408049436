import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { LibTableRecordActionComponent } from 'tuain-ng-forms-lib';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'row-action',
  templateUrl: './row-action.component.html',
  styleUrls: ['./row-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTableRecordActionComponent extends LibTableRecordActionComponent {
  icon: String;
  confirm: string = null;
  @Input() size = 'default';
  @Input() type = 'default';
  isActive: boolean;

  override start() {
    super.start();
    this.confirm = this.action.getCustomAttribute('preConfirm') ?? null;
    if (this.action?.actionType === 'CHECK' && this.action?.stateField) {
      this.isActive = this.recordData?.[this.action?.stateField] ?? false;
    }
    if (this.action.actionType === 'CHECK') {
      this.action.iconName = 'SELECTROW';
    } else if (this.action.actionType === 'UNCHECK') {
      this.action.iconName = 'UNSELECTROW';
    }
  }
}
