<div class="cardBox widgetCard">
  <h4 class="titleUnderShort">¿Qué deseas hacer?</h4>
  <div style="display: flex; flex-direction: column; gap: 1rem; padding: 1rem; font-size: medium;">
    <ng-container *ngFor="let serviceGroup of services; index as i">
      <li *ngIf="i !== activeIndex">
        <a (click)="activateServiceGroup(i)">
          <app-icon [iconName]="serviceGroup.iconName" collection="awesome"></app-icon>
          {{serviceGroup.title}}
        </a>
      </li>
    </ng-container>
  </div>
</div>