export const moduleRoutes = {
  root: 'auth',
  childForms: {
    unknownDevice: {
      data: { title: 'Auth / Login' },
      requireAuth: false,
      functions: [],
      route: 'unknownDevice',
    },
    registerUser: {
      data: { title: 'Auth / Register' },
      requireAuth: false,
      functions: [],
      route: 'registerUser',
    },
    forgotPassword: {
      data: { title: 'Auth / Password' },
      requireAuth: false,
      route: 'forgotPassword',
    },
    unlockUser: {
      data: { title: 'Auth / Password' },
      requireAuth: false,
      route: 'unlockUser',
    },
    userRecovery: {
      data: { title: 'Auth / Password' },
      requireAuth: false,
      route: 'userRecovery',
    },
    simpleRegister: {
      data: { title: 'Auth / Password' },
      requireAuth: false,
      functions: [],
      route: 'simpleRegister',
    },
    noSession: {
      data: { title: 'Auth / Parameters' },
      requireAuth: false,
      functions: [],
      route: 'nosession',
    },
    unauthorized: {
      data: { title: 'Auth / Parameters' },
      requireAuth: false,
      functions: [],
      route: 'unauthorized',
    },
    notfound: {
      data: { title: 'Auth / Parameters' },
      requireAuth: false,
      functions: [],
      route: 'notfound',
    },
    accountActivation: {
      data: { title: 'Activar cuenta' },
      requireAuth: false,
      functions: [],
      route: 'accountActivation',
    },
    resetPassword: {
      data: { title: 'Activar cuenta' },
      requireAuth: false,
      functions: [],
        route: 'resetPassword',
    },
  },
  layout: 'auth',
  loadChildren: () => import('src/app/page-modules/auth/auth.module').then(m => m.AuthModule),
};
