export const moduleRoutes = {
  root: 'welcome',
  childForms: {
    welcome: {
      data: { title: 'Auth / Welcome' },
      requireAuth: false,
      functions: [],
      route: 'welcome',
    },
  },
  layout: 'welcome',
  loadChildren: () => import('src/app/page-modules/welcome/welcome.module').then(m => m.WelcomeModule),
};
