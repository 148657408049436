<ng-container *ngIf="customAttributes().isIcon; then thenBlock; else elseBlock"></ng-container>
<ng-template #thenBlock>
  <mat-card>
    <mat-card-subtitle id="warningIcon">
      <div class=".ion-grid">
        <div class=".ion-row">
          <div class="ion-col" size="auto">
            <app-icon [class]="color" [iconName]="icon" collection="awesome" size="lg"></app-icon>
          </div>
        </div>
      </div>
    </mat-card-subtitle> </mat-card
></ng-template>
<ng-template #elseBlock
  ><mat-card [class]="color">
    <mat-card-title>
      <div class="ion-col" size="auto">
        <app-icon [iconName]="icon" collection="awesome" size="lg"></app-icon>
      </div>
      <div class="ion-col">
        <label class="alertTitle" (click)="toggleDetail()">{{ titleWarning }}</label>
      </div>
    </mat-card-title>
    <mat-card-subtitle *ngIf="message">
      {{ message + '.' }} {{ detailOpened && detail ? detail : '' }}
    </mat-card-subtitle>
  </mat-card></ng-template
>
