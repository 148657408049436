import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentPageService } from 'src/app/services/component-pages.service';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { FormOperationService } from 'src/app/services/form-manager.service';

const SESSION_ENDED = 'sessionEnded';

@Component({
  selector: 'app-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  userInfo: any = {};
  badgeCount = 7;
  menuData: any[];

  constructor(
    private _router: Router,
    private _eventManager: EventManagerService,
    private _formOperationService: FormOperationService,
    private _componentPageService: ComponentPageService,
  ) {
    this._eventManager.subscribe('profileObtained', data => this.enableProfile(data));
  }

  enableProfile(data) {
    this.userInfo = data;
    this._eventManager.subscribe('menuObtained', menuData => this.buildMenu(menuData.userMenu));
  }

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1;
  }

  activateMenu(name = null) {
    if (name === 'logout') {
      this._eventManager.next(SESSION_ENDED, { endDate: new Date() });
    }
    else if (name) this._formOperationService.openForm(null, { name });
  }

  buildMenu(data) {
    this.menuData = this.preprocessMenudata(data);
  }

  preprocessMenudata(menuData) {
    menuData.forEach((item) => {
      if (item.icon) {
        let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
        item.url = pageUrl;
        item.iconName = item.icon;
        item.key = item.form;
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }
}
