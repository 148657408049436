import { Component, ElementRef, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

const fldsWithInfo = ['fingerPrintCode'];
@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInputComponent extends FieldComponent {
  @Input() prefixIcon = '';
  @Input() suffixIconName = '';
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  includeInfo: boolean = false;
  infoContent: any = {};

  override start() {
    super.start();
    const { suffixIconName = '', showInfoContent = {} } = this.customAttributes();
    this.suffixIconName = suffixIconName;
    this.infoContent = showInfoContent;
    this.includeInfo = fldsWithInfo.includes(this.code);
  }

  override focus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  inputTypedCheck() {
    let formatOk = (this.value()?.length > 0 && this.format())
      ? this.format().test(this.value()) : true;
    if (this.value() && this.type() === 'ONLYTEXT') {
      formatOk = /^[A-Z]+$/i.test(this.value());
    }
    if (formatOk) {
      this.inputTyped();
    } else {
      this.value.set(this.field.value);
    }
  }
}
