export const moduleRoutes = {
  root: 'onboard',
  childForms: {
    startOnboard: {
      data: { title: 'Autoenrolamiento' },
      requireAuth: false,
      functions: [],
      route: 'startOnboard',
    },
    signAgentContract: {
      data: { title: 'Autoenrolamiento' },
      requireAuth: false,
      functions: [],
      route: 'signAgentContract',
    },
  },
  layout: 'onboard',
  loadChildren: () => import('src/app/page-modules/onboard/onboard.module').then(m => m.OnboardModule),
};
