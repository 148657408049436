import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-warning',
  templateUrl: './app-warning.component.html',
  styleUrls: ['./app-static.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppWarningComponent extends FieldComponent {
  icon: string;
  titleWarning: string;
  message: string;
  detail: string;
  color: string;
  detailOpened: boolean = true;

  override start(): void {
    super.start();
    let selectedIcon = 'circleSuccess';
    this.color = 'success';
    if (typeof this.value() === 'string') {
      this.title = '';
      this.message = this.value();
    } else {
      const { type = 'success', title = '', message = '', detail = '' } = this.value() ?? {};
      this.titleWarning = title;
      this.message = message;
      this.detail = detail;
      this.color = type;
      switch (type) {
        case 'success':
          selectedIcon = 'circleSuccess';
          break;
        case 'error':
        case 'danger':
          selectedIcon = 'CIRCLE_ERROR';
          this.color = 'danger';
          break;
        case 'question':
        case 'medium':
          selectedIcon = 'CIRCLE_QUESTION';
          this.color = 'medium';
          break;
        case 'info':
        case 'light':
          selectedIcon = 'CIRCLE_INFO';
          this.color = 'light';
          break;
        case 'warning':
          selectedIcon = 'CIRCLE_WARNING';
          break;
        default:
          selectedIcon = 'circleSuccess';
          this.color = 'success';
          break;
      }
    }
    this.icon = selectedIcon;
  }

  toggleDetail() {
    this.detailOpened = !this.detailOpened;
  }
}
