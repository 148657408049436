<ng-container *ngIf="visible()">
  <button
    *ngIf="confirm"
    nz-button
    nzType="text"
    [disabled]="disabled()"
    nz-popconfirm
    [nzPopconfirmTitle]="confirm"
    (nzOnConfirm)="onActivate()"
    nzPopconfirmPlacement="leftTop">
    <app-icon [iconName]="action?.iconName"></app-icon>
    <span> &nbsp; {{ action.actionTitle }}</span>
  </button>
  <button *ngIf="!confirm" nz-button nzType="text" [disabled]="disabled" (mousedown)="onActivate()">
    <app-icon [iconName]="action?.iconName"></app-icon>
    <span> &nbsp; {{ action.actionTitle }}</span>
  </button>
</ng-container>
