import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-typeahead',
  templateUrl: './app-typeahead.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTypeAheadComponent extends FieldComponent { }

