import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './app-pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPdfComponent extends FieldComponent {
  style: string;
  @Input() isBase64 = false;
  @Input() width = '100%';
  @Input() height = '500px';
  private pdf: PDFDocumentProxy;

  override start() {
    super.start();
    this.style = `margin: auto; width: ${this.width}; height: ${this.height}; border: grey solid 1px`;
    if (this.isBase64) {
      this.value.set(this.base64ToArrayBuffer(this.value()));
    }
  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.field.setCustomAttribute('pdfFile', pdf);
  }

  base64ToArrayBuffer(base64: any) {
    let binaryString = base64?.replace(/\\n/g, '');
    binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  onError(err) {
    console.log(err);
  }
}
