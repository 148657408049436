/* eslint-disable @typescript-eslint/naming-convention */
export const cities = {
  'Amazonas': {
    'name': 'Amazonas',
    'code': 'Amazonas',
    'cities': [
      {
        'name': 'LETICIA',
        'code': 'LETICIA',
      },
      {
        'name': 'EL ENCANTO',
        'code': 'EL ENCANTO',
      },
      {
        'name': 'LA CHORRERA',
        'code': 'LA CHORRERA',
      },
      {
        'name': 'LA PEDRERA',
        'code': 'LA PEDRERA',
      },
      {
        'name': 'LA VICTORIA',
        'code': 'LA VICTORIA',
      },
      {
        'name': 'MIRITI - PARANÁ',
        'code': 'MIRITI - PARANÁ',
      },
      {
        'name': 'PUERTO ALEGRÍA',
        'code': 'PUERTO ALEGRÍA',
      },
      {
        'name': 'PUERTO ARICA',
        'code': 'PUERTO ARICA',
      },
      {
        'name': 'PUERTO NARIÑO',
        'code': 'PUERTO NARIÑO',
      },
      {
        'name': 'PUERTO SANTANDER',
        'code': 'PUERTO SANTANDER',
      },
      {
        'name': 'TARAPACÁ',
        'code': 'TARAPACÁ',
      },
    ],
  },
  'Antioquia': {
    'name': 'Antioquia',
    'code': 'Antioquia',
    'cities': [
      {
        'name': 'MEDELLÍN',
        'code': 'MEDELLÍN',
      },
      {
        'name': 'ABEJORRAL',
        'code': 'ABEJORRAL',
      },
      {
        'name': 'ABRIAQUÍ',
        'code': 'ABRIAQUÍ',
      },
      {
        'name': 'ALEJANDRÍA',
        'code': 'ALEJANDRÍA',
      },
      {
        'name': 'AMAGÁ',
        'code': 'AMAGÁ',
      },
      {
        'name': 'AMALFI',
        'code': 'AMALFI',
      },
      {
        'name': 'ANDES',
        'code': 'ANDES',
      },
      {
        'name': 'ANGELÓPOLIS',
        'code': 'ANGELÓPOLIS',
      },
      {
        'name': 'ANGOSTURA',
        'code': 'ANGOSTURA',
      },
      {
        'name': 'ANORÍ',
        'code': 'ANORÍ',
      },
      {
        'name': 'SANTAFÉ DE ANTIOQUIA',
        'code': 'SANTAFÉ DE ANTIOQUIA',
      },
      {
        'name': 'ANZA',
        'code': 'ANZA',
      },
      {
        'name': 'APARTADÓ',
        'code': 'APARTADÓ',
      },
      {
        'name': 'ARBOLETES',
        'code': 'ARBOLETES',
      },
      {
        'name': 'ARGELIA',
        'code': 'ARGELIA',
      },
      {
        'name': 'ARMENIA',
        'code': 'ARMENIA',
      },
      {
        'name': 'BARBOSA',
        'code': 'BARBOSA',
      },
      {
        'name': 'BELMIRA',
        'code': 'BELMIRA',
      },
      {
        'name': 'BELLO',
        'code': 'BELLO',
      },
      {
        'name': 'BETANIA',
        'code': 'BETANIA',
      },
      {
        'name': 'BETULIA',
        'code': 'BETULIA',
      },
      {
        'name': 'CIUDAD BOLÍVAR',
        'code': 'CIUDAD BOLÍVAR',
      },
      {
        'name': 'BRICEÑO',
        'code': 'BRICEÑO',
      },
      {
        'name': 'BURITICÁ',
        'code': 'BURITICÁ',
      },
      {
        'name': 'CÁCERES',
        'code': 'CÁCERES',
      },
      {
        'name': 'CAICEDO',
        'code': 'CAICEDO',
      },
      {
        'name': 'CALDAS',
        'code': 'CALDAS',
      },
      {
        'name': 'CAMPAMENTO',
        'code': 'CAMPAMENTO',
      },
      {
        'name': 'CAÑASGORDAS',
        'code': 'CAÑASGORDAS',
      },
      {
        'name': 'CARACOLÍ',
        'code': 'CARACOLÍ',
      },
      {
        'name': 'CARAMANTA',
        'code': 'CARAMANTA',
      },
      {
        'name': 'CAREPA',
        'code': 'CAREPA',
      },
      {
        'name': 'EL CARMEN DE VIBORAL',
        'code': 'EL CARMEN DE VIBORAL',
      },
      {
        'name': 'CAROLINA',
        'code': 'CAROLINA',
      },
      {
        'name': 'CAUCASIA',
        'code': 'CAUCASIA',
      },
      {
        'name': 'CHIGORODÓ',
        'code': 'CHIGORODÓ',
      },
      {
        'name': 'CISNEROS',
        'code': 'CISNEROS',
      },
      {
        'name': 'COCORNÁ',
        'code': 'COCORNÁ',
      },
      {
        'name': 'CONCEPCIÓN',
        'code': 'CONCEPCIÓN',
      },
      {
        'name': 'CONCORDIA',
        'code': 'CONCORDIA',
      },
      {
        'name': 'COPACABANA',
        'code': 'COPACABANA',
      },
      {
        'name': 'DABEIBA',
        'code': 'DABEIBA',
      },
      {
        'name': 'DON MATÍAS',
        'code': 'DON MATÍAS',
      },
      {
        'name': 'EBÉJICO',
        'code': 'EBÉJICO',
      },
      {
        'name': 'EL BAGRE',
        'code': 'EL BAGRE',
      },
      {
        'name': 'ENTRERRIOS',
        'code': 'ENTRERRIOS',
      },
      {
        'name': 'ENVIGADO',
        'code': 'ENVIGADO',
      },
      {
        'name': 'FREDONIA',
        'code': 'FREDONIA',
      },
      {
        'name': 'FRONTINO',
        'code': 'FRONTINO',
      },
      {
        'name': 'GIRALDO',
        'code': 'GIRALDO',
      },
      {
        'name': 'GIRARDOTA',
        'code': 'GIRARDOTA',
      },
      {
        'name': 'GÓMEZ PLATA',
        'code': 'GÓMEZ PLATA',
      },
      {
        'name': 'GRANADA',
        'code': 'GRANADA',
      },
      {
        'name': 'GUADALUPE',
        'code': 'GUADALUPE',
      },
      {
        'name': 'GUARNE',
        'code': 'GUARNE',
      },
      {
        'name': 'GUATAPE',
        'code': 'GUATAPE',
      },
      {
        'name': 'HELICONIA',
        'code': 'HELICONIA',
      },
      {
        'name': 'HISPANIA',
        'code': 'HISPANIA',
      },
      {
        'name': 'ITAGUI',
        'code': 'ITAGUI',
      },
      {
        'name': 'ITUANGO',
        'code': 'ITUANGO',
      },
      {
        'name': 'JARDÍN',
        'code': 'JARDÍN',
      },
      {
        'name': 'JERICÓ',
        'code': 'JERICÓ',
      },
      {
        'name': 'LA CEJA',
        'code': 'LA CEJA',
      },
      {
        'name': 'LA ESTRELLA',
        'code': 'LA ESTRELLA',
      },
      {
        'name': 'LA PINTADA',
        'code': 'LA PINTADA',
      },
      {
        'name': 'LA UNIÓN',
        'code': 'LA UNIÓN',
      },
      {
        'name': 'LIBORINA',
        'code': 'LIBORINA',
      },
      {
        'name': 'MACEO',
        'code': 'MACEO',
      },
      {
        'name': 'MARINILLA',
        'code': 'MARINILLA',
      },
      {
        'name': 'MONTEBELLO',
        'code': 'MONTEBELLO',
      },
      {
        'name': 'MURINDÓ',
        'code': 'MURINDÓ',
      },
      {
        'name': 'MUTATÁ',
        'code': 'MUTATÁ',
      },
      {
        'name': 'NARIÑO',
        'code': 'NARIÑO',
      },
      {
        'name': 'NECOCLÍ',
        'code': 'NECOCLÍ',
      },
      {
        'name': 'NECHÍ',
        'code': 'NECHÍ',
      },
      {
        'name': 'OLAYA',
        'code': 'OLAYA',
      },
      {
        'name': 'PEÑOL',
        'code': 'PEÑOL',
      },
      {
        'name': 'PEQUE',
        'code': 'PEQUE',
      },
      {
        'name': 'PUEBLORRICO',
        'code': 'PUEBLORRICO',
      },
      {
        'name': 'PUERTO BERRÍO',
        'code': 'PUERTO BERRÍO',
      },
      {
        'name': 'PUERTO NARE',
        'code': 'PUERTO NARE',
      },
      {
        'name': 'PUERTO TRIUNFO',
        'code': 'PUERTO TRIUNFO',
      },
      {
        'name': 'REMEDIOS',
        'code': 'REMEDIOS',
      },
      {
        'name': 'RETIRO',
        'code': 'RETIRO',
      },
      {
        'name': 'RIONEGRO',
        'code': 'RIONEGRO',
      },
      {
        'name': 'SABANALARGA',
        'code': 'SABANALARGA',
      },
      {
        'name': 'SABANETA',
        'code': 'SABANETA',
      },
      {
        'name': 'SALGAR',
        'code': 'SALGAR',
      },
      {
        'name': 'SAN ANDRÉS DE CUERQUÍA',
        'code': 'SAN ANDRÉS DE CUERQUÍA',
      },
      {
        'name': 'SAN CARLOS',
        'code': 'SAN CARLOS',
      },
      {
        'name': 'SAN FRANCISCO',
        'code': 'SAN FRANCISCO',
      },
      {
        'name': 'SAN JERÓNIMO',
        'code': 'SAN JERÓNIMO',
      },
      {
        'name': 'SAN JOSÉ DE LA MONTAÑA',
        'code': 'SAN JOSÉ DE LA MONTAÑA',
      },
      {
        'name': 'SAN JUAN DE URABÁ',
        'code': 'SAN JUAN DE URABÁ',
      },
      {
        'name': 'SAN LUIS',
        'code': 'SAN LUIS',
      },
      {
        'name': 'SAN PEDRO',
        'code': 'SAN PEDRO',
      },
      {
        'name': 'SAN PEDRO DE URABA',
        'code': 'SAN PEDRO DE URABA',
      },
      {
        'name': 'SAN RAFAEL',
        'code': 'SAN RAFAEL',
      },
      {
        'name': 'SAN ROQUE',
        'code': 'SAN ROQUE',
      },
      {
        'name': 'SAN VICENTE',
        'code': 'SAN VICENTE',
      },
      {
        'name': 'SANTA BÁRBARA',
        'code': 'SANTA BÁRBARA',
      },
      {
        'name': 'SANTA ROSA DE OSOS',
        'code': 'SANTA ROSA DE OSOS',
      },
      {
        'name': 'SANTO DOMINGO',
        'code': 'SANTO DOMINGO',
      },
      {
        'name': 'EL SANTUARIO',
        'code': 'EL SANTUARIO',
      },
      {
        'name': 'SEGOVIA',
        'code': 'SEGOVIA',
      },
      {
        'name': 'SONSON',
        'code': 'SONSON',
      },
      {
        'name': 'SOPETRÁN',
        'code': 'SOPETRÁN',
      },
      {
        'name': 'TÁMESIS',
        'code': 'TÁMESIS',
      },
      {
        'name': 'TARAZÁ',
        'code': 'TARAZÁ',
      },
      {
        'name': 'TARSO',
        'code': 'TARSO',
      },
      {
        'name': 'TITIRIBÍ',
        'code': 'TITIRIBÍ',
      },
      {
        'name': 'TOLEDO',
        'code': 'TOLEDO',
      },
      {
        'name': 'TURBO',
        'code': 'TURBO',
      },
      {
        'name': 'URAMITA',
        'code': 'URAMITA',
      },
      {
        'name': 'URRAO',
        'code': 'URRAO',
      },
      {
        'name': 'VALDIVIA',
        'code': 'VALDIVIA',
      },
      {
        'name': 'VALPARAÍSO',
        'code': 'VALPARAÍSO',
      },
      {
        'name': 'VEGACHÍ',
        'code': 'VEGACHÍ',
      },
      {
        'name': 'VENECIA',
        'code': 'VENECIA',
      },
      {
        'name': 'VIGÍA DEL FUERTE',
        'code': 'VIGÍA DEL FUERTE',
      },
      {
        'name': 'YALÍ',
        'code': 'YALÍ',
      },
      {
        'name': 'YARUMAL',
        'code': 'YARUMAL',
      },
      {
        'name': 'YOLOMBÓ',
        'code': 'YOLOMBÓ',
      },
      {
        'name': 'YONDÓ',
        'code': 'YONDÓ',
      },
      {
        'name': 'ZARAGOZA',
        'code': 'ZARAGOZA',
      },
    ],
  },
  'Arauca': {
    'name': 'Arauca',
    'code': 'Arauca',
    'cities': [
      {
        'name': 'ARAUCA',
        'code': 'ARAUCA',
      },
      {
        'name': 'ARAUQUITA',
        'code': 'ARAUQUITA',
      },
      {
        'name': 'CRAVO NORTE',
        'code': 'CRAVO NORTE',
      },
      {
        'name': 'FORTUL',
        'code': 'FORTUL',
      },
      {
        'name': 'PUERTO RONDÓN',
        'code': 'PUERTO RONDÓN',
      },
      {
        'name': 'SARAVENA',
        'code': 'SARAVENA',
      },
      {
        'name': 'TAME',
        'code': 'TAME',
      },
    ],
  },
  'Atlántico': {
    'name': 'Atlántico',
    'code': 'Atlántico',
    'cities': [
      {
        'name': 'BARRANQUILLA',
        'code': 'BARRANQUILLA',
      },
      {
        'name': 'BARANOA',
        'code': 'BARANOA',
      },
      {
        'name': 'CAMPO DE LA CRUZ',
        'code': 'CAMPO DE LA CRUZ',
      },
      {
        'name': 'CANDELARIA',
        'code': 'CANDELARIA',
      },
      {
        'name': 'GALAPA',
        'code': 'GALAPA',
      },
      {
        'name': 'JUAN DE ACOSTA',
        'code': 'JUAN DE ACOSTA',
      },
      {
        'name': 'LURUACO',
        'code': 'LURUACO',
      },
      {
        'name': 'MALAMBO',
        'code': 'MALAMBO',
      },
      {
        'name': 'MANATÍ',
        'code': 'MANATÍ',
      },
      {
        'name': 'PALMAR DE VARELA',
        'code': 'PALMAR DE VARELA',
      },
      {
        'name': 'PIOJÓ',
        'code': 'PIOJÓ',
      },
      {
        'name': 'POLONUEVO',
        'code': 'POLONUEVO',
      },
      {
        'name': 'PONEDERA',
        'code': 'PONEDERA',
      },
      {
        'name': 'PUERTO COLOMBIA',
        'code': 'PUERTO COLOMBIA',
      },
      {
        'name': 'REPELÓN',
        'code': 'REPELÓN',
      },
      {
        'name': 'SABANAGRANDE',
        'code': 'SABANAGRANDE',
      },
      {
        'name': 'SABANALARGA',
        'code': 'SABANALARGA',
      },
      {
        'name': 'SANTA LUCÍA',
        'code': 'SANTA LUCÍA',
      },
      {
        'name': 'SANTO TOMÁS',
        'code': 'SANTO TOMÁS',
      },
      {
        'name': 'SOLEDAD',
        'code': 'SOLEDAD',
      },
      {
        'name': 'SUAN',
        'code': 'SUAN',
      },
      {
        'name': 'TUBARÁ',
        'code': 'TUBARÁ',
      },
      {
        'name': 'USIACURÍ',
        'code': 'USIACURÍ',
      },
    ],
  },
  'Bogotá D.C.': {
    'name': 'Bogotá D.C.',
    'code': 'Bogotá D.C.',
    'cities': [
      {
        'name': 'BOGOTÁ, D.C.',
        'code': 'BOGOTÁ, D.C.',
      },
    ],
  },
  'Bolívar': {
    'name': 'Bolívar',
    'code': 'Bolívar',
    'cities': [
      {
        'name': 'CARTAGENA',
        'code': 'CARTAGENA',
      },
      {
        'name': 'ACHÍ',
        'code': 'ACHÍ',
      },
      {
        'name': 'ALTOS DEL ROSARIO',
        'code': 'ALTOS DEL ROSARIO',
      },
      {
        'name': 'ARENAL',
        'code': 'ARENAL',
      },
      {
        'name': 'ARJONA',
        'code': 'ARJONA',
      },
      {
        'name': 'ARROYOHONDO',
        'code': 'ARROYOHONDO',
      },
      {
        'name': 'BARRANCO DE LOBA',
        'code': 'BARRANCO DE LOBA',
      },
      {
        'name': 'CALAMAR',
        'code': 'CALAMAR',
      },
      {
        'name': 'CANTAGALLO',
        'code': 'CANTAGALLO',
      },
      {
        'name': 'CICUCO',
        'code': 'CICUCO',
      },
      {
        'name': 'CÓRDOBA',
        'code': 'CÓRDOBA',
      },
      {
        'name': 'CLEMENCIA',
        'code': 'CLEMENCIA',
      },
      {
        'name': 'EL CARMEN DE BOLÍVAR',
        'code': 'EL CARMEN DE BOLÍVAR',
      },
      {
        'name': 'EL GUAMO',
        'code': 'EL GUAMO',
      },
      {
        'name': 'EL PEÑÓN',
        'code': 'EL PEÑÓN',
      },
      {
        'name': 'HATILLO DE LOBA',
        'code': 'HATILLO DE LOBA',
      },
      {
        'name': 'MAGANGUÉ',
        'code': 'MAGANGUÉ',
      },
      {
        'name': 'MAHATES',
        'code': 'MAHATES',
      },
      {
        'name': 'MARGARITA',
        'code': 'MARGARITA',
      },
      {
        'name': 'MARÍA LA BAJA',
        'code': 'MARÍA LA BAJA',
      },
      {
        'name': 'MONTECRISTO',
        'code': 'MONTECRISTO',
      },
      {
        'name': 'MOMPÓS',
        'code': 'MOMPÓS',
      },
      {
        'name': 'MORALES',
        'code': 'MORALES',
      },
      {
        'name': 'PINILLOS',
        'code': 'PINILLOS',
      },
      {
        'name': 'REGIDOR',
        'code': 'REGIDOR',
      },
      {
        'name': 'RÍO VIEJO',
        'code': 'RÍO VIEJO',
      },
      {
        'name': 'SAN CRISTÓBAL',
        'code': 'SAN CRISTÓBAL',
      },
      {
        'name': 'SAN ESTANISLAO',
        'code': 'SAN ESTANISLAO',
      },
      {
        'name': 'SAN FERNANDO',
        'code': 'SAN FERNANDO',
      },
      {
        'name': 'SAN JACINTO',
        'code': 'SAN JACINTO',
      },
      {
        'name': 'SAN JACINTO DEL CAUCA',
        'code': 'SAN JACINTO DEL CAUCA',
      },
      {
        'name': 'SAN JUAN NEPOMUCENO',
        'code': 'SAN JUAN NEPOMUCENO',
      },
      {
        'name': 'SAN MARTÍN DE LOBA',
        'code': 'SAN MARTÍN DE LOBA',
      },
      {
        'name': 'SAN PABLO',
        'code': 'SAN PABLO',
      },
      {
        'name': 'SANTA CATALINA',
        'code': 'SANTA CATALINA',
      },
      {
        'name': 'SANTA ROSA',
        'code': 'SANTA ROSA',
      },
      {
        'name': 'SANTA ROSA DEL SUR',
        'code': 'SANTA ROSA DEL SUR',
      },
      {
        'name': 'SIMITÍ',
        'code': 'SIMITÍ',
      },
      {
        'name': 'SOPLAVIENTO',
        'code': 'SOPLAVIENTO',
      },
      {
        'name': 'TALAIGUA NUEVO',
        'code': 'TALAIGUA NUEVO',
      },
      {
        'name': 'TIQUISIO',
        'code': 'TIQUISIO',
      },
      {
        'name': 'TURBACO',
        'code': 'TURBACO',
      },
      {
        'name': 'TURBANÁ',
        'code': 'TURBANÁ',
      },
      {
        'name': 'VILLANUEVA',
        'code': 'VILLANUEVA',
      },
      {
        'name': 'ZAMBRANO',
        'code': 'ZAMBRANO',
      },
    ],
  },
  'Boyacá': {
    'name': 'Boyacá',
    'code': 'Boyacá',
    'cities': [
      {
        'name': 'TUNJA',
        'code': 'TUNJA',
      },
      {
        'name': 'ALMEIDA',
        'code': 'ALMEIDA',
      },
      {
        'name': 'AQUITANIA',
        'code': 'AQUITANIA',
      },
      {
        'name': 'ARCABUCO',
        'code': 'ARCABUCO',
      },
      {
        'name': 'BELÉN',
        'code': 'BELÉN',
      },
      {
        'name': 'BERBEO',
        'code': 'BERBEO',
      },
      {
        'name': 'BETÉITIVA',
        'code': 'BETÉITIVA',
      },
      {
        'name': 'BOAVITA',
        'code': 'BOAVITA',
      },
      {
        'name': 'BOYACÁ',
        'code': 'BOYACÁ',
      },
      {
        'name': 'BRICEÑO',
        'code': 'BRICEÑO',
      },
      {
        'name': 'BUENAVISTA',
        'code': 'BUENAVISTA',
      },
      {
        'name': 'BUSBANZÁ',
        'code': 'BUSBANZÁ',
      },
      {
        'name': 'CALDAS',
        'code': 'CALDAS',
      },
      {
        'name': 'CAMPOHERMOSO',
        'code': 'CAMPOHERMOSO',
      },
      {
        'name': 'CERINZA',
        'code': 'CERINZA',
      },
      {
        'name': 'CHINAVITA',
        'code': 'CHINAVITA',
      },
      {
        'name': 'CHIQUINQUIRÁ',
        'code': 'CHIQUINQUIRÁ',
      },
      {
        'name': 'CHISCAS',
        'code': 'CHISCAS',
      },
      {
        'name': 'CHITA',
        'code': 'CHITA',
      },
      {
        'name': 'CHITARAQUE',
        'code': 'CHITARAQUE',
      },
      {
        'name': 'CHIVATÁ',
        'code': 'CHIVATÁ',
      },
      {
        'name': 'CIÉNEGA',
        'code': 'CIÉNEGA',
      },
      {
        'name': 'CÓMBITA',
        'code': 'CÓMBITA',
      },
      {
        'name': 'COPER',
        'code': 'COPER',
      },
      {
        'name': 'CORRALES',
        'code': 'CORRALES',
      },
      {
        'name': 'COVARACHÍA',
        'code': 'COVARACHÍA',
      },
      {
        'name': 'CUBARÁ',
        'code': 'CUBARÁ',
      },
      {
        'name': 'CUCAITA',
        'code': 'CUCAITA',
      },
      {
        'name': 'CUÍTIVA',
        'code': 'CUÍTIVA',
      },
      {
        'name': 'CHÍQUIZA',
        'code': 'CHÍQUIZA',
      },
      {
        'name': 'CHIVOR',
        'code': 'CHIVOR',
      },
      {
        'name': 'DUITAMA',
        'code': 'DUITAMA',
      },
      {
        'name': 'EL COCUY',
        'code': 'EL COCUY',
      },
      {
        'name': 'EL6aSPINO',
        'code': 'EL6aSPINO',
      },
      {
        'name': 'FIRAVITOBA',
        'code': 'FIRAVITOBA',
      },
      {
        'name': 'FLORESTA',
        'code': 'FLORESTA',
      },
      {
        'name': 'GACHANTIVÁ',
        'code': 'GACHANTIVÁ',
      },
      {
        'name': 'GAMEZA',
        'code': 'GAMEZA',
      },
      {
        'name': 'GARAGOA',
        'code': 'GARAGOA',
      },
      {
        'name': 'GUACAMAYAS',
        'code': 'GUACAMAYAS',
      },
      {
        'name': 'GUATEQUE',
        'code': 'GUATEQUE',
      },
      {
        'name': 'GUAYATÁ',
        'code': 'GUAYATÁ',
      },
      {
        'name': 'GÜICÁN',
        'code': 'GÜICÁN',
      },
      {
        'name': 'IZA',
        'code': 'IZA',
      },
      {
        'name': 'JENESANO',
        'code': 'JENESANO',
      },
      {
        'name': 'JERICÓ',
        'code': 'JERICÓ',
      },
      {
        'name': 'LABRANZAGRANDE',
        'code': 'LABRANZAGRANDE',
      },
      {
        'name': 'LA CAPILLA',
        'code': 'LA CAPILLA',
      },
      {
        'name': 'LA VICTORIA',
        'code': 'LA VICTORIA',
      },
      {
        'name': 'LA UVITA',
        'code': 'LA UVITA',
      },
      {
        'name': 'VILLA DE LEYVA',
        'code': 'VILLA DE LEYVA',
      },
      {
        'name': 'MACANAL',
        'code': 'MACANAL',
      },
      {
        'name': 'MARIPÍ',
        'code': 'MARIPÍ',
      },
      {
        'name': 'MIRAFLORES',
        'code': 'MIRAFLORES',
      },
      {
        'name': 'MONGUA',
        'code': 'MONGUA',
      },
      {
        'name': 'MONGUÍ',
        'code': 'MONGUÍ',
      },
      {
        'name': 'MONIQUIRÁ',
        'code': 'MONIQUIRÁ',
      },
      {
        'name': 'MOTAVITA',
        'code': 'MOTAVITA',
      },
      {
        'name': 'MUZO',
        'code': 'MUZO',
      },
      {
        'name': 'NOBSA',
        'code': 'NOBSA',
      },
      {
        'name': 'NUEVO COLÓN',
        'code': 'NUEVO COLÓN',
      },
      {
        'name': 'OICATÁ',
        'code': 'OICATÁ',
      },
      {
        'name': 'OTANCHE',
        'code': 'OTANCHE',
      },
      {
        'name': 'PACHAVITA',
        'code': 'PACHAVITA',
      },
      {
        'name': 'PÁEZ',
        'code': 'PÁEZ',
      },
      {
        'name': 'PAIPA',
        'code': 'PAIPA',
      },
      {
        'name': 'PAJARITO',
        'code': 'PAJARITO',
      },
      {
        'name': 'PANQUEBA',
        'code': 'PANQUEBA',
      },
      {
        'name': 'PAUNA',
        'code': 'PAUNA',
      },
      {
        'name': 'PAYA',
        'code': 'PAYA',
      },
      {
        'name': 'PAZ DE RÍO',
        'code': 'PAZ DE RÍO',
      },
      {
        'name': 'PESCA',
        'code': 'PESCA',
      },
      {
        'name': 'PISBA',
        'code': 'PISBA',
      },
      {
        'name': 'PUERTO BOYACÁ',
        'code': 'PUERTO BOYACÁ',
      },
      {
        'name': 'QUÍPAMA',
        'code': 'QUÍPAMA',
      },
      {
        'name': 'RAMIRIQUÍ',
        'code': 'RAMIRIQUÍ',
      },
      {
        'name': 'RÁQUIRA',
        'code': 'RÁQUIRA',
      },
      {
        'name': 'RONDÓN',
        'code': 'RONDÓN',
      },
      {
        'name': 'SABOYÁ',
        'code': 'SABOYÁ',
      },
      {
        'name': 'SÁCHICA',
        'code': 'SÁCHICA',
      },
      {
        'name': 'SAMACÁ',
        'code': 'SAMACÁ',
      },
      {
        'name': 'SAN EDUARDO',
        'code': 'SAN EDUARDO',
      },
      {
        'name': 'SAN JOSÉ DE PARE',
        'code': 'SAN JOSÉ DE PARE',
      },
      {
        'name': 'SAN LUIS DE GACENO',
        'code': 'SAN LUIS DE GACENO',
      },
      {
        'name': 'SAN MATEO',
        'code': 'SAN MATEO',
      },
      {
        'name': 'SAN MIGUEL DE SEMA',
        'code': 'SAN MIGUEL DE SEMA',
      },
      {
        'name': 'SAN PABLO DE BORBUR',
        'code': 'SAN PABLO DE BORBUR',
      },
      {
        'name': 'SANTANA',
        'code': 'SANTANA',
      },
      {
        'name': 'SANTA MARÍA',
        'code': 'SANTA MARÍA',
      },
      {
        'name': 'SANTA ROSA DE VITERBO',
        'code': 'SANTA ROSA DE VITERBO',
      },
      {
        'name': 'SANTA SOFÍA',
        'code': 'SANTA SOFÍA',
      },
      {
        'name': 'SATIVANORTE',
        'code': 'SATIVANORTE',
      },
      {
        'name': 'SATIVASUR',
        'code': 'SATIVASUR',
      },
      {
        'name': 'SIACHOQUE',
        'code': 'SIACHOQUE',
      },
      {
        'name': 'SOATÁ',
        'code': 'SOATÁ',
      },
      {
        'name': 'SOCOTÁ',
        'code': 'SOCOTÁ',
      },
      {
        'name': 'SOCHA',
        'code': 'SOCHA',
      },
      {
        'name': 'SOGAMOSO',
        'code': 'SOGAMOSO',
      },
      {
        'name': 'SOMONDOCO',
        'code': 'SOMONDOCO',
      },
      {
        'name': 'SORA',
        'code': 'SORA',
      },
      {
        'name': 'SOTAQUIRÁ',
        'code': 'SOTAQUIRÁ',
      },
      {
        'name': 'SORACÁ',
        'code': 'SORACÁ',
      },
      {
        'name': 'SUSACÓN',
        'code': 'SUSACÓN',
      },
      {
        'name': 'SUTAMARCHÁN',
        'code': 'SUTAMARCHÁN',
      },
      {
        'name': 'SUTATENZA',
        'code': 'SUTATENZA',
      },
      {
        'name': 'TASCO',
        'code': 'TASCO',
      },
      {
        'name': 'TENZA',
        'code': 'TENZA',
      },
      {
        'name': 'TIBANÁ',
        'code': 'TIBANÁ',
      },
      {
        'name': 'TIBASOSA',
        'code': 'TIBASOSA',
      },
      {
        'name': 'TINJACÁ',
        'code': 'TINJACÁ',
      },
      {
        'name': 'TIPACOQUE',
        'code': 'TIPACOQUE',
      },
      {
        'name': 'TOCA',
        'code': 'TOCA',
      },
      {
        'name': 'TOGÜÍ',
        'code': 'TOGÜÍ',
      },
      {
        'name': 'TÓPAGA',
        'code': 'TÓPAGA',
      },
      {
        'name': 'TOTA',
        'code': 'TOTA',
      },
      {
        'name': 'TUNUNGUÁ',
        'code': 'TUNUNGUÁ',
      },
      {
        'name': 'TURMEQUÉ',
        'code': 'TURMEQUÉ',
      },
      {
        'name': 'TUTA',
        'code': 'TUTA',
      },
      {
        'name': 'TUTAZÁ',
        'code': 'TUTAZÁ',
      },
      {
        'name': 'UMBITA',
        'code': 'UMBITA',
      },
      {
        'name': 'VENTAQUEMADA',
        'code': 'VENTAQUEMADA',
      },
      {
        'name': 'VIRACACHÁ',
        'code': 'VIRACACHÁ',
      },
      {
        'name': 'ZETAQUIRA',
        'code': 'ZETAQUIRA',
      },
    ],
  },
  'Caldas': {
    'name': 'Caldas',
    'code': 'Caldas',
    'cities': [
      {
        'name': 'MANIZALES',
        'code': 'MANIZALES',
      },
      {
        'name': 'AGUADAS',
        'code': 'AGUADAS',
      },
      {
        'name': 'ANSERMA',
        'code': 'ANSERMA',
      },
      {
        'name': 'ARANZAZU',
        'code': 'ARANZAZU',
      },
      {
        'name': 'BELALCÁZAR',
        'code': 'BELALCÁZAR',
      },
      {
        'name': 'CHINCHINÁ',
        'code': 'CHINCHINÁ',
      },
      {
        'name': 'FILADELFIA',
        'code': 'FILADELFIA',
      },
      {
        'name': 'LA DORADA',
        'code': 'LA DORADA',
      },
      {
        'name': 'LA MERCED',
        'code': 'LA MERCED',
      },
      {
        'name': 'MANZANARES',
        'code': 'MANZANARES',
      },
      {
        'name': 'MARMATO',
        'code': 'MARMATO',
      },
      {
        'name': 'MARQUETALIA',
        'code': 'MARQUETALIA',
      },
      {
        'name': 'MARULANDA',
        'code': 'MARULANDA',
      },
      {
        'name': 'NEIRA',
        'code': 'NEIRA',
      },
      {
        'name': 'NORCASIA',
        'code': 'NORCASIA',
      },
      {
        'name': 'PÁCORA',
        'code': 'PÁCORA',
      },
      {
        'name': 'PALESTINA',
        'code': 'PALESTINA',
      },
      {
        'name': 'PENSILVANIA',
        'code': 'PENSILVANIA',
      },
      {
        'name': 'RIOSUCIO',
        'code': 'RIOSUCIO',
      },
      {
        'name': 'RISARALDA',
        'code': 'RISARALDA',
      },
      {
        'name': 'SALAMINA',
        'code': 'SALAMINA',
      },
      {
        'name': 'SAMANÁ',
        'code': 'SAMANÁ',
      },
      {
        'name': 'SAN JOSÉ',
        'code': 'SAN JOSÉ',
      },
      {
        'name': 'SUPÍA',
        'code': 'SUPÍA',
      },
      {
        'name': 'VICTORIA',
        'code': 'VICTORIA',
      },
      {
        'name': 'VILLAMARÍA',
        'code': 'VILLAMARÍA',
      },
      {
        'name': 'VITERBO',
        'code': 'VITERBO',
      },
    ],
  },
  'Caquetá': {
    'name': 'Caquetá',
    'code': 'Caquetá',
    'cities': [
      {
        'name': 'FLORENCIA',
        'code': 'FLORENCIA',
      },
      {
        'name': 'ALBANIA',
        'code': 'ALBANIA',
      },
      {
        'name': 'BELÉN DE LOS ANDAQUIES',
        'code': 'BELÉN DE LOS ANDAQUIES',
      },
      {
        'name': 'CARTAGENA DEL CHAIRÁ',
        'code': 'CARTAGENA DEL CHAIRÁ',
      },
      {
        'name': 'CURILLO',
        'code': 'CURILLO',
      },
      {
        'name': 'EL DONCELLO',
        'code': 'EL DONCELLO',
      },
      {
        'name': 'EL PAUJIL',
        'code': 'EL PAUJIL',
      },
      {
        'name': 'LA MONTAÑITA',
        'code': 'LA MONTAÑITA',
      },
      {
        'name': 'MILÁN',
        'code': 'MILÁN',
      },
      {
        'name': 'MORELIA',
        'code': 'MORELIA',
      },
      {
        'name': 'PUERTO RICO',
        'code': 'PUERTO RICO',
      },
      {
        'name': 'SAN JOSÉ DEL FRAGUA',
        'code': 'SAN JOSÉ DEL FRAGUA',
      },
      {
        'name': 'SAN VICENTE DEL CAGUÁN',
        'code': 'SAN VICENTE DEL CAGUÁN',
      },
      {
        'name': 'SOLANO',
        'code': 'SOLANO',
      },
      {
        'name': 'SOLITA',
        'code': 'SOLITA',
      },
      {
        'name': 'VALPARAÍSO',
        'code': 'VALPARAÍSO',
      },
    ],
  },
  'Casanare': {
    'name': 'Casanare',
    'code': 'Casanare',
    'cities': [
      {
        'name': 'YOPAL',
        'code': 'YOPAL',
      },
      {
        'name': 'AGUAZUL',
        'code': 'AGUAZUL',
      },
      {
        'name': 'CHAMEZA',
        'code': 'CHAMEZA',
      },
      {
        'name': 'HATO COROZAL',
        'code': 'HATO COROZAL',
      },
      {
        'name': 'LA SALINA',
        'code': 'LA SALINA',
      },
      {
        'name': 'MANÍ',
        'code': 'MANÍ',
      },
      {
        'name': 'MONTERREY',
        'code': 'MONTERREY',
      },
      {
        'name': 'NUNCHÍA',
        'code': 'NUNCHÍA',
      },
      {
        'name': 'OROCUÉ',
        'code': 'OROCUÉ',
      },
      {
        'name': 'PAZ DE ARIPORO',
        'code': 'PAZ DE ARIPORO',
      },
      {
        'name': 'PORE',
        'code': 'PORE',
      },
      {
        'name': 'RECETOR',
        'code': 'RECETOR',
      },
      {
        'name': 'SABANALARGA',
        'code': 'SABANALARGA',
      },
      {
        'name': 'SÁCAMA',
        'code': 'SÁCAMA',
      },
      {
        'name': 'SAN LUIS DE PALENQUE',
        'code': 'SAN LUIS DE PALENQUE',
      },
      {
        'name': 'TÁMARA',
        'code': 'TÁMARA',
      },
      {
        'name': 'TAURAMENA',
        'code': 'TAURAMENA',
      },
      {
        'name': 'TRINIDAD',
        'code': 'TRINIDAD',
      },
      {
        'name': 'VILLANUEVA',
        'code': 'VILLANUEVA',
      },
    ],
  },
  'Cauca': {
    'name': 'Cauca',
    'code': 'Cauca',
    'cities': [
      {
        'name': 'POPAYÁN',
        'code': 'POPAYÁN',
      },
      {
        'name': 'ALMAGUER',
        'code': 'ALMAGUER',
      },
      {
        'name': 'ARGELIA',
        'code': 'ARGELIA',
      },
      {
        'name': 'BALBOA',
        'code': 'BALBOA',
      },
      {
        'name': 'BOLÍVAR',
        'code': 'BOLÍVAR',
      },
      {
        'name': 'BUENOS AIRES',
        'code': 'BUENOS AIRES',
      },
      {
        'name': 'CAJIBÍO',
        'code': 'CAJIBÍO',
      },
      {
        'name': 'CALDONO',
        'code': 'CALDONO',
      },
      {
        'name': 'CALOTO',
        'code': 'CALOTO',
      },
      {
        'name': 'CORINTO',
        'code': 'CORINTO',
      },
      {
        'name': 'EL TAMBO',
        'code': 'EL TAMBO',
      },
      {
        'name': 'FLORENCIA',
        'code': 'FLORENCIA',
      },
      {
        'name': 'GUACHENÉ',
        'code': 'GUACHENÉ',
      },
      {
        'name': 'GUAPI',
        'code': 'GUAPI',
      },
      {
        'name': 'INZÁ',
        'code': 'INZÁ',
      },
      {
        'name': 'JAMBALÓ',
        'code': 'JAMBALÓ',
      },
      {
        'name': 'LA SIERRA',
        'code': 'LA SIERRA',
      },
      {
        'name': 'LA VEGA',
        'code': 'LA VEGA',
      },
      {
        'name': 'LÓPEZ',
        'code': 'LÓPEZ',
      },
      {
        'name': 'MERCADERES',
        'code': 'MERCADERES',
      },
      {
        'name': 'MIRANDA',
        'code': 'MIRANDA',
      },
      {
        'name': 'MORALES',
        'code': 'MORALES',
      },
      {
        'name': 'PADILLA',
        'code': 'PADILLA',
      },
      {
        'name': 'PAEZ',
        'code': 'PAEZ',
      },
      {
        'name': 'PATÍA',
        'code': 'PATÍA',
      },
      {
        'name': 'PIAMONTE',
        'code': 'PIAMONTE',
      },
      {
        'name': 'PIENDAMÓ',
        'code': 'PIENDAMÓ',
      },
      {
        'name': 'PUERTO TEJADA',
        'code': 'PUERTO TEJADA',
      },
      {
        'name': 'PURACÉ',
        'code': 'PURACÉ',
      },
      {
        'name': 'ROSAS',
        'code': 'ROSAS',
      },
      {
        'name': 'SAN SEBASTIÁN',
        'code': 'SAN SEBASTIÁN',
      },
      {
        'name': 'SANTANDER DE QUILICHAO',
        'code': 'SANTANDER DE QUILICHAO',
      },
      {
        'name': 'SANTA ROSA',
        'code': 'SANTA ROSA',
      },
      {
        'name': 'SILVIA',
        'code': 'SILVIA',
      },
      {
        'name': 'SOTARA',
        'code': 'SOTARA',
      },
      {
        'name': 'SUÁREZ',
        'code': 'SUÁREZ',
      },
      {
        'name': 'SUCRE',
        'code': 'SUCRE',
      },
      {
        'name': 'TIMBÍO',
        'code': 'TIMBÍO',
      },
      {
        'name': 'TIMBIQUÍ',
        'code': 'TIMBIQUÍ',
      },
      {
        'name': 'TORIBIO',
        'code': 'TORIBIO',
      },
      {
        'name': 'TOTORÓ',
        'code': 'TOTORÓ',
      },
      {
        'name': 'VILLA RICA',
        'code': 'VILLA RICA',
      },
    ],
  },
  'Cesar': {
    'name': 'Cesar',
    'code': 'Cesar',
    'cities': [
      {
        'name': 'VALLEDUPAR',
        'code': 'VALLEDUPAR',
      },
      {
        'name': 'AGUACHICA',
        'code': 'AGUACHICA',
      },
      {
        'name': 'AGUSTÍN CODAZZI',
        'code': 'AGUSTÍN CODAZZI',
      },
      {
        'name': 'ASTREA',
        'code': 'ASTREA',
      },
      {
        'name': 'BECERRIL',
        'code': 'BECERRIL',
      },
      {
        'name': 'BOSCONIA',
        'code': 'BOSCONIA',
      },
      {
        'name': 'CHIMICHAGUA',
        'code': 'CHIMICHAGUA',
      },
      {
        'name': 'CHIRIGUANÁ',
        'code': 'CHIRIGUANÁ',
      },
      {
        'name': 'CURUMANÍ',
        'code': 'CURUMANÍ',
      },
      {
        'name': 'EL COPEY',
        'code': 'EL COPEY',
      },
      {
        'name': 'EL PASO',
        'code': 'EL PASO',
      },
      {
        'name': 'GAMARRA',
        'code': 'GAMARRA',
      },
      {
        'name': 'GONZÁLEZ',
        'code': 'GONZÁLEZ',
      },
      {
        'name': 'LA GLORIA',
        'code': 'LA GLORIA',
      },
      {
        'name': 'LA JAGUA DE IBIRICO',
        'code': 'LA JAGUA DE IBIRICO',
      },
      {
        'name': 'MANAURE',
        'code': 'MANAURE',
      },
      {
        'name': 'PAILITAS',
        'code': 'PAILITAS',
      },
      {
        'name': 'PELAYA',
        'code': 'PELAYA',
      },
      {
        'name': 'PUEBLO BELLO',
        'code': 'PUEBLO BELLO',
      },
      {
        'name': 'RÍO DE ORO',
        'code': 'RÍO DE ORO',
      },
      {
        'name': 'LA PAZ',
        'code': 'LA PAZ',
      },
      {
        'name': 'SAN ALBERTO',
        'code': 'SAN ALBERTO',
      },
      {
        'name': 'SAN DIEGO',
        'code': 'SAN DIEGO',
      },
      {
        'name': 'SAN MARTÍN',
        'code': 'SAN MARTÍN',
      },
      {
        'name': 'TAMALAMEQUE',
        'code': 'TAMALAMEQUE',
      },
    ],
  },
  'Chocó': {
    'name': 'Chocó',
    'code': 'Chocó',
    'cities': [
      {
        'name': 'QUIBDÓ',
        'code': 'QUIBDÓ',
      },
      {
        'name': 'ACANDÍ',
        'code': 'ACANDÍ',
      },
      {
        'name': 'ALTO BAUDO',
        'code': 'ALTO BAUDO',
      },
      {
        'name': 'ATRATO',
        'code': 'ATRATO',
      },
      {
        'name': 'BAGADÓ',
        'code': 'BAGADÓ',
      },
      {
        'name': 'BAHÍA SOLANO',
        'code': 'BAHÍA SOLANO',
      },
      {
        'name': 'BAJO BAUDÓ',
        'code': 'BAJO BAUDÓ',
      },
      {
        'name': 'BELÉN DE BAJIRÁ',
        'code': 'BELÉN DE BAJIRÁ',
      },
      {
        'name': 'BOJAYA',
        'code': 'BOJAYA',
      },
      {
        'name': 'EL CANTÓN DEL SAN PABLO',
        'code': 'EL CANTÓN DEL SAN PABLO',
      },
      {
        'name': 'CARMEN DEL DARIEN',
        'code': 'CARMEN DEL DARIEN',
      },
      {
        'name': 'CÉRTEGUI',
        'code': 'CÉRTEGUI',
      },
      {
        'name': 'CONDOTO',
        'code': 'CONDOTO',
      },
      {
        'name': 'EL CARMEN DE ATRATO',
        'code': 'EL CARMEN DE ATRATO',
      },
      {
        'name': 'EL LITORAL DEL SAN JUAN',
        'code': 'EL LITORAL DEL SAN JUAN',
      },
      {
        'name': 'ISTMINA',
        'code': 'ISTMINA',
      },
      {
        'name': 'JURADÓ',
        'code': 'JURADÓ',
      },
      {
        'name': 'LLORÓ',
        'code': 'LLORÓ',
      },
      {
        'name': 'MEDIO ATRATO',
        'code': 'MEDIO ATRATO',
      },
      {
        'name': 'MEDIO BAUDÓ',
        'code': 'MEDIO BAUDÓ',
      },
      {
        'name': 'MEDIO SAN JUAN',
        'code': 'MEDIO SAN JUAN',
      },
      {
        'name': 'NÓVITA',
        'code': 'NÓVITA',
      },
      {
        'name': 'NUQUÍ',
        'code': 'NUQUÍ',
      },
      {
        'name': 'RÍO IRO',
        'code': 'RÍO IRO',
      },
      {
        'name': 'RÍO QUITO',
        'code': 'RÍO QUITO',
      },
      {
        'name': 'RIOSUCIO',
        'code': 'RIOSUCIO',
      },
      {
        'name': 'SAN JOSÉ DEL PALMAR',
        'code': 'SAN JOSÉ DEL PALMAR',
      },
      {
        'name': 'SIPÍ',
        'code': 'SIPÍ',
      },
      {
        'name': 'TADÓ',
        'code': 'TADÓ',
      },
      {
        'name': 'UNGUÍA',
        'code': 'UNGUÍA',
      },
      {
        'name': 'UNIÓN PANAMERICANA',
        'code': 'UNIÓN PANAMERICANA',
      },
    ],
  },
  'Córdoba': {
    'name': 'Córdoba',
    'code': 'Córdoba',
    'cities': [
      {
        'name': 'MONTERÍA',
        'code': 'MONTERÍA',
      },
      {
        'name': 'AYAPEL',
        'code': 'AYAPEL',
      },
      {
        'name': 'BUENAVISTA',
        'code': 'BUENAVISTA',
      },
      {
        'name': 'CANALETE',
        'code': 'CANALETE',
      },
      {
        'name': 'CERETÉ',
        'code': 'CERETÉ',
      },
      {
        'name': 'CHIMÁ',
        'code': 'CHIMÁ',
      },
      {
        'name': 'CHINÚ',
        'code': 'CHINÚ',
      },
      {
        'name': 'CIÉNAGA DE ORO',
        'code': 'CIÉNAGA DE ORO',
      },
      {
        'name': 'COTORRA',
        'code': 'COTORRA',
      },
      {
        'name': 'LA APARTADA',
        'code': 'LA APARTADA',
      },
      {
        'name': 'LORICA',
        'code': 'LORICA',
      },
      {
        'name': 'LOS CÓRDOBAS',
        'code': 'LOS CÓRDOBAS',
      },
      {
        'name': 'MOMIL',
        'code': 'MOMIL',
      },
      {
        'name': 'MONTELÍBANO',
        'code': 'MONTELÍBANO',
      },
      {
        'name': 'MOÑITOS',
        'code': 'MOÑITOS',
      },
      {
        'name': 'PLANETA RICA',
        'code': 'PLANETA RICA',
      },
      {
        'name': 'PUEBLO NUEVO',
        'code': 'PUEBLO NUEVO',
      },
      {
        'name': 'PUERTO ESCONDIDO',
        'code': 'PUERTO ESCONDIDO',
      },
      {
        'name': 'PUERTO LIBERTADOR',
        'code': 'PUERTO LIBERTADOR',
      },
      {
        'name': 'PURÍSIMA',
        'code': 'PURÍSIMA',
      },
      {
        'name': 'SAHAGÚN',
        'code': 'SAHAGÚN',
      },
      {
        'name': 'SAN ANDRÉS SOTAVENTO',
        'code': 'SAN ANDRÉS SOTAVENTO',
      },
      {
        'name': 'SAN ANTERO',
        'code': 'SAN ANTERO',
      },
      {
        'name': 'SAN BERNARDO DEL VIENTO',
        'code': 'SAN BERNARDO DEL VIENTO',
      },
      {
        'name': 'SAN CARLOS',
        'code': 'SAN CARLOS',
      },
      {
        'name': 'SAN PELAYO',
        'code': 'SAN PELAYO',
      },
      {
        'name': 'TIERRALTA',
        'code': 'TIERRALTA',
      },
      {
        'name': 'VALENCIA',
        'code': 'VALENCIA',
      },
    ],
  },
  'Cundinamarca': {
    'name': 'Cundinamarca',
    'code': 'Cundinamarca',
    'cities': [
      {
        'name': 'AGUA DE DIOS',
        'code': 'AGUA DE DIOS',
      },
      {
        'name': 'ALBÁN',
        'code': 'ALBÁN',
      },
      {
        'name': 'ANAPOIMA',
        'code': 'ANAPOIMA',
      },
      {
        'name': 'ANOLAIMA',
        'code': 'ANOLAIMA',
      },
      {
        'name': 'ARBELÁEZ',
        'code': 'ARBELÁEZ',
      },
      {
        'name': 'BELTRÁN',
        'code': 'BELTRÁN',
      },
      {
        'name': 'BITUIMA',
        'code': 'BITUIMA',
      },
      {
        'name': 'BOJACÁ',
        'code': 'BOJACÁ',
      },
      {
        'name': 'CABRERA',
        'code': 'CABRERA',
      },
      {
        'name': 'CACHIPAY',
        'code': 'CACHIPAY',
      },
      {
        'name': 'CAJICÁ',
        'code': 'CAJICÁ',
      },
      {
        'name': 'CAPARRAPÍ',
        'code': 'CAPARRAPÍ',
      },
      {
        'name': 'CAQUEZA',
        'code': 'CAQUEZA',
      },
      {
        'name': 'CARMEN DE CARUPA',
        'code': 'CARMEN DE CARUPA',
      },
      {
        'name': 'CHAGUANÍ',
        'code': 'CHAGUANÍ',
      },
      {
        'name': 'CHÍA',
        'code': 'CHÍA',
      },
      {
        'name': 'CHIPAQUE',
        'code': 'CHIPAQUE',
      },
      {
        'name': 'CHOACHÍ',
        'code': 'CHOACHÍ',
      },
      {
        'name': 'CHOCONTÁ',
        'code': 'CHOCONTÁ',
      },
      {
        'name': 'COGUA',
        'code': 'COGUA',
      },
      {
        'name': 'COTA',
        'code': 'COTA',
      },
      {
        'name': 'CUCUNUBÁ',
        'code': 'CUCUNUBÁ',
      },
      {
        'name': 'EL COLEGIO',
        'code': 'EL COLEGIO',
      },
      {
        'name': 'EL PEÑÓN',
        'code': 'EL PEÑÓN',
      },
      {
        'name': 'EL ROSAL',
        'code': 'EL ROSAL',
      },
      {
        'name': 'FACATATIVÁ',
        'code': 'FACATATIVÁ',
      },
      {
        'name': 'FOMEQUE',
        'code': 'FOMEQUE',
      },
      {
        'name': 'FOSCA',
        'code': 'FOSCA',
      },
      {
        'name': 'FUNZA',
        'code': 'FUNZA',
      },
      {
        'name': 'FÚQUENE',
        'code': 'FÚQUENE',
      },
      {
        'name': 'FUSAGASUGÁ',
        'code': 'FUSAGASUGÁ',
      },
      {
        'name': 'GACHALA',
        'code': 'GACHALA',
      },
      {
        'name': 'GACHANCIPÁ',
        'code': 'GACHANCIPÁ',
      },
      {
        'name': 'GACHETÁ',
        'code': 'GACHETÁ',
      },
      {
        'name': 'GAMA',
        'code': 'GAMA',
      },
      {
        'name': 'GIRARDOT',
        'code': 'GIRARDOT',
      },
      {
        'name': 'GRANADA',
        'code': 'GRANADA',
      },
      {
        'name': 'GUACHETÁ',
        'code': 'GUACHETÁ',
      },
      {
        'name': 'GUADUAS',
        'code': 'GUADUAS',
      },
      {
        'name': 'GUASCA',
        'code': 'GUASCA',
      },
      {
        'name': 'GUATAQUÍ',
        'code': 'GUATAQUÍ',
      },
      {
        'name': 'GUATAVITA',
        'code': 'GUATAVITA',
      },
      {
        'name': 'GUAYABAL DE SIQUIMA',
        'code': 'GUAYABAL DE SIQUIMA',
      },
      {
        'name': 'GUAYABETAL',
        'code': 'GUAYABETAL',
      },
      {
        'name': 'GUTIÉRREZ',
        'code': 'GUTIÉRREZ',
      },
      {
        'name': 'JERUSALÉN',
        'code': 'JERUSALÉN',
      },
      {
        'name': 'JUNÍN',
        'code': 'JUNÍN',
      },
      {
        'name': 'LA CALERA',
        'code': 'LA CALERA',
      },
      {
        'name': 'LA MESA',
        'code': 'LA MESA',
      },
      {
        'name': 'LA PALMA',
        'code': 'LA PALMA',
      },
      {
        'name': 'LA PEÑA',
        'code': 'LA PEÑA',
      },
      {
        'name': 'LA VEGA',
        'code': 'LA VEGA',
      },
      {
        'name': 'LENGUAZAQUE',
        'code': 'LENGUAZAQUE',
      },
      {
        'name': 'MACHETA',
        'code': 'MACHETA',
      },
      {
        'name': 'MADRID',
        'code': 'MADRID',
      },
      {
        'name': 'MANTA',
        'code': 'MANTA',
      },
      {
        'name': 'MEDINA',
        'code': 'MEDINA',
      },
      {
        'name': 'MOSQUERA',
        'code': 'MOSQUERA',
      },
      {
        'name': 'NARIÑO',
        'code': 'NARIÑO',
      },
      {
        'name': 'NEMOCÓN',
        'code': 'NEMOCÓN',
      },
      {
        'name': 'NILO',
        'code': 'NILO',
      },
      {
        'name': 'NIMAIMA',
        'code': 'NIMAIMA',
      },
      {
        'name': 'NOCAIMA',
        'code': 'NOCAIMA',
      },
      {
        'name': 'VENECIA',
        'code': 'VENECIA',
      },
      {
        'name': 'PACHO',
        'code': 'PACHO',
      },
      {
        'name': 'PAIME',
        'code': 'PAIME',
      },
      {
        'name': 'PANDI',
        'code': 'PANDI',
      },
      {
        'name': 'PARATEBUENO',
        'code': 'PARATEBUENO',
      },
      {
        'name': 'PASCA',
        'code': 'PASCA',
      },
      {
        'name': 'PUERTO SALGAR',
        'code': 'PUERTO SALGAR',
      },
      {
        'name': 'PULÍ',
        'code': 'PULÍ',
      },
      {
        'name': 'QUEBRADANEGRA',
        'code': 'QUEBRADANEGRA',
      },
      {
        'name': 'QUETAME',
        'code': 'QUETAME',
      },
      {
        'name': 'QUIPILE',
        'code': 'QUIPILE',
      },
      {
        'name': 'APULO',
        'code': 'APULO',
      },
      {
        'name': 'RICAURTE',
        'code': 'RICAURTE',
      },
      {
        'name': 'SAN ANTONIO DEL TEQUENDAMA',
        'code': 'SAN ANTONIO DEL TEQUENDAMA',
      },
      {
        'name': 'SAN BERNARDO',
        'code': 'SAN BERNARDO',
      },
      {
        'name': 'SAN CAYETANO',
        'code': 'SAN CAYETANO',
      },
      {
        'name': 'SAN FRANCISCO',
        'code': 'SAN FRANCISCO',
      },
      {
        'name': 'SAN JUAN DE RÍO SECO',
        'code': 'SAN JUAN DE RÍO SECO',
      },
      {
        'name': 'SASAIMA',
        'code': 'SASAIMA',
      },
      {
        'name': 'SESQUILÉ',
        'code': 'SESQUILÉ',
      },
      {
        'name': 'SIBATÉ',
        'code': 'SIBATÉ',
      },
      {
        'name': 'SILVANIA',
        'code': 'SILVANIA',
      },
      {
        'name': 'SIMIJACA',
        'code': 'SIMIJACA',
      },
      {
        'name': 'SOACHA',
        'code': 'SOACHA',
      },
      {
        'name': 'SOPÓ',
        'code': 'SOPÓ',
      },
      {
        'name': 'SUBACHOQUE',
        'code': 'SUBACHOQUE',
      },
      {
        'name': 'SUESCA',
        'code': 'SUESCA',
      },
      {
        'name': 'SUPATÁ',
        'code': 'SUPATÁ',
      },
      {
        'name': 'SUSA',
        'code': 'SUSA',
      },
      {
        'name': 'SUTATAUSA',
        'code': 'SUTATAUSA',
      },
      {
        'name': 'TABIO',
        'code': 'TABIO',
      },
      {
        'name': 'TAUSA',
        'code': 'TAUSA',
      },
      {
        'name': 'TENA',
        'code': 'TENA',
      },
      {
        'name': 'TENJO',
        'code': 'TENJO',
      },
      {
        'name': 'TIBACUY',
        'code': 'TIBACUY',
      },
      {
        'name': 'TIBIRITA',
        'code': 'TIBIRITA',
      },
      {
        'name': 'TOCAIMA',
        'code': 'TOCAIMA',
      },
      {
        'name': 'TOCANCIPÁ',
        'code': 'TOCANCIPÁ',
      },
      {
        'name': 'TOPAIPÍ',
        'code': 'TOPAIPÍ',
      },
      {
        'name': 'UBALÁ',
        'code': 'UBALÁ',
      },
      {
        'name': 'UBAQUE',
        'code': 'UBAQUE',
      },
      {
        'name': 'VILLA DE SAN DIEGO DE UBATE',
        'code': 'VILLA DE SAN DIEGO DE UBATE',
      },
      {
        'name': 'UNE',
        'code': 'UNE',
      },
      {
        'name': 'ÚTICA',
        'code': 'ÚTICA',
      },
      {
        'name': 'VERGARA',
        'code': 'VERGARA',
      },
      {
        'name': 'VIANÍ',
        'code': 'VIANÍ',
      },
      {
        'name': 'VILLAGÓMEZ',
        'code': 'VILLAGÓMEZ',
      },
      {
        'name': 'VILLAPINZÓN',
        'code': 'VILLAPINZÓN',
      },
      {
        'name': 'VILLETA',
        'code': 'VILLETA',
      },
      {
        'name': 'VIOTÁ',
        'code': 'VIOTÁ',
      },
      {
        'name': 'YACOPÍ',
        'code': 'YACOPÍ',
      },
      {
        'name': 'ZIPACÓN',
        'code': 'ZIPACÓN',
      },
      {
        'name': 'ZIPAQUIRÁ',
        'code': 'ZIPAQUIRÁ',
      },
    ],
  },
  'Guainía': {
    'name': 'Guainía',
    'code': 'Guainía',
    'cities': [
      {
        'name': 'INÍRIDA',
        'code': 'INÍRIDA',
      },
      {
        'name': 'BARRANCO MINAS',
        'code': 'BARRANCO MINAS',
      },
      {
        'name': 'MAPIRIPANA',
        'code': 'MAPIRIPANA',
      },
      {
        'name': 'SAN FELIPE',
        'code': 'SAN FELIPE',
      },
      {
        'name': 'PUERTO COLOMBIA',
        'code': 'PUERTO COLOMBIA',
      },
      {
        'name': 'LA GUADALUPE',
        'code': 'LA GUADALUPE',
      },
      {
        'name': 'CACAHUAL',
        'code': 'CACAHUAL',
      },
      {
        'name': 'PANA PANA',
        'code': 'PANA PANA',
      },
      {
        'name': 'MORICHAL',
        'code': 'MORICHAL',
      },
    ],
  },
  'Guaviare': {
    'name': 'Guaviare',
    'code': 'Guaviare',
    'cities': [
      {
        'name': 'SAN JOSÉ DEL GUAVIARE',
        'code': 'SAN JOSÉ DEL GUAVIARE',
      },
      {
        'name': 'CALAMAR',
        'code': 'CALAMAR',
      },
      {
        'name': 'EL RETORNO',
        'code': 'EL RETORNO',
      },
      {
        'name': 'MIRAFLORES',
        'code': 'MIRAFLORES',
      },
    ],
  },
  'Huila': {
    'name': 'Huila',
    'code': 'Huila',
    'cities': [
      {
        'name': 'NEIVA',
        'code': 'NEIVA',
      },
      {
        'name': 'ACEVEDO',
        'code': 'ACEVEDO',
      },
      {
        'name': 'AGRADO',
        'code': 'AGRADO',
      },
      {
        'name': 'AIPE',
        'code': 'AIPE',
      },
      {
        'name': 'ALGECIRAS',
        'code': 'ALGECIRAS',
      },
      {
        'name': 'ALTAMIRA',
        'code': 'ALTAMIRA',
      },
      {
        'name': 'BARAYA',
        'code': 'BARAYA',
      },
      {
        'name': 'CAMPOALEGRE',
        'code': 'CAMPOALEGRE',
      },
      {
        'name': 'COLOMBIA',
        'code': 'COLOMBIA',
      },
      {
        'name': 'ELÍAS',
        'code': 'ELÍAS',
      },
      {
        'name': 'GARZÓN',
        'code': 'GARZÓN',
      },
      {
        'name': 'GIGANTE',
        'code': 'GIGANTE',
      },
      {
        'name': 'GUADALUPE',
        'code': 'GUADALUPE',
      },
      {
        'name': 'HOBO',
        'code': 'HOBO',
      },
      {
        'name': 'IQUIRA',
        'code': 'IQUIRA',
      },
      {
        'name': 'ISNOS',
        'code': 'ISNOS',
      },
      {
        'name': 'LA ARGENTINA',
        'code': 'LA ARGENTINA',
      },
      {
        'name': 'LA PLATA',
        'code': 'LA PLATA',
      },
      {
        'name': 'NÁTAGA',
        'code': 'NÁTAGA',
      },
      {
        'name': 'OPORAPA',
        'code': 'OPORAPA',
      },
      {
        'name': 'PAICOL',
        'code': 'PAICOL',
      },
      {
        'name': 'PALERMO',
        'code': 'PALERMO',
      },
      {
        'name': 'PALESTINA',
        'code': 'PALESTINA',
      },
      {
        'name': 'PITAL',
        'code': 'PITAL',
      },
      {
        'name': 'PITALITO',
        'code': 'PITALITO',
      },
      {
        'name': 'RIVERA',
        'code': 'RIVERA',
      },
      {
        'name': 'SALADOBLANCO',
        'code': 'SALADOBLANCO',
      },
      {
        'name': 'SAN AGUSTÍN',
        'code': 'SAN AGUSTÍN',
      },
      {
        'name': 'SANTA MARÍA',
        'code': 'SANTA MARÍA',
      },
      {
        'name': 'SUAZA',
        'code': 'SUAZA',
      },
      {
        'name': 'TARQUI',
        'code': 'TARQUI',
      },
      {
        'name': 'TESALIA',
        'code': 'TESALIA',
      },
      {
        'name': 'TELLO',
        'code': 'TELLO',
      },
      {
        'name': 'TERUEL',
        'code': 'TERUEL',
      },
      {
        'name': 'TIMANÁ',
        'code': 'TIMANÁ',
      },
      {
        'name': 'VILLAVIEJA',
        'code': 'VILLAVIEJA',
      },
      {
        'name': 'YAGUARÁ',
        'code': 'YAGUARÁ',
      },
    ],
  },
  'La Guajira': {
    'name': 'La Guajira',
    'code': 'La Guajira',
    'cities': [
      {
        'name': 'RIOHACHA',
        'code': 'RIOHACHA',
      },
      {
        'name': 'ALBANIA',
        'code': 'ALBANIA',
      },
      {
        'name': 'BARRANCAS',
        'code': 'BARRANCAS',
      },
      {
        'name': 'DIBULLA',
        'code': 'DIBULLA',
      },
      {
        'name': 'DISTRACCIÓN',
        'code': 'DISTRACCIÓN',
      },
      {
        'name': 'EL MOLINO',
        'code': 'EL MOLINO',
      },
      {
        'name': 'FONSECA',
        'code': 'FONSECA',
      },
      {
        'name': 'HATONUEVO',
        'code': 'HATONUEVO',
      },
      {
        'name': 'LA JAGUA DEL PILAR',
        'code': 'LA JAGUA DEL PILAR',
      },
      {
        'name': 'MAICAO',
        'code': 'MAICAO',
      },
      {
        'name': 'MANAURE',
        'code': 'MANAURE',
      },
      {
        'name': 'SAN JUAN DEL CESAR',
        'code': 'SAN JUAN DEL CESAR',
      },
      {
        'name': 'URIBIA',
        'code': 'URIBIA',
      },
      {
        'name': 'URUMITA',
        'code': 'URUMITA',
      },
      {
        'name': 'VILLANUEVA',
        'code': 'VILLANUEVA',
      },
    ],
  },
  'Magdalena': {
    'name': 'Magdalena',
    'code': 'Magdalena',
    'cities': [
      {
        'name': 'SANTA MARTA',
        'code': 'SANTA MARTA',
      },
      {
        'name': 'ALGARROBO',
        'code': 'ALGARROBO',
      },
      {
        'name': 'ARACATACA',
        'code': 'ARACATACA',
      },
      {
        'name': 'ARIGUANÍ',
        'code': 'ARIGUANÍ',
      },
      {
        'name': 'CERRO SAN ANTONIO',
        'code': 'CERRO SAN ANTONIO',
      },
      {
        'name': 'CHIBOLO',
        'code': 'CHIBOLO',
      },
      {
        'name': 'CIÉNAGA',
        'code': 'CIÉNAGA',
      },
      {
        'name': 'CONCORDIA',
        'code': 'CONCORDIA',
      },
      {
        'name': 'EL BANCO',
        'code': 'EL BANCO',
      },
      {
        'name': 'EL PIÑON',
        'code': 'EL PIÑON',
      },
      {
        'name': 'EL RETÉN',
        'code': 'EL RETÉN',
      },
      {
        'name': 'FUNDACIÓN',
        'code': 'FUNDACIÓN',
      },
      {
        'name': 'GUAMAL',
        'code': 'GUAMAL',
      },
      {
        'name': 'NUEVA GRANADA',
        'code': 'NUEVA GRANADA',
      },
      {
        'name': 'PEDRAZA',
        'code': 'PEDRAZA',
      },
      {
        'name': 'PIJIÑO DEL CARMEN',
        'code': 'PIJIÑO DEL CARMEN',
      },
      {
        'name': 'PIVIJAY',
        'code': 'PIVIJAY',
      },
      {
        'name': 'PLATO',
        'code': 'PLATO',
      },
      {
        'name': 'PUEBLOVIEJO',
        'code': 'PUEBLOVIEJO',
      },
      {
        'name': 'REMOLINO',
        'code': 'REMOLINO',
      },
      {
        'name': 'SABANAS DE SAN ANGEL',
        'code': 'SABANAS DE SAN ANGEL',
      },
      {
        'name': 'SALAMINA',
        'code': 'SALAMINA',
      },
      {
        'name': 'SAN SEBASTIÁN DE BUENAVISTA',
        'code': 'SAN SEBASTIÁN DE BUENAVISTA',
      },
      {
        'name': 'SAN ZENÓN',
        'code': 'SAN ZENÓN',
      },
      {
        'name': 'SANTA ANA',
        'code': 'SANTA ANA',
      },
      {
        'name': 'SANTA BÁRBARA DE PINTO',
        'code': 'SANTA BÁRBARA DE PINTO',
      },
      {
        'name': 'SITIONUEVO',
        'code': 'SITIONUEVO',
      },
      {
        'name': 'TENERIFE',
        'code': 'TENERIFE',
      },
      {
        'name': 'ZAPAYÁN',
        'code': 'ZAPAYÁN',
      },
      {
        'name': 'ZONA BANANERA',
        'code': 'ZONA BANANERA',
      },
    ],
  },
  'Meta': {
    'name': 'Meta',
    'code': 'Meta',
    'cities': [
      {
        'name': 'VILLAVICENCIO',
        'code': 'VILLAVICENCIO',
      },
      {
        'name': 'ACACÍAS',
        'code': 'ACACÍAS',
      },
      {
        'name': 'BARRANCA DE UPÍA',
        'code': 'BARRANCA DE UPÍA',
      },
      {
        'name': 'CABUYARO',
        'code': 'CABUYARO',
      },
      {
        'name': 'CASTILLA LA NUEVA',
        'code': 'CASTILLA LA NUEVA',
      },
      {
        'name': 'CUBARRAL',
        'code': 'CUBARRAL',
      },
      {
        'name': 'CUMARAL',
        'code': 'CUMARAL',
      },
      {
        'name': 'EL CALVARIO',
        'code': 'EL CALVARIO',
      },
      {
        'name': 'EL CASTILLO',
        'code': 'EL CASTILLO',
      },
      {
        'name': 'EL DORADO',
        'code': 'EL DORADO',
      },
      {
        'name': 'FUENTE DE ORO',
        'code': 'FUENTE DE ORO',
      },
      {
        'name': 'GRANADA',
        'code': 'GRANADA',
      },
      {
        'name': 'GUAMAL',
        'code': 'GUAMAL',
      },
      {
        'name': 'MAPIRIPÁN',
        'code': 'MAPIRIPÁN',
      },
      {
        'name': 'MESETAS',
        'code': 'MESETAS',
      },
      {
        'name': 'LA MACARENA',
        'code': 'LA MACARENA',
      },
      {
        'name': 'URIBE',
        'code': 'URIBE',
      },
      {
        'name': 'LEJANÍAS',
        'code': 'LEJANÍAS',
      },
      {
        'name': 'PUERTO CONCORDIA',
        'code': 'PUERTO CONCORDIA',
      },
      {
        'name': 'PUERTO GAITÁN',
        'code': 'PUERTO GAITÁN',
      },
      {
        'name': 'PUERTO LÓPEZ',
        'code': 'PUERTO LÓPEZ',
      },
      {
        'name': 'PUERTO LLERAS',
        'code': 'PUERTO LLERAS',
      },
      {
        'name': 'PUERTO RICO',
        'code': 'PUERTO RICO',
      },
      {
        'name': 'RESTREPO',
        'code': 'RESTREPO',
      },
      {
        'name': 'SAN CARLOS DE GUAROA',
        'code': 'SAN CARLOS DE GUAROA',
      },
      {
        'name': 'SAN JUAN DE ARAMA',
        'code': 'SAN JUAN DE ARAMA',
      },
      {
        'name': 'SAN JUANITO',
        'code': 'SAN JUANITO',
      },
      {
        'name': 'SAN MARTÍN',
        'code': 'SAN MARTÍN',
      },
      {
        'name': 'VISTAHERMOSA',
        'code': 'VISTAHERMOSA',
      },
    ],
  },
  'Nariño': {
    'name': 'Nariño',
    'code': 'Nariño',
    'cities': [
      {
        'name': 'PASTO',
        'code': 'PASTO',
      },
      {
        'name': 'ALBÁN',
        'code': 'ALBÁN',
      },
      {
        'name': 'ALDANA',
        'code': 'ALDANA',
      },
      {
        'name': 'ANCUYÁ',
        'code': 'ANCUYÁ',
      },
      {
        'name': 'ARBOLEDA',
        'code': 'ARBOLEDA',
      },
      {
        'name': 'BARBACOAS',
        'code': 'BARBACOAS',
      },
      {
        'name': 'BELÉN',
        'code': 'BELÉN',
      },
      {
        'name': 'BUESACO',
        'code': 'BUESACO',
      },
      {
        'name': 'COLÓN',
        'code': 'COLÓN',
      },
      {
        'name': 'CONSACA',
        'code': 'CONSACA',
      },
      {
        'name': 'CONTADERO',
        'code': 'CONTADERO',
      },
      {
        'name': 'CÓRDOBA',
        'code': 'CÓRDOBA',
      },
      {
        'name': 'CUASPUD',
        'code': 'CUASPUD',
      },
      {
        'name': 'CUMBAL',
        'code': 'CUMBAL',
      },
      {
        'name': 'CUMBITARA',
        'code': 'CUMBITARA',
      },
      {
        'name': 'CHACHAGÜÍ',
        'code': 'CHACHAGÜÍ',
      },
      {
        'name': 'EL CHARCO',
        'code': 'EL CHARCO',
      },
      {
        'name': 'EL PEÑOL',
        'code': 'EL PEÑOL',
      },
      {
        'name': 'EL ROSARIO',
        'code': 'EL ROSARIO',
      },
      {
        'name': 'EL TABLÓN DE GÓMEZ',
        'code': 'EL TABLÓN DE GÓMEZ',
      },
      {
        'name': 'EL TAMBO',
        'code': 'EL TAMBO',
      },
      {
        'name': 'FUNES',
        'code': 'FUNES',
      },
      {
        'name': 'GUACHUCAL',
        'code': 'GUACHUCAL',
      },
      {
        'name': 'GUAITARILLA',
        'code': 'GUAITARILLA',
      },
      {
        'name': 'GUALMATÁN',
        'code': 'GUALMATÁN',
      },
      {
        'name': 'ILES',
        'code': 'ILES',
      },
      {
        'name': 'IMUÉS',
        'code': 'IMUÉS',
      },
      {
        'name': 'IPIALES',
        'code': 'IPIALES',
      },
      {
        'name': 'LA CRUZ',
        'code': 'LA CRUZ',
      },
      {
        'name': 'LA FLORIDA',
        'code': 'LA FLORIDA',
      },
      {
        'name': 'LA LLANADA',
        'code': 'LA LLANADA',
      },
      {
        'name': 'LA TOLA',
        'code': 'LA TOLA',
      },
      {
        'name': 'LA UNIÓN',
        'code': 'LA UNIÓN',
      },
      {
        'name': 'LEIVA',
        'code': 'LEIVA',
      },
      {
        'name': 'LINARES',
        'code': 'LINARES',
      },
      {
        'name': 'LOS ANDES',
        'code': 'LOS ANDES',
      },
      {
        'name': 'MAGÜI',
        'code': 'MAGÜI',
      },
      {
        'name': 'MALLAMA',
        'code': 'MALLAMA',
      },
      {
        'name': 'MOSQUERA',
        'code': 'MOSQUERA',
      },
      {
        'name': 'NARIÑO',
        'code': 'NARIÑO',
      },
      {
        'name': 'OLAYA HERRERA',
        'code': 'OLAYA HERRERA',
      },
      {
        'name': 'OSPINA',
        'code': 'OSPINA',
      },
      {
        'name': 'FRANCISCO PIZARRO',
        'code': 'FRANCISCO PIZARRO',
      },
      {
        'name': 'POLICARPA',
        'code': 'POLICARPA',
      },
      {
        'name': 'POTOSÍ',
        'code': 'POTOSÍ',
      },
      {
        'name': 'PROVIDENCIA',
        'code': 'PROVIDENCIA',
      },
      {
        'name': 'PUERRES',
        'code': 'PUERRES',
      },
      {
        'name': 'PUPIALES',
        'code': 'PUPIALES',
      },
      {
        'name': 'RICAURTE',
        'code': 'RICAURTE',
      },
      {
        'name': 'ROBERTO PAYÁN',
        'code': 'ROBERTO PAYÁN',
      },
      {
        'name': 'SAMANIEGO',
        'code': 'SAMANIEGO',
      },
      {
        'name': 'SANDONÁ',
        'code': 'SANDONÁ',
      },
      {
        'name': 'SAN BERNARDO',
        'code': 'SAN BERNARDO',
      },
      {
        'name': 'SAN LORENZO',
        'code': 'SAN LORENZO',
      },
      {
        'name': 'SAN PABLO',
        'code': 'SAN PABLO',
      },
      {
        'name': 'SAN PEDRO DE CARTAGO',
        'code': 'SAN PEDRO DE CARTAGO',
      },
      {
        'name': 'SANTA BÁRBARA',
        'code': 'SANTA BÁRBARA',
      },
      {
        'name': 'SANTACRUZ',
        'code': 'SANTACRUZ',
      },
      {
        'name': 'SAPUYES',
        'code': 'SAPUYES',
      },
      {
        'name': 'TAMINANGO',
        'code': 'TAMINANGO',
      },
      {
        'name': 'TANGUA',
        'code': 'TANGUA',
      },
      {
        'name': 'SAN ANDRES DE TUMACO',
        'code': 'SAN ANDRES DE TUMACO',
      },
      {
        'name': 'TÚQUERRES',
        'code': 'TÚQUERRES',
      },
      {
        'name': 'YACUANQUER',
        'code': 'YACUANQUER',
      },
    ],
  },
  'Norte de Santander': {
    'name': 'Norte de Santander',
    'code': 'Norte de Santander',
    'cities': [
      {
        'name': 'CÚCUTA',
        'code': 'CÚCUTA',
      },
      {
        'name': 'ABREGO',
        'code': 'ABREGO',
      },
      {
        'name': 'ARBOLEDAS',
        'code': 'ARBOLEDAS',
      },
      {
        'name': 'BOCHALEMA',
        'code': 'BOCHALEMA',
      },
      {
        'name': 'BUCARASICA',
        'code': 'BUCARASICA',
      },
      {
        'name': 'CÁCOTA',
        'code': 'CÁCOTA',
      },
      {
        'name': 'CACHIRÁ',
        'code': 'CACHIRÁ',
      },
      {
        'name': 'CHINÁCOTA',
        'code': 'CHINÁCOTA',
      },
      {
        'name': 'CHITAGÁ',
        'code': 'CHITAGÁ',
      },
      {
        'name': 'CONVENCIÓN',
        'code': 'CONVENCIÓN',
      },
      {
        'name': 'CUCUTILLA',
        'code': 'CUCUTILLA',
      },
      {
        'name': 'DURANIA',
        'code': 'DURANIA',
      },
      {
        'name': 'EL CARMEN',
        'code': 'EL CARMEN',
      },
      {
        'name': 'EL TARRA',
        'code': 'EL TARRA',
      },
      {
        'name': 'EL ZULIA',
        'code': 'EL ZULIA',
      },
      {
        'name': 'GRAMALOTE',
        'code': 'GRAMALOTE',
      },
      {
        'name': 'HACARÍ',
        'code': 'HACARÍ',
      },
      {
        'name': 'HERRÁN',
        'code': 'HERRÁN',
      },
      {
        'name': 'LABATECA',
        'code': 'LABATECA',
      },
      {
        'name': 'LA ESPERANZA',
        'code': 'LA ESPERANZA',
      },
      {
        'name': 'LA PLAYA',
        'code': 'LA PLAYA',
      },
      {
        'name': 'LOS PATIOS',
        'code': 'LOS PATIOS',
      },
      {
        'name': 'LOURDES',
        'code': 'LOURDES',
      },
      {
        'name': 'MUTISCUA',
        'code': 'MUTISCUA',
      },
      {
        'name': 'OCAÑA',
        'code': 'OCAÑA',
      },
      {
        'name': 'PAMPLONA',
        'code': 'PAMPLONA',
      },
      {
        'name': 'PAMPLONITA',
        'code': 'PAMPLONITA',
      },
      {
        'name': 'PUERTO SANTANDER',
        'code': 'PUERTO SANTANDER',
      },
      {
        'name': 'RAGONVALIA',
        'code': 'RAGONVALIA',
      },
      {
        'name': 'SALAZAR',
        'code': 'SALAZAR',
      },
      {
        'name': 'SAN CALIXTO',
        'code': 'SAN CALIXTO',
      },
      {
        'name': 'SAN CAYETANO',
        'code': 'SAN CAYETANO',
      },
      {
        'name': 'SANTIAGO',
        'code': 'SANTIAGO',
      },
      {
        'name': 'SARDINATA',
        'code': 'SARDINATA',
      },
      {
        'name': 'SILOS',
        'code': 'SILOS',
      },
      {
        'name': 'TEORAMA',
        'code': 'TEORAMA',
      },
      {
        'name': 'TIBÚ',
        'code': 'TIBÚ',
      },
      {
        'name': 'TOLEDO',
        'code': 'TOLEDO',
      },
      {
        'name': 'VILLA CARO',
        'code': 'VILLA CARO',
      },
      {
        'name': 'VILLA DEL ROSARIO',
        'code': 'VILLA DEL ROSARIO',
      },
    ],
  },
  'Putumayo': {
    'name': 'Putumayo',
    'code': 'Putumayo',
    'cities': [
      {
        'name': 'MOCOA',
        'code': 'MOCOA',
      },
      {
        'name': 'COLÓN',
        'code': 'COLÓN',
      },
      {
        'name': 'ORITO',
        'code': 'ORITO',
      },
      {
        'name': 'PUERTO ASÍS',
        'code': 'PUERTO ASÍS',
      },
      {
        'name': 'PUERTO CAICEDO',
        'code': 'PUERTO CAICEDO',
      },
      {
        'name': 'PUERTO GUZMÁN',
        'code': 'PUERTO GUZMÁN',
      },
      {
        'name': 'LEGUÍZAMO',
        'code': 'LEGUÍZAMO',
      },
      {
        'name': 'SIBUNDOY',
        'code': 'SIBUNDOY',
      },
      {
        'name': 'SAN FRANCISCO',
        'code': 'SAN FRANCISCO',
      },
      {
        'name': 'SAN MIGUEL',
        'code': 'SAN MIGUEL',
      },
      {
        'name': 'SANTIAGO',
        'code': 'SANTIAGO',
      },
      {
        'name': 'VALLE DEL GUAMUEZ',
        'code': 'VALLE DEL GUAMUEZ',
      },
      {
        'name': 'VILLAGARZÓN',
        'code': 'VILLAGARZÓN',
      },
    ],
  },
  'Quindío': {
    'name': 'Quindío',
    'code': 'Quindío',
    'cities': [
      {
        'name': 'ARMENIA',
        'code': 'ARMENIA',
      },
      {
        'name': 'BUENAVISTA',
        'code': 'BUENAVISTA',
      },
      {
        'name': 'CALARCA',
        'code': 'CALARCA',
      },
      {
        'name': 'CIRCASIA',
        'code': 'CIRCASIA',
      },
      {
        'name': 'CÓRDOBA',
        'code': 'CÓRDOBA',
      },
      {
        'name': 'FILANDIA',
        'code': 'FILANDIA',
      },
      {
        'name': 'GÉNOVA',
        'code': 'GÉNOVA',
      },
      {
        'name': 'LA TEBAIDA',
        'code': 'LA TEBAIDA',
      },
      {
        'name': 'MONTENEGRO',
        'code': 'MONTENEGRO',
      },
      {
        'name': 'PIJAO',
        'code': 'PIJAO',
      },
      {
        'name': 'QUIMBAYA',
        'code': 'QUIMBAYA',
      },
      {
        'name': 'SALENTO',
        'code': 'SALENTO',
      },
    ],
  },
  'Risaralda': {
    'name': 'Risaralda',
    'code': 'Risaralda',
    'cities': [
      {
        'name': 'PEREIRA',
        'code': 'PEREIRA',
      },
      {
        'name': 'APÍA',
        'code': 'APÍA',
      },
      {
        'name': 'BALBOA',
        'code': 'BALBOA',
      },
      {
        'name': 'BELÉN DE UMBRÍA',
        'code': 'BELÉN DE UMBRÍA',
      },
      {
        'name': 'DOSQUEBRADAS',
        'code': 'DOSQUEBRADAS',
      },
      {
        'name': 'GUÁTICA',
        'code': 'GUÁTICA',
      },
      {
        'name': 'LA CELIA',
        'code': 'LA CELIA',
      },
      {
        'name': 'LA VIRGINIA',
        'code': 'LA VIRGINIA',
      },
      {
        'name': 'MARSELLA',
        'code': 'MARSELLA',
      },
      {
        'name': 'MISTRATÓ',
        'code': 'MISTRATÓ',
      },
      {
        'name': 'PUEBLO RICO',
        'code': 'PUEBLO RICO',
      },
      {
        'name': 'QUINCHÍA',
        'code': 'QUINCHÍA',
      },
      {
        'name': 'SANTA ROSA DE CABAL',
        'code': 'SANTA ROSA DE CABAL',
      },
      {
        'name': 'SANTUARIO',
        'code': 'SANTUARIO',
      },
    ],
  },
  'San Andrés': {
    'name': 'San Andrés',
    'code': 'San Andrés',
    'cities': [
      {
        'name': 'SAN ANDRÉS',
        'code': 'SAN ANDRÉS',
      },
      {
        'name': 'PROVIDENCIA',
        'code': 'PROVIDENCIA',
      },
    ],
  },
  'Santander': {
    'name': 'Santander',
    'code': 'Santander',
    'cities': [
      {
        'name': 'BUCARAMANGA',
        'code': 'BUCARAMANGA',
      },
      {
        'name': 'AGUADA',
        'code': 'AGUADA',
      },
      {
        'name': 'ALBANIA',
        'code': 'ALBANIA',
      },
      {
        'name': 'ARATOCA',
        'code': 'ARATOCA',
      },
      {
        'name': 'BARBOSA',
        'code': 'BARBOSA',
      },
      {
        'name': 'BARICHARA',
        'code': 'BARICHARA',
      },
      {
        'name': 'BARRANCABERMEJA',
        'code': 'BARRANCABERMEJA',
      },
      {
        'name': 'BETULIA',
        'code': 'BETULIA',
      },
      {
        'name': 'BOLÍVAR',
        'code': 'BOLÍVAR',
      },
      {
        'name': 'CABRERA',
        'code': 'CABRERA',
      },
      {
        'name': 'CALIFORNIA',
        'code': 'CALIFORNIA',
      },
      {
        'name': 'CAPITANEJO',
        'code': 'CAPITANEJO',
      },
      {
        'name': 'CARCASÍ',
        'code': 'CARCASÍ',
      },
      {
        'name': 'CEPITÁ',
        'code': 'CEPITÁ',
      },
      {
        'name': 'CERRITO',
        'code': 'CERRITO',
      },
      {
        'name': 'CHARALÁ',
        'code': 'CHARALÁ',
      },
      {
        'name': 'CHARTA',
        'code': 'CHARTA',
      },
      {
        'name': 'CHIMA',
        'code': 'CHIMA',
      },
      {
        'name': 'CHIPATÁ',
        'code': 'CHIPATÁ',
      },
      {
        'name': 'CIMITARRA',
        'code': 'CIMITARRA',
      },
      {
        'name': 'CONCEPCIÓN',
        'code': 'CONCEPCIÓN',
      },
      {
        'name': 'CONFINES',
        'code': 'CONFINES',
      },
      {
        'name': 'CONTRATACIÓN',
        'code': 'CONTRATACIÓN',
      },
      {
        'name': 'COROMORO',
        'code': 'COROMORO',
      },
      {
        'name': 'CURITÍ',
        'code': 'CURITÍ',
      },
      {
        'name': 'EL CARMEN DE CHUCURÍ',
        'code': 'EL CARMEN DE CHUCURÍ',
      },
      {
        'name': 'EL GUACAMAYO',
        'code': 'EL GUACAMAYO',
      },
      {
        'name': 'EL PEÑÓN',
        'code': 'EL PEÑÓN',
      },
      {
        'name': 'EL PLAYÓN',
        'code': 'EL PLAYÓN',
      },
      {
        'name': 'ENCINO',
        'code': 'ENCINO',
      },
      {
        'name': 'ENCISO',
        'code': 'ENCISO',
      },
      {
        'name': 'FLORIÁN',
        'code': 'FLORIÁN',
      },
      {
        'name': 'FLORIDABLANCA',
        'code': 'FLORIDABLANCA',
      },
      {
        'name': 'GALÁN',
        'code': 'GALÁN',
      },
      {
        'name': 'GAMBITA',
        'code': 'GAMBITA',
      },
      {
        'name': 'GIRÓN',
        'code': 'GIRÓN',
      },
      {
        'name': 'GUACA',
        'code': 'GUACA',
      },
      {
        'name': 'GUADALUPE',
        'code': 'GUADALUPE',
      },
      {
        'name': 'GUAPOTÁ',
        'code': 'GUAPOTÁ',
      },
      {
        'name': 'GUAVATÁ',
        'code': 'GUAVATÁ',
      },
      {
        'name': 'GÜEPSA',
        'code': 'GÜEPSA',
      },
      {
        'name': 'HATO',
        'code': 'HATO',
      },
      {
        'name': 'JESÚS MARÍA',
        'code': 'JESÚS MARÍA',
      },
      {
        'name': 'JORDÁN',
        'code': 'JORDÁN',
      },
      {
        'name': 'LA BELLEZA',
        'code': 'LA BELLEZA',
      },
      {
        'name': 'LANDÁZURI',
        'code': 'LANDÁZURI',
      },
      {
        'name': 'LA PAZ',
        'code': 'LA PAZ',
      },
      {
        'name': 'LEBRÍJA',
        'code': 'LEBRÍJA',
      },
      {
        'name': 'LOS SANTOS',
        'code': 'LOS SANTOS',
      },
      {
        'name': 'MACARAVITA',
        'code': 'MACARAVITA',
      },
      {
        'name': 'MÁLAGA',
        'code': 'MÁLAGA',
      },
      {
        'name': 'MATANZA',
        'code': 'MATANZA',
      },
      {
        'name': 'MOGOTES',
        'code': 'MOGOTES',
      },
      {
        'name': 'MOLAGAVITA',
        'code': 'MOLAGAVITA',
      },
      {
        'name': 'OCAMONTE',
        'code': 'OCAMONTE',
      },
      {
        'name': 'OIBA',
        'code': 'OIBA',
      },
      {
        'name': 'ONZAGA',
        'code': 'ONZAGA',
      },
      {
        'name': 'PALMAR',
        'code': 'PALMAR',
      },
      {
        'name': 'PALMAS DEL SOCORRO',
        'code': 'PALMAS DEL SOCORRO',
      },
      {
        'name': 'PÁRAMO',
        'code': 'PÁRAMO',
      },
      {
        'name': 'PIEDECUESTA',
        'code': 'PIEDECUESTA',
      },
      {
        'name': 'PINCHOTE',
        'code': 'PINCHOTE',
      },
      {
        'name': 'PUENTE NACIONAL',
        'code': 'PUENTE NACIONAL',
      },
      {
        'name': 'PUERTO PARRA',
        'code': 'PUERTO PARRA',
      },
      {
        'name': 'PUERTO WILCHES',
        'code': 'PUERTO WILCHES',
      },
      {
        'name': 'RIONEGRO',
        'code': 'RIONEGRO',
      },
      {
        'name': 'SABANA DE TORRES',
        'code': 'SABANA DE TORRES',
      },
      {
        'name': 'SAN ANDRÉS',
        'code': 'SAN ANDRÉS',
      },
      {
        'name': 'SAN BENITO',
        'code': 'SAN BENITO',
      },
      {
        'name': 'SAN GIL',
        'code': 'SAN GIL',
      },
      {
        'name': 'SAN JOAQUÍN',
        'code': 'SAN JOAQUÍN',
      },
      {
        'name': 'SAN JOSÉ DE MIRANDA',
        'code': 'SAN JOSÉ DE MIRANDA',
      },
      {
        'name': 'SAN MIGUEL',
        'code': 'SAN MIGUEL',
      },
      {
        'name': 'SAN VICENTE DE CHUCURÍ',
        'code': 'SAN VICENTE DE CHUCURÍ',
      },
      {
        'name': 'SANTA BÁRBARA',
        'code': 'SANTA BÁRBARA',
      },
      {
        'name': 'SANTA HELENA DEL OPÓN',
        'code': 'SANTA HELENA DEL OPÓN',
      },
      {
        'name': 'SIMACOTA',
        'code': 'SIMACOTA',
      },
      {
        'name': 'SOCORRO',
        'code': 'SOCORRO',
      },
      {
        'name': 'SUAITA',
        'code': 'SUAITA',
      },
      {
        'name': 'SUCRE',
        'code': 'SUCRE',
      },
      {
        'name': 'SURATÁ',
        'code': 'SURATÁ',
      },
      {
        'name': 'TONA',
        'code': 'TONA',
      },
      {
        'name': 'VALLE DE SAN JOSÉ',
        'code': 'VALLE DE SAN JOSÉ',
      },
      {
        'name': 'VÉLEZ',
        'code': 'VÉLEZ',
      },
      {
        'name': 'VETAS',
        'code': 'VETAS',
      },
      {
        'name': 'VILLANUEVA',
        'code': 'VILLANUEVA',
      },
      {
        'name': 'ZAPATOCA',
        'code': 'ZAPATOCA',
      },
    ],
  },
  'Sucre': {
    'name': 'Sucre',
    'code': 'Sucre',
    'cities': [
      {
        'name': 'SINCELEJO',
        'code': 'SINCELEJO',
      },
      {
        'name': 'BUENAVISTA',
        'code': 'BUENAVISTA',
      },
      {
        'name': 'CAIMITO',
        'code': 'CAIMITO',
      },
      {
        'name': 'COLOSO',
        'code': 'COLOSO',
      },
      {
        'name': 'COROZAL',
        'code': 'COROZAL',
      },
      {
        'name': 'COVEÑAS',
        'code': 'COVEÑAS',
      },
      {
        'name': 'CHALÁN',
        'code': 'CHALÁN',
      },
      {
        'name': 'EL ROBLE',
        'code': 'EL ROBLE',
      },
      {
        'name': 'GALERAS',
        'code': 'GALERAS',
      },
      {
        'name': 'GUARANDA',
        'code': 'GUARANDA',
      },
      {
        'name': 'LA UNIÓN',
        'code': 'LA UNIÓN',
      },
      {
        'name': 'LOS PALMITOS',
        'code': 'LOS PALMITOS',
      },
      {
        'name': 'MAJAGUAL',
        'code': 'MAJAGUAL',
      },
      {
        'name': 'MORROA',
        'code': 'MORROA',
      },
      {
        'name': 'OVEJAS',
        'code': 'OVEJAS',
      },
      {
        'name': 'PALMITO',
        'code': 'PALMITO',
      },
      {
        'name': 'SAMPUÉS',
        'code': 'SAMPUÉS',
      },
      {
        'name': 'SAN BENITO ABAD',
        'code': 'SAN BENITO ABAD',
      },
      {
        'name': 'SAN JUAN DE BETULIA',
        'code': 'SAN JUAN DE BETULIA',
      },
      {
        'name': 'SAN MARCOS',
        'code': 'SAN MARCOS',
      },
      {
        'name': 'SAN ONOFRE',
        'code': 'SAN ONOFRE',
      },
      {
        'name': 'SAN PEDRO',
        'code': 'SAN PEDRO',
      },
      {
        'name': 'SAN LUIS DE SINCÉ',
        'code': 'SAN LUIS DE SINCÉ',
      },
      {
        'name': 'SUCRE',
        'code': 'SUCRE',
      },
      {
        'name': 'SANTIAGO DE TOLÚ',
        'code': 'SANTIAGO DE TOLÚ',
      },
      {
        'name': 'TOLÚ VIEJO',
        'code': 'TOLÚ VIEJO',
      },
    ],
  },
  'Tolima': {
    'name': 'Tolima',
    'code': 'Tolima',
    'cities': [
      {
        'name': 'IBAGUÉ',
        'code': 'IBAGUÉ',
      },
      {
        'name': 'ALPUJARRA',
        'code': 'ALPUJARRA',
      },
      {
        'name': 'ALVARADO',
        'code': 'ALVARADO',
      },
      {
        'name': 'AMBALEMA',
        'code': 'AMBALEMA',
      },
      {
        'name': 'ANZOÁTEGUI',
        'code': 'ANZOÁTEGUI',
      },
      {
        'name': 'ARMERO',
        'code': 'ARMERO',
      },
      {
        'name': 'ATACO',
        'code': 'ATACO',
      },
      {
        'name': 'CAJAMARCA',
        'code': 'CAJAMARCA',
      },
      {
        'name': 'CARMEN DE APICALÁ',
        'code': 'CARMEN DE APICALÁ',
      },
      {
        'name': 'CASABIANCA',
        'code': 'CASABIANCA',
      },
      {
        'name': 'CHAPARRAL',
        'code': 'CHAPARRAL',
      },
      {
        'name': 'COELLO',
        'code': 'COELLO',
      },
      {
        'name': 'COYAIMA',
        'code': 'COYAIMA',
      },
      {
        'name': 'CUNDAY',
        'code': 'CUNDAY',
      },
      {
        'name': 'DOLORES',
        'code': 'DOLORES',
      },
      {
        'name': 'ESPINAL',
        'code': 'ESPINAL',
      },
      {
        'name': 'FALAN',
        'code': 'FALAN',
      },
      {
        'name': 'FLANDES',
        'code': 'FLANDES',
      },
      {
        'name': 'FRESNO',
        'code': 'FRESNO',
      },
      {
        'name': 'GUAMO',
        'code': 'GUAMO',
      },
      {
        'name': 'HERVEO',
        'code': 'HERVEO',
      },
      {
        'name': 'HONDA',
        'code': 'HONDA',
      },
      {
        'name': 'ICONONZO',
        'code': 'ICONONZO',
      },
      {
        'name': 'LÉRIDA',
        'code': 'LÉRIDA',
      },
      {
        'name': 'LÍBANO',
        'code': 'LÍBANO',
      },
      {
        'name': 'MARIQUITA',
        'code': 'MARIQUITA',
      },
      {
        'name': 'MELGAR',
        'code': 'MELGAR',
      },
      {
        'name': 'MURILLO',
        'code': 'MURILLO',
      },
      {
        'name': 'NATAGAIMA',
        'code': 'NATAGAIMA',
      },
      {
        'name': 'ORTEGA',
        'code': 'ORTEGA',
      },
      {
        'name': 'PALOCABILDO',
        'code': 'PALOCABILDO',
      },
      {
        'name': 'PIEDRAS',
        'code': 'PIEDRAS',
      },
      {
        'name': 'PLANADAS',
        'code': 'PLANADAS',
      },
      {
        'name': 'PRADO',
        'code': 'PRADO',
      },
      {
        'name': 'PURIFICACIÓN',
        'code': 'PURIFICACIÓN',
      },
      {
        'name': 'RIOBLANCO',
        'code': 'RIOBLANCO',
      },
      {
        'name': 'RONCESVALLES',
        'code': 'RONCESVALLES',
      },
      {
        'name': 'ROVIRA',
        'code': 'ROVIRA',
      },
      {
        'name': 'SALDAÑA',
        'code': 'SALDAÑA',
      },
      {
        'name': 'SAN ANTONIO',
        'code': 'SAN ANTONIO',
      },
      {
        'name': 'SAN LUIS',
        'code': 'SAN LUIS',
      },
      {
        'name': 'SANTA ISABEL',
        'code': 'SANTA ISABEL',
      },
      {
        'name': 'SUÁREZ',
        'code': 'SUÁREZ',
      },
      {
        'name': 'VALLE DE SAN JUAN',
        'code': 'VALLE DE SAN JUAN',
      },
      {
        'name': 'VENADILLO',
        'code': 'VENADILLO',
      },
      {
        'name': 'VILLAHERMOSA',
        'code': 'VILLAHERMOSA',
      },
      {
        'name': 'VILLARRICA',
        'code': 'VILLARRICA',
      },
    ],
  },
  'Valle del Cauca': {
    'name': 'Valle del Cauca',
    'code': 'Valle del Cauca',
    'cities': [
      {
        'name': 'CALI',
        'code': 'CALI',
      },
      {
        'name': 'ALCALÁ',
        'code': 'ALCALÁ',
      },
      {
        'name': 'ANDALUCÍA',
        'code': 'ANDALUCÍA',
      },
      {
        'name': 'ANSERMANUEVO',
        'code': 'ANSERMANUEVO',
      },
      {
        'name': 'ARGELIA',
        'code': 'ARGELIA',
      },
      {
        'name': 'BOLÍVAR',
        'code': 'BOLÍVAR',
      },
      {
        'name': 'BUENAVENTURA',
        'code': 'BUENAVENTURA',
      },
      {
        'name': 'GUADALAJARA DE BUGA',
        'code': 'GUADALAJARA DE BUGA',
      },
      {
        'name': 'BUGALAGRANDE',
        'code': 'BUGALAGRANDE',
      },
      {
        'name': 'CAICEDONIA',
        'code': 'CAICEDONIA',
      },
      {
        'name': 'CALIMA',
        'code': 'CALIMA',
      },
      {
        'name': 'CANDELARIA',
        'code': 'CANDELARIA',
      },
      {
        'name': 'CARTAGO',
        'code': 'CARTAGO',
      },
      {
        'name': 'DAGUA',
        'code': 'DAGUA',
      },
      {
        'name': 'EL ÁGUILA',
        'code': 'EL ÁGUILA',
      },
      {
        'name': 'EL CAIRO',
        'code': 'EL CAIRO',
      },
      {
        'name': 'EL CERRITO',
        'code': 'EL CERRITO',
      },
      {
        'name': 'EL DOVIO',
        'code': 'EL DOVIO',
      },
      {
        'name': 'FLORIDA',
        'code': 'FLORIDA',
      },
      {
        'name': 'GINEBRA',
        'code': 'GINEBRA',
      },
      {
        'name': 'GUACARÍ',
        'code': 'GUACARÍ',
      },
      {
        'name': 'JAMUNDÍ',
        'code': 'JAMUNDÍ',
      },
      {
        'name': 'LA CUMBRE',
        'code': 'LA CUMBRE',
      },
      {
        'name': 'LA UNIÓN',
        'code': 'LA UNIÓN',
      },
      {
        'name': 'LA VICTORIA',
        'code': 'LA VICTORIA',
      },
      {
        'name': 'OBANDO',
        'code': 'OBANDO',
      },
      {
        'name': 'PALMIRA',
        'code': 'PALMIRA',
      },
      {
        'name': 'PRADERA',
        'code': 'PRADERA',
      },
      {
        'name': 'RESTREPO',
        'code': 'RESTREPO',
      },
      {
        'name': 'RIOFRÍO',
        'code': 'RIOFRÍO',
      },
      {
        'name': 'ROLDANILLO',
        'code': 'ROLDANILLO',
      },
      {
        'name': 'SAN PEDRO',
        'code': 'SAN PEDRO',
      },
      {
        'name': 'SEVILLA',
        'code': 'SEVILLA',
      },
      {
        'name': 'TORO',
        'code': 'TORO',
      },
      {
        'name': 'TRUJILLO',
        'code': 'TRUJILLO',
      },
      {
        'name': 'TULUÁ',
        'code': 'TULUÁ',
      },
      {
        'name': 'ULLOA',
        'code': 'ULLOA',
      },
      {
        'name': 'VERSALLES',
        'code': 'VERSALLES',
      },
      {
        'name': 'VIJES',
        'code': 'VIJES',
      },
      {
        'name': 'YOTOCO',
        'code': 'YOTOCO',
      },
      {
        'name': 'YUMBO',
        'code': 'YUMBO',
      },
      {
        'name': 'ZARZAL',
        'code': 'ZARZAL',
      },
    ],
  },
  'Vaupés': {
    'name': 'Vaupés',
    'code': 'Vaupés',
    'cities': [
      {
        'name': 'MITÚ',
        'code': 'MITÚ',
      },
      {
        'name': 'CARURU',
        'code': 'CARURU',
      },
      {
        'name': 'PACOA',
        'code': 'PACOA',
      },
      {
        'name': 'TARAIRA',
        'code': 'TARAIRA',
      },
      {
        'name': 'PAPUNAUA',
        'code': 'PAPUNAUA',
      },
      {
        'name': 'YAVARATÉ',
        'code': 'YAVARATÉ',
      },
    ],
  },
  'Vichada': {
    'name': 'Vichada',
    'code': 'Vichada',
    'cities': [
      {
        'name': 'PUERTO CARREÑO',
        'code': 'PUERTO CARREÑO',
      },
      {
        'name': 'LA PRIMAVERA',
        'code': 'LA PRIMAVERA',
      },
      {
        'name': 'SANTA ROSALÍA',
        'code': 'SANTA ROSALÍA',
      },
      {
        'name': 'CUMARIBO',
        'code': 'CUMARIBO',
      },
    ],
  },
};