export const moduleRoutes = {
  root: 'home',
  childForms: {
    agentHome: {
      data: { title: 'Inicio' },
      requireAuth: false,
      functions: [],
      route: 'agentHome',
    },
  },
  layout: 'home',
  loadChildren: () => import('src/app/page-modules/home/home.module').then(m => m.HomeModule),
};
