import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { defaultConfig } from '../app.config';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  private geocoder: any;

  constructor() {
    const loader = new Loader({
      apiKey: defaultConfig.googleApiKey,
      version: "weekly",
      // ...additionalOptions,
    });
    
    loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      // map = new Map(document.getElementById("map") as HTMLElement, {
      //   center: { lat: -34.397, lng: 150.644 },
      //   zoom: 8,
      // });
    });
  }
}
