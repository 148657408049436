import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-actions-footer',
  templateUrl: './actions.footer.component.html',
  styleUrls: ['./actions.footer.component.scss'],
})
export class AppActionsFooter implements OnChanges {
  actionGroups: any[] = [];
  @Input() actions: any[];

  ngOnChanges() {
    this.updateVisualization();
  }

  async updateVisualization() {
    this.actionGroups = [];
    if (this.actions?.length > 0) {
      let previousType = null;
      let className = null;
      let currentGroup = { className: '', actions: [] };
      const actions = this.actions.length > 0 ? this.actions.filter(act => {
        return act?.customAttributes.location !== 'HEADER' && act?.customAttributes.displayLocation !== 'modal';
      }) : []
      for (let index = 0; index < actions.length; index++) {
        const action = actions[index];
        className = action?.customAttributes.displayType === 'link' ? 'linkGroupFooter' : 'actionGroupFooter';
        if (previousType === null || className === previousType) {
          if (previousType === null) {
            currentGroup.className = className;
          }
          currentGroup.actions.push(action);
        } else {
          this.actionGroups.push(currentGroup);
          currentGroup = { className: className, actions: [] };
          currentGroup.actions.push(action);
          previousType === null;
        }
        previousType = className;
      }
      if (currentGroup.actions.length > 0) {
        this.actionGroups.push(currentGroup);
      }
    }
  }
}
