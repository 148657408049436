<div class="authContainer">
  <div class="containerInner">
    <div class="nav d-flex justify-content-between bd-highlight topbar">
      <div class="float-left nav">
        <img class="header-logo" src="{{assetsBase}}/images/logos/horizontal-logo-pbb.png" />
      </div>
      <!-- Sección izquierda de la barra superios -->
      <!-- <div class="bd-highlight">
        <div class="d-flex justify-content-start align-items-center bd-highlight">
          <div class="bd-highlight"> -->
      <!-- <app-topbar-fav-pages></app-topbar-fav-pages> -->
      <!-- </div>
        </div>
      </div> -->
      <!-- Sección media de la barra superior
      <div class="bd-highlight">
        <div class="d-flex justify-content-center align-items-center bd-highlight">
          <div class="bd-highlight">
            <app-topbar-documentation></app-topbar-documentation>
          </div>
          <div class="bd-highlight">
            <app-topbar-project-management></app-topbar-project-management>
          </div>
          <div class="bd-highlight">
            <app-topbar-language-switcher></app-topbar-language-switcher>
          </div>
        </div>
      </div -->
      <!-- Sección derecha de la barra superios -->
      <div class="bd-highlight sessionMenu">
        <div class="d-flex justify-content-end align-items-center bd-highlight">
          <div class="pe-2 bd-highlight">
            <h6><strong>{{ userName }}</strong></h6>
          </div>
          <div class="pe-2 bd-highlight">
            <app-topbar-user-menu></app-topbar-user-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>