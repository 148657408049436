export const commonSliders = {
  slideObjects: [{
    image: {
      src: "../../../../assets/bolivariano/images/intro/icono1.png",
    },
    title: "¡Bienvenido a PuntoBB!",
    subtitle: "Como Corresponsal no Bancario, incrementas la cantidad de clientes en tu negocio"
  }, {
    image: {
      src: "../../../../assets/bolivariano/images/intro/icono2.png",
    },
    title: "Aumenta tus ingresos",
    subtitle: "Gana por cada transacción realizada"
  }, {
    image: {
      src: "../../../../assets/bolivariano/images/intro/icono3.png",
    },
    title: "Obtén un mayor alcance de tu negocio",
    subtitle: "Ofrece servicios bancarios a tu comunidad"
  }],

  swiperProperties: {
    effect: 'fade',
    titleStyle: "font-weight: 600",
    subtitleStyle: "width: 85%",
    imgStyle: "width: 150px; height: auto; aspect-ratio: 1; object-fit: cover; margin-bottom: 1rem; border-radius: .5rem",
    slideStyle: "padding: 1rem; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;",
    // containerStyle: "border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px",
  }
}

export const commonSlidersGuard = {
  slideObjects: [{
    image: {
      src: "../../../../assets/bolivariano/images/guard/icono1.png",
    },
    title: "Tip de seguridad",
    subtitle: "Usa una computadora, un teléfono o una tablet con los que accedas a menudo."
  },],

  swiperProperties: {
    effect: 'fade',
    titleStyle: "font-weight: 600",
    subtitleStyle: "width: 85%",
    imgStyle: "width: 150px; height: auto; aspect-ratio: 1; object-fit: cover; margin-bottom: 1rem; border-radius: .5rem",
    slideStyle: "padding: 1rem; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;",
    // containerStyle: "border-radius: 1rem; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px",
  }
} 