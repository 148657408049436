import { Component, ChangeDetectionStrategy } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-date-month',
  templateUrl: './app-date-month.component.html',
  styles: [`nz-date-picker ::ng-deep .ant-calendar-picker { width: 100%; }
    nz-date-picker, nz-time-picker { width: 100%; }
    .form-control-error { z-index:4; }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDateMonthComponent extends FieldComponent {
  style: string;

  disabledDate = (current: Date): boolean => {
    if (this && this.minValue() && differenceInCalendarDays(current, this.minValue()) < 0) {
      return true;
    } else if (this && this.maxValue() && differenceInCalendarDays(current, this.maxValue()) > 0) {
      return true;
    }
    return false;
  };

  disabledDate2 = (current: Date): boolean => {
    const today = new Date();
    return differenceInCalendarDays(current, today) > 0;
  }
}
