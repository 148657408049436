<div *ngIf="visible() && customAttributes()?.displayType !== 'none'" [id]="subSection.code">
  <h4 *ngIf="showHeader">
    <strong>{{ subSection.subsectionTitle }}</strong>
  </h4>
  <ng-container *ngFor="let elementGroup of elementgroups; let i = index">
    <div class="elementGroup elementGroup-{{ i }}">
      <div [ngClass]="elementGroup.className">
        <ng-container *ngFor="let element of elementGroup.elements">
          <app-element *ngIf="element.visible" class="element" [element]="element"></app-element>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
