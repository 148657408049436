<div *ngIf="visible()" [id]="section.code">
  <!-- [nzBordered]="bordered"  -->
  <ng-container [ngSwitch]="customAttributes().displayType">
    <ng-container *ngSwitchCase="'tabbed'">
      <nz-tabset [nzTabPosition]="customAttributes().tabLocation ?? 'left'" nzType="line">
        <ng-container *ngFor="let subSection of section?.subSections">
          <nz-tab
            *ngIf="subSection.visible"
            [nzTitle]="subSection.subsectionTitle"
            (nzClick)="section?.activateSubSection(subSection.subSectionCode)">
            <app-subsection *ngIf="subSection?.visible" [subSection]="subSection" [showHeader]="false"></app-subsection>
          </nz-tab>
        </ng-container>
      </nz-tabset>
    </ng-container>

    <ng-container *ngSwitchCase="'accordion'">
      <h4 *ngIf="showHeader" nz-typography>
        <strong>{{ section?.title }}</strong>
      </h4>
      <nz-collapse nzGhost>
        <!-- [nzBordered]="false" -->
        <nz-collapse-panel
          *ngFor="let subSection of standardSubsections"
          [nzHeader]="subSection.subsectionTitle"
          [nzActive]="subSection.visible"
          [nzDisabled]="!subSection.visible">
          <app-subsection
            *ngIf="subSection?.visible"
            [subSection]="subSection"
            [showHeader]="showSubsectionHeaders"></app-subsection>
        </nz-collapse-panel>
      </nz-collapse>
      <div class="mt-2">
        <ng-container *ngFor="let subSection of specialSubsections">
          <app-subsection
            *ngIf="subSection?.visible"
            [subSection]="subSection"
            [showHeader]="showSubsectionHeaders"></app-subsection>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <h4 *ngIf="showHeader" nz-typography>
        <strong>{{ section?.title }}</strong>
      </h4>
      <ng-container *ngFor="let subSection of section?.subSections">
        <app-subsection
          *ngIf="subSection?.visible"
          [subSection]="subSection"
          [showHeader]="showSubsectionHeaders"></app-subsection>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
