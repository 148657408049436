export const moduleRoutes = {
  root: 'root-page',
  childForms: {
    myBusiness: {
      data: { title: 'Servicios' },
      requireAuth: true,
      functions: [],
      route: 'myBusiness',
    },
    dailySummary: {
      data: { title: 'Resumen del día' },
      requireAuth: true,
      functions: [],
      route: 'dailySummary',
    },
    transactionStore: {
      data: { title: 'Servicios' },
      requireAuth: true,
      functions: [],
      route: 'transactionStore',
    },
    notifications: {
      data: { title: 'Servicios' },
      requireAuth: true,
      functions: [],
      route: 'notifications',
    },
    ticketList: {
      data: { title: 'Servicios' },
      requireAuth: true,
      functions: [],
      route: 'ticketList',
    },
  },
  layout: 'main',
  loadChildren: () => import('src/app/page-modules/root-page/root-page.module').then(m => m.RootPageModule)
};
