<div class="euTopMenuBase">
  <ul nz-menu nzMode="inline">
    <li *ngFor="let menuTab of menuOptions" nz-menu-item>
      <a [ngClass]="(currentForm === menuTab?.form || menuTab?.relatedForms?.includes(currentForm)) ? 'activeItem' : ''"
        (click)="activateOption(menuTab)">
        <app-icon [iconName]="menuTab.iconName" collection="awesome" size="lg"></app-icon>
        <span class="notificationBadge" *ngIf="menuTab?.badge" color="danger">{{menuTab?.badge}}</span>
        {{menuTab?.title}}
      </a>
    </li>
  </ul>
</div>