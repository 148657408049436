import { Component, computed, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NgOtpInputComponent } from 'ng-otp-input';
import { AppSimpleField } from '../app-simple-field/app-simple-field.component';

@Component({
  selector: 'app-otp-input',
  templateUrl: './app-otp-input.component.html',
  styleUrls: ['app-otp-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppOtpInputComponent extends AppSimpleField {
  otpConfig = computed(() => {
    return {
      length: this.customAttributes().otpLength ?? 6,
      allowNumbersOnly: true,
      inputStyles: {
        width: '50px',
        height: '50px'
      }
    };
  });

  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  override updateValue(): void {
    this.ngOtpInput?.setValue(this.field.value);
  }

  onOtpChange(otpCode) {
    if (otpCode?.length === +this.otpConfig().length) {
      this.value.set(otpCode);
    } else {
      if (this.customAttributes().noClean) {
        this.value.set(otpCode);
      } else {
        this.value.set('');
      }
    }
    this.updateObject(false);
    this.onInputChange();
  }
}
