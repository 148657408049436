<div class="row">
  <h3 class="col-lg-12" *ngIf="captureType() === 'LABEL'">
    <span class="ant-page-header-heading-title ng-star-inserted">
      {{ value() }}
    </span>
  </h3>
  <div
    class="col-lg-12"
    style="text-align: center; margin-top: 0.5rem; margin-bottom: 0.5rem"
    [ngClass]="customAttributes().displayClass">
    <ng-container *ngIf="captureType() === 'TITLE' && value()" [ngSwitch]="customAttributes().size">
      <h1 *ngSwitchCase="'h1'" nz-typography>{{ value() }}</h1>
      <h2 *ngSwitchCase="'h2'" nz-typography>{{ value() }}</h2>
      <h3 *ngSwitchCase="'h3'" nz-typography>{{ value() }}</h3>
      <h4 *ngSwitchCase="'h4'" nz-typography>{{ value() }}</h4>
      <h6 *ngSwitchCase="'h6'" nz-typography>{{ value() }}</h6>
      <h5 *ngSwitchDefault nz-typography [class]="className" [ngClass]="customAttributes().displayClass">
        {{ value() }}
      </h5>
    </ng-container>
    <ng-container *ngIf="captureType() === 'SUMMARYFIELD'">
      <div class="col-lg-12 summaryField" [ngClass]="className">
        <div style="display: flex; justify-content: end; align-items: center; padding: 0.5rem; padding-inline: 2rem">
          <label [ngStyle]="customAttributes().labelStylesWeb">{{ placeholder() }}</label>
          <h6 nz-typography [ngStyle]="customAttributes().valueStylesWeb">&#8287;{{ value() }}</h6>
        </div>
      </div>
    </ng-container>
    <div class="col-lg-12 staticQuestion">
      <ng-container *ngIf="captureType() === 'QUESTION'" [ngSwitch]="customAttributes().size">
        <div class="mainQuest">
          <h5 *ngSwitchDefault nz-typography>{{ value() }}</h5>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col-lg-12" *ngIf="captureType() === 'MESSAGE' && value()" style="text-align: center">
    <span *ngIf="captureType() === 'MESSAGE' && value()" nz-typography>{{ value() }}</span>
    <br />
    <br />
  </div>
  <a href="{{ value() }}" target="_blank" *ngIf="captureType() === formConfig?.fieldTypes?.link">
    {{ title() }}
  </a>
</div>
