import { Component, Injector } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

const checkFrequency = 60000;
const defaultValidPosition = true;
const SESSION_ESTABLISHED = 'sessionEstablished';
const SESSION_ENDED = 'sessionEnded';
const INVASIVE_NOTIFICATION = 'invasiveNotification';

const frm = {
  name: 'activityCheck',
  states: { default: 'default' },
  sections: { default: 'default' },
  subsections: { default: 'default' },
  fields: {
    lastCheckDate: 'lastCheckDate',
    geolocation: 'geolocation',
    validTime: 'validTime',
    agentPendingTickets: 'agentPendingTickets',
    agentPendingNotifications: 'agentPendingNotifications',
    nextInvasiveNotifications: 'nextInvasiveNotifications',
    agentAccountBalance: 'agentAccountBalance',
    agentAccountBalanceDate: 'agentAccountBalanceDate',
    agentAccountActive: 'agentAccountActive',
    agentValidDistance: 'agentValidDistance',
    notificationToClose: 'notificationToClose',
    notificationCloseType: 'notificationCloseType',
  },
  actions: {
    checkActivity: 'checkActivity',
    closeNotification: 'closeNotification',
  },
  tables: {},
};

@Component({
  selector: 'app-activity-check',
  template: `<div></div>`,
})
export class ActivityCheckComponent extends AppFormComponent {
  checkTimer: any = null;
  activatedCheck: boolean = false;
  pendingTickets: number = 0;
  pendingNotifications: number = 0;
  validPosition: boolean = false;
  lastAgentStatus: any = {};
  activityCheckStart: Date;

  override preStart() { this.name = frm.name; }

  override start() {
    super.start();
    this.activityCheckStart = new Date();
    this.disableActivityNotification();
    this.disableServerErrorPopup();
    this.subscribeAppEvents(SESSION_ESTABLISHED, (data) => { this.activateChecking(data); });
    this.subscribeAppEvents(SESSION_ENDED, event => this.deactivateChecking(event));
    this.subscribeAppEvents('closeInvasiveNotification', (data) => { data && this.closeInvasiveNotification(data); });
    this.onActionStart(frm.actions.checkActivity, async () => {
      const geolocation = await this.getCurrentPosition();
      this.setFieldValue('geolocation', geolocation);
      this._userSession.addCustomSessionData('geolocation', geolocation);
    });
    this.onActionFinish(frm.actions.checkActivity, this.updateObtaindedActivity.bind(this));
  }

  deactivateChecking(event) {
    const { endDate } = event ?? {};
    if (endDate && endDate > this.activityCheckStart) {
      this.activatedCheck = false;
      clearTimeout(this.checkTimer);
    }
  }

  activateChecking(sessionInfo) {
    if (sessionInfo?.sessionData?.agentId && !this.activatedCheck) {
      this.activatedCheck = true;
      this.startAction(frm.actions.checkActivity);
    }
  }

  restartTimer() {
    if (this.activatedCheck) {
      clearTimeout(this.checkTimer);
      this.checkTimer = setTimeout(() => this.startAction(frm.actions.checkActivity), checkFrequency);
    }
  }

  updateObtaindedActivity() {
    if (this.activatedCheck && !this.errorOccured()) {
      this.refreshAgentInfo();
    }
  }

  refreshAgentInfo() {
    const sessionLost = this.getExtraInfo('sessionLost');
    if (sessionLost === true) {
      this.emitAppEvents(SESSION_ENDED, { endDate: new Date() });
      return;
    } else {
      this.restartTimer();
    }
    const validTime = this.getFieldValue(frm.fields.validTime) || null;
    const pendingTickets = this.getFieldValue(frm.fields.agentPendingTickets) || null;
    const pendingNotifications = this.getFieldValue(frm.fields.agentPendingNotifications) || null;
    const agentAccountBalance = this.getFieldValue(frm.fields.agentAccountBalance) || null;
    const agentValidDistance = this.getFieldValue(frm.fields.agentValidDistance) || null;
    const agentAccountActive = this.getFieldValue(frm.fields.agentAccountActive) || null;
    const agentAccountBalanceDate = this.getFieldValue(frm.fields.agentAccountBalanceDate) || null;
    const nextInvasiveNotifications = this.getFieldValue(frm.fields.nextInvasiveNotifications) || null;
    this.emitAppEvents(INVASIVE_NOTIFICATION, { ...nextInvasiveNotifications, eventDate: new Date() });

    // Se limpian los campos que no se quieren enviar de vuelta al back
    this.setFieldValue(frm.fields.nextInvasiveNotifications, '');
    // Se toman las acciones de notificación de los cambios
    if (pendingTickets && !Number.isNaN(pendingTickets) && pendingTickets !== this.pendingTickets) {
      this.emitAppEvents('pendingTickets', pendingTickets);
    }
    if (pendingNotifications && !Number.isNaN(pendingNotifications) && pendingNotifications !== this.pendingNotifications) {
      this.emitAppEvents('pendingNotifications', pendingNotifications);
    }
    let validPosition = true;
    if (agentValidDistance) {
      const { agentOutOfRange = false } = agentValidDistance;
      validPosition = defaultValidPosition || !agentOutOfRange;
    }
    const agentBalance = (Number.isNaN(+agentAccountBalance) || +agentAccountBalance === 0) ? null : +agentAccountBalance;
    const currentStatus = {
      validPosition,
      agentBalance,
      agentAccountBalance: agentBalance,
      agentTotalBalance: agentBalance,
      agentAccountBalanceDate,
      validTime,
      activeAccount: agentAccountActive,
    };
    const changedStatus = {};
    const agentStatusFields = Object.keys(currentStatus);
    let agentStatusChanged = false;
    for (let index = 0; index < agentStatusFields.length; index++) {
      const fieldName = agentStatusFields[index];
      if (currentStatus[fieldName] !== null && currentStatus[fieldName] !== this.lastAgentStatus[fieldName]) {
        agentStatusChanged = true;
        changedStatus[fieldName] = currentStatus[fieldName];
      }
    }
    if (agentStatusChanged) {
      Object.assign(this.lastAgentStatus, currentStatus);
      this.emitAppEvents('agentStatus', changedStatus);
    }
  }

  closeInvasiveNotification(notificationData) {
    const { id, permanent, nextForm } = notificationData ?? {};
    this.setFieldValue(frm.fields.notificationToClose, id);
    this.setFieldValue(frm.fields.notificationCloseType, permanent);
    this.startAction(frm.actions.closeNotification);
    if (nextForm) {
      this.openForm(nextForm);
    }
  }
}
