/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import urlJoin from 'proper-url-join';
import { LibFormManagerService } from 'tuain-ng-forms-lib';
import { StorageService } from './storage.service';
import { AppConfigService } from './app-configuration.service';
import { APIGatewayService } from './api-gateway.service';
import { EventManagerService } from './event-manager.service';
import { UserSessionService } from './user-session.service';
import { defaultForms } from './form-manager.service.config';
import { ComponentPageService } from 'src/app/services/component-pages.service';
import { defaultConfig } from 'src/app/app.config';

const SESSION_ESTABLISHED = 'sessionEstablished';
const SESSION_ENDED = 'sessionEnded';
const NOTIFY_FORM_AFTER_SESSION_START = 'notifyFormAfterSessionStart';
const NOTIFY_FORM_AFTER_SESSION_END = 'notifyFormAfterSessionEnd';

const SYNC = 0;
const UPDATE_ALL = 1;
const SIGNATURE = 'sign';
const FORM_DEF = 'def';
const COMPARISON_FUNCTIONS_FIELD = 'functionCode';
const CUSTOMIZE = false;
const EXEC_START = false;
const VERSION = 'TUAIN-FRM-2021A';

const CONFIG_LOADED = 'configLoaded';
const DEFAULT = 'DEFAULT';
const SESSIONLESS_HOME = 'SESSIONLESS_HOME';
const SESSIONBASED_HOME = 'SESSIONBASED_HOME';
const LOGIN = 'LOGIN';
const HOME = 'agentHome';
const UNAUTHORIZED = 'UNAUTHORIZED';
const START = 'START';

function userAllowed(requiredFunctions, userFunctions, matchFld = null) {
  for (let index = 0; index < requiredFunctions?.length; index++) {
    const requiredFunction = requiredFunctions[index];
    const hasFunction = userFunctions?.find(
      usrFnc => (matchFld ? usrFnc[matchFld] : usrFnc) === requiredFunction);
    if (!hasFunction) {
      return false;
    }
  }
  return true;
}

function removeUnsupportedActions(actions, userFunctions) {
  const actIndexesToRemove = [];
  for (let index = 0; index < actions?.length; index++) {
    const actionFunctions = actions[index]?.functions ?? [];
    if (!actionFunctions.every(fnc => userFunctions?.includes(fnc))) {
      actIndexesToRemove.unshift(index);
    }
    delete actions[index].functions;
  }
  for (let index = 0; index < actIndexesToRemove.length; index++) {
    actions.splice(actIndexesToRemove[index], 1);
  }
  return actions;
}

function removeUnsupportedTableActions(tables, userFunctions) {
  for (let index = 0; index < tables?.length; index++) {
    const table = tables[index];
    const actIndexesToRemove = [];
    for (let actIndex = 0; actIndex < table?.actions?.length; actIndex++) {
      const actionFunctions = table.actions[actIndex]?.functions ?? [];
      // COMPARISON_FUNCTIONS_FIELD
      if (!userAllowed(actionFunctions, userFunctions)) {
        actIndexesToRemove.unshift(actIndex);
      }
      delete table.actions[actIndex].functions;
    }
    for (let actIndex = 0; actIndex < actIndexesToRemove.length; actIndex++) {
      table.actions.splice(actIndexesToRemove[actIndex], 1);
    }
  }
  return tables;
}

function customizeFormDefinition(formDefinition, userFunctions) {
  const { transitions, actions, tables, states = [] } = formDefinition;
  formDefinition.states = states.map(item => item.name);
  formDefinition.transitions =
    transitions
      ?.map((trns) => ({
        name: trns.name,
        source: trns.source,
        destination: trns.destination,
        functions: trns.functions ?? [],
      })
      ).filter(
        trns => {
          const { functions: transitionFunctions = [] } = trns;
          for (let index = 0; index < transitionFunctions.length; index++) {
            if (!userFunctions?.includes(transitionFunctions[index])) {
              return false;
            }
          }
          return true;
        }) ?? [];
  formDefinition.actions = removeUnsupportedActions(actions, userFunctions) ?? [];
  formDefinition.tables = removeUnsupportedTableActions(tables, userFunctions);
  return formDefinition;
}

@Injectable({ providedIn: 'root' })
export class FormOperationService extends LibFormManagerService {
  encryptKey: string;
  signatures: any;
  state: number;
  userFunctions: string[];
  lastRequestedForm: [string, string, string];
  logoutPath: string = '';
  formAfterSessionStart: string = '';
  nextForm: string = defaultConfig?.initialForm;
  serverEntryPoint: any = defaultConfig?.serverEntryPoint;

  constructor(
    private _apiGateway: APIGatewayService,
    private _router: Router,
    private _eventManager: EventManagerService,
    private _appConfig: AppConfigService,
    private _storageService: StorageService,
    private _componentPageService: ComponentPageService,
    private _userSession: UserSessionService,
  ) {
    super();
    this.state = SYNC;
    this.subscribeEvents();
  }

  override async loadStack() {
    this.pageStack = await this._storageService?.getItem('pageStack') ?? [];
  }

  override saveStack() {
    this._storageService.setItem('pageStack', this.pageStack);
  }

  extractConfigData() {
    this.logoutPath = this._appConfig.getParameter('logoutPath');
    this.serverEntryPoint = this._appConfig.getParameter('serverEntryPoint');
  }

  subscribeEvents() {
    this._eventManager.subscribe(CONFIG_LOADED, (data) => { data && this.extractConfigData(); });
    this._eventManager.subscribe('sessionEncryptKeyReceived', data => this.updateEncryptKey(data));
    this._eventManager.subscribe('notAuthorized', () => this.goToForm(UNAUTHORIZED));
    this._eventManager.subscribe(NOTIFY_FORM_AFTER_SESSION_START, (formName) => {
      (typeof formName === 'string') && (this.formAfterSessionStart = formName);
    });
    this._eventManager.subscribe(NOTIFY_FORM_AFTER_SESSION_END,
      (form) => { form && (this.nextForm = form); });
    this._eventManager.subscribe(SESSION_ESTABLISHED, data => this.newSessionEstablished(data));
    this._eventManager.subscribe(SESSION_ENDED, (data) => {
      if (!data) { return; }
      const formToOpen = data?.formToOpen;
      this._apiGateway.closeSession();
      const nextFormAfterClose = formToOpen ?? this.nextForm;
      if (nextFormAfterClose) {
        this.goToForm(nextFormAfterClose);
      }
    });
  }

  newSessionEstablished(fullSessionData) {
    const { sessionData, privileges, changeForm } = fullSessionData ?? {};
    if (!sessionData) { return; }
    this.getFormSignatures();
    if (privileges) { this.userFunctions = privileges; }
    if (changeForm) {
      let [nextForm, token, subject] = [this.formAfterSessionStart, null, null];
      if (!this.formAfterSessionStart) {
        [nextForm, token, subject] = this.lastRequestedForm ?? [];
      }
      if (!nextForm) {
        console.log('La aplicación está en un estado inviable tras la apertura de sesión (e.g.reload)');
        this._eventManager.next(SESSION_ENDED, { endDate: new Date() });
      }
      this.goToForm(nextForm, token, subject);
    }
  }

  updateEncryptKey(encryptKey) {
    this.encryptKey = encryptKey;
  }

  async getFormSignatures() {
    const formSignaturesRes = await this._apiGateway.getSignatures();
    this.signatures = formSignaturesRes.getData();
    const codes = Object.keys(this.signatures ?? {});
    if (codes.length === 0) {
      this.state = UPDATE_ALL;
      return;
    }
    codes.forEach(async (code) => {
      const remoteSignature = this.signatures[code];
      const storedSignature = await this.getFormSignature(code);
      if (storedSignature !== remoteSignature) {
        this.removeForm(code);
        this.updateSignature(code, remoteSignature);
      }
    });
  }

  override async getFormDefinition(code) {
    try {
      const storedDefinition = await this.getForm(code);
      if (storedDefinition && this.state !== UPDATE_ALL) {
        return customizeFormDefinition(storedDefinition, this.userFunctions);
      }
      this._eventManager.next('serverProcessStarted', null);
      const defRes = await this._apiGateway.getFormDefinition({
        formCode: code,
        customize: CUSTOMIZE,
        executeStart: EXEC_START,
        version: VERSION,
      });
      this._eventManager.next('serverProcessEnded', null);
      if (defRes && !defRes.hasError()) {
        const definition = defRes.getData();
        this.updateForm(code, definition);
        return customizeFormDefinition(definition, this.userFunctions);
      }
      // No se tiene definición local de formulario ni se obtuvo del server
      return defaultForms[code]
        ? customizeFormDefinition(defaultForms[code], this.userFunctions)
        : null;
    } catch (e) {
      console.log(`Se produjo una excepción obteniendo Formulario ${code}: ${e}`);
    }
    return null;
  }

  override async execServerAction(actionRequest) {
    try {
      this._eventManager.next('serverProcessStarted', null);
      const formActionResponse = await this._apiGateway.executeFormAction(actionRequest);
      this._eventManager.next('serverProcessEnded', null);
      const { error } = formActionResponse;
      if (error.errorName === 'forms-lib-formActionExec-sessionNotFound') {
        this._eventManager.next(SESSION_ENDED, { endDate: new Date() });
      } else if (formActionResponse && formActionResponse.data) {
        return formActionResponse;
      }
    } catch (e) {
      console.log(`Excepción solicitando acción ${actionRequest}: ${e}`);
    }
    return null;
  }

  override goToForm(formName, token = null, subject = null) {
    this.lastRequestedForm = [formName, token, subject];
    // if (this.logoutPath && (formName === SESSIONLESS_HOME || formName === LOGIN)) {
    //   let protocol = this.serverEntryPoint.protocol ?? window.location.protocol;
    //   protocol = protocol.endsWith(':') ? protocol.slice(0, -1) : protocol;
    //   let server = this.serverEntryPoint.server ?? window.location.hostname;
    //   server = server.endsWith('/') ? server.slice(0, -1) : server;
    //   let baseUrl = `${protocol}://${server}`;
    //   const port = this.serverEntryPoint.port ?? window.location.port;
    //   baseUrl += port ? `:${port}` : '';
    //   const logoutUrl = urlJoin(baseUrl, this.logoutPath);
    //   window.open(logoutUrl, '_self');
    //   return;
    // }
    const formRoute = this._componentPageService.formRoute(formName);
    const navigationArray = [formRoute];
    (token || subject) && navigationArray.push(token ?? 0);
    subject && navigationArray.push(subject);
    this._router.navigate(navigationArray);
  }

  async getForm(code: string) {
    return this._storageService.getItem(`${FORM_DEF}-${code}`);
  }

  async getFormSignature(code: string) {
    return this._storageService.getItem(`${SIGNATURE}-${code}`);
  }

  async updateSignature(code: string, value) {
    return this._storageService.setItem(`${SIGNATURE}-${code}`, value);
  }

  removeForm(code: string) {
    return this._storageService.removeItem(`${FORM_DEF}-${code}`);
  }

  updateForm(code: string, def) {
    return this._storageService.setItem(`${FORM_DEF}-${code}`, def);
  }
}
