import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { TranslateModule } from '@ngx-translate/core';
import { AntdModule } from 'src/app/antd.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FormBasedComponentsModule } from 'src/app/components/form-based/form-based.module';

import { TuainComponentsModule } from 'src/app/components/tuain/forms.module';
import { CustomComponentsModule } from 'src/app/components/custom/custom-component.module';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  HttpClientModule,
  FontAwesomeModule,
  FormBasedComponentsModule,
  GoogleMapsModule,
  TranslateModule,
  NgScrollbarModule,
  AntdModule,
  TuainComponentsModule,
  CustomComponentsModule,
];

@NgModule({
  imports: [
    ...MODULES,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false,
      serverLoggingUrl: '/api/logs',
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
  ],
  declarations: [],
  exports: [
    ...MODULES,
    LoggerModule,
  ],
})
export class SharedModule { }
