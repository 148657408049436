import { Component, effect, ViewChild, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { MaskitoOptions, MaskitoElementPredicate, maskitoTransform } from '@maskito/core';

@Component({
  selector: 'app-textformat',
  templateUrl: './app-text-format.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTextFormatComponent extends FieldComponent {
  valueChanged = false;
  preffix: string = null;
  pattern: any;
  maskOptions: MaskitoOptions;
  maskPredicate: MaskitoElementPredicate;

  @Input() prefixIcon = '';
  @Input() suffixIconName = '';
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  override start() {
    super.start();
    this.suffixIconName = this.customAttributes().suffixIconName ?? '';
    this.configurePredicate();
  }

  configurePredicate() {
    const format: any = this.format();
    if (typeof format === 'object') {
      const { mask, regExp } = format ?? {};
      this.pattern = regExp ? new RegExp(`^${regExp}$`) : null;
      const maskExprArray = mask?.map((part) => {
        const { type, value } = part ?? {};
        return type === 'exp' ? new RegExp(`^${value}$`) : value;
      });
      this.maskOptions = { mask: maskExprArray };
    } else if (format) {
      this.pattern = new RegExp(`^${format}$`);
      this.maskOptions = { mask: this.pattern };
    }
    this.maskPredicate = async el => this.inputElement?.nativeElement;
  }

  override focus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  setValueNativeInput(formatedValue) {
    this.value.set(formatedValue);
    if (this.inputElement?.nativeElement) {
      this.inputElement!.nativeElement.value = formatedValue ?? '';
    }
  }

  onChangeModel(value: string): void {
    this.valueChanged = true;
    let formatedValue = this.value();
    this.setValueNativeInput(formatedValue);
    this.updateObject();
    this.onInputChange();
  }
}
