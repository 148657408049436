<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <app-icon iconName="menubars" size="2x"></app-icon>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu *ngFor="let item of menuData">
      <li nz-menu-item (click)="activateMenu(item)">
        <app-icon *ngIf="item.icon" [iconName]="item.iconName"></app-icon>
        <span class="menuItemText">{{ item?.title }} {{userInfo[item?.value]}}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
