<div class="formField otpContainer" [ngSwitch]="true">
  <div *ngIf="labelText()" [class]="customAttributes().labelClass">
    <div *ngIf="customAttributes().secondLabel">
      <label position="stacked">{{ labelText() }} &nbsp;-&nbsp;</label>
      <label position="stacked" style="color: var(--antd-wave-shadow-color)">{{
        customAttributes().secondLabel
      }}</label>
    </div>
    <label *ngIf="!customAttributes().secondLabel" position="stacked">{{ labelText() }}</label>
  </div>
  <div [class]="customAttributes().displayClass">
    <ng-otp-input [id]="field.code" (onInputChange)="onOtpChange($event)" [config]="otpConfig()"></ng-otp-input>
  </div>
</div>
