import { Component, Input } from '@angular/core';
import { SubSectionComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-subsection',
  templateUrl: './app-subsection.component.html',
  styleUrls: ['./app-subsection.component.scss'],
})
export class AppSubSectionComponent extends SubSectionComponent {
  @Input() showHeader = false;
  elementgroups: any[] = [];

  override start() {
    super.start();
    this.updateVisualization();
  }

  async updateVisualization() {
    if (this.subSection?.subSectionElements?.length > 0) {
      let previousType = null;
      let elementType = null;
      let currentGroup = { className: '', elements: [] };
      for (let index = 0; index < this.subSection.subSectionElements.length; index++) {
        const element = this.subSection.subSectionElements[index];
        if (element?.isField()) {
          elementType = 'fieldGroup';
        } else if (element?.isTable()) {
          elementType = 'tableGroup';
        } else if (element?.isAction()) {
          elementType = element?.customAttributes.displayType === 'link' ? 'linkGroup' : 'actionGroup';
        }
        if (previousType === null || elementType === previousType) {
          if (previousType === null) {
            currentGroup.className = elementType;
          }
          currentGroup.elements.push(element);
        } else {
          this.elementgroups.push(currentGroup);
          currentGroup = { className: elementType, elements: [] };
          currentGroup.elements.push(element);
          previousType === null;
        }
        previousType = elementType;
      }
      if (currentGroup.elements.length > 0) {
        this.elementgroups.push(currentGroup);
      }
    }
  }
}
