export const moduleRoutes = {
  root: 'eu-auth',
  childForms: {
    endUserAccess: {
      data: { title: 'Access' },
      requireAuth: false,
      functions: [],
      route: 'endUserAccess',
    },
  },
  layout: 'euauth',
  loadChildren: () => import('src/app/page-modules/end-user/eu-auth/eu-auth.module').then(m => m.EndUserAuthModule),
};
