import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as forge from 'node-forge';
import { AppConfigService } from './app-configuration.service';
import { StorageService } from './storage.service';
import { EventManagerService } from './event-manager.service';

const EVENT_ROOTED_DEVICE = 'rootedDevice';
const EVENT_DEVELOPER_MODE = 'developerMode';
const CONFIG_LOADED = 'configLoaded';

const DEVICE_PREFFIX_ATTRIBUTE = 'devicePreffix';
const COMPROMISED_DEVICE = 'compromisedDevice';
const ROOT_CHECK_FREQ = 60 * 60 * 1000; // Cada 60 minutes
const DEV_MODE_CHECK_FREQ = 2 * 60 * 1000; // Cada 2 minutes

@Injectable({ providedIn: 'root' })
export class DeviceManagementService {
  deviceInfo: any;
  deviceId: string;
  publicKey: string;
  devicePreffix: string;
  deviceKeys: any;
  hardwareInfo: any;
  isRooted: boolean = false;
  isDeveloperMode: boolean = false;

  constructor(
    private _storageService: StorageService,
    private _appConfig: AppConfigService,
    private _deviceService: DeviceDetectorService,
    private _eventManager: EventManagerService,
  ) {
    this.startDeviceService();
  }

  async startDeviceService() {
    this.devicePreffix = this._appConfig.getParameter(DEVICE_PREFFIX_ATTRIBUTE);
    this._eventManager.subscribe(CONFIG_LOADED,
      (data) => { data && (this.devicePreffix = this._appConfig.getParameter(DEVICE_PREFFIX_ATTRIBUTE)); });
    this.obtainHardwareInfo();
    this.startSafeAreaCalculation();
  }

  async startSafeAreaCalculation() {}

  async obtainHardwareInfo() {
    const deviceInfo = this._deviceService.getDeviceInfo();
    const isMobile = this._deviceService.isMobile();
    const isTablet = this._deviceService.isTablet();
    const isDesktopDevice = this._deviceService.isDesktop();
    this.hardwareInfo = {
      deviceId: deviceInfo?.browser_version,
      name: `${deviceInfo?.os} ${deviceInfo?.browser}`,
      androidSDKVersion: 'none',
      isVirtual: true,
      manufacturer: deviceInfo?.browser,
      model: deviceInfo?.browser_version,
      operatingSystem: deviceInfo?.os,
      osVersion: deviceInfo?.os_version,
      platform: isDesktopDevice ? 'desktop' : 'mobile',
      webViewVersion: deviceInfo?.userAgent,
    }
  }

  getHardwareInfo() {
    return this.hardwareInfo;
  }

  async activateDeviceSecurityValidation() {
    return null;
  }

  public async createKeyPair() {
    let keyPair;
    let keyPairPem;
    await forge.pki.rsa.generateKeyPair(2048, 0x10001, (err, keypair) => {
      keyPair = keypair;
      keyPairPem = {
        publicKey: forge.pki.publicKeyToPem(keypair.publicKey),
        privateKey: forge.pki.privateKeyToPem(keypair.privateKey),
      };
    });
    return { keyPairPem, keyPair };
  }

  public async setAppDevice(deviceId, platformKey) {
    this.deviceId = deviceId;
    const { keyPair, keyPairPem } = await this.createKeyPair();
    this.deviceKeys = keyPair;
    this.publicKey = keyPairPem.publicKey;
    this._storageService.setItem(`${this.devicePreffix}-DEVICE-ID`, deviceId);
    this._storageService.setItem(`${this.devicePreffix}-DEVICE-${deviceId}`, { deviceId, platformKey, ...keyPairPem });
  }

  public async loadAppDevice() {
    this.deviceId = await this._storageService?.getItem(`${this.devicePreffix}-DEVICE-ID`);
    if (!this.deviceId) {
      return null;
    }
    const { deviceId, model, platform, operatingSystem, osVersion, manufacturer, name, isVirtual, webViewVersion, androidSDKVersion } = this.hardwareInfo;
    this.deviceInfo = {
      deviceId: this.deviceId,
      deviceNativeAlias: deviceId,
      model, platform, operatingSystem, osVersion, manufacturer, name, isVirtual, webViewVersion, androidSDKVersion,
    };
    if (!this.deviceKeys) {
      const { privateKey, publicKey, platformKey } = await this._storageService
        ?.getItem(`${this.devicePreffix}-DEVICE-${this.deviceId}`) ?? {};
      if (privateKey && publicKey && platformKey) {
        this.deviceKeys = {
          privateKey: forge.pki.privateKeyFromPem(privateKey),
          publicKey: forge.pki.publicKeyFromPem(publicKey),
          platformKey: forge.pki.publicKeyFromPem(platformKey),
        };
        this.publicKey = publicKey;
      }
    }
    return { deviceInfo: this.deviceInfo };
  }

  public async getAppDevice() {
    if (!this.deviceInfo || !this.deviceKeys) {
      await this.loadAppDevice();
    }
    const devicePublicInfo = {
      deviceId: this.deviceId,
      deviceData: this.deviceInfo,
      publicKey: this.publicKey,
    };
    devicePublicInfo?.deviceData && delete devicePublicInfo.deviceData.deviceId;
    return devicePublicInfo;
  }

  public async getDeviceId() {
    await this.getAppDevice();
    return this.deviceId;
  }

  public async removeDevice() {
    const deviceId = await this._storageService?.getItem(`${this.devicePreffix}-DEVICE-ID`);
    if (deviceId) {
      this._storageService?.removeItem(`${this.devicePreffix}-DEVICE-ID`);
      this._storageService?.removeItem(`${this.devicePreffix}-DEVICE-${deviceId}`);
      this.deviceInfo = null;
      this.deviceId = null;
      this.publicKey = null;
      this.deviceKeys = null;
    }
  }

  public async encryptData(data) {
    if (!this.deviceInfo || !this.deviceKeys) {
      await this.loadAppDevice();
    }
    const { publicKey } = this.deviceKeys;
    return publicKey.encrypt(data);
  }

  public async decryptData(data) {
    if (!this.deviceInfo || !this.deviceKeys) {
      await this.loadAppDevice();
    }
    const { privateKey } = this.deviceKeys ?? {};
    if (!privateKey) {
      return '';
    }
    try {
      const rawData = forge.util.decode64(data);
      return privateKey ? privateKey.decrypt(rawData) : null;
    } catch (e) {
      return null;
    }
  }

  public async signData(data) {
    if (!this.deviceInfo || !this.deviceKeys) {
      await this.loadAppDevice();
    }
    const { privateKey } = this.deviceKeys ?? {};
    if (!privateKey) {
      return null;
    }
    try {
      const md = forge.md.sha256.create();
      md.update(data);
      const sign = privateKey.sign(md);
      const signedData = forge.util.encode64(sign);
      return signedData;
    } catch (e) {
      return null;
    }
  }
}
