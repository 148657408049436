<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-input-group nzCompact>
      <nz-time-picker
        [nzAllowEmpty]="false"
        style="width: 50%"
        [id]="field.code"
        [(ngModel)]="startTime"
        (ngModelChange)="onChangeModel1($event)"
        [nzDisabled]="disabled()"
        nzFormat="HH:mm"
        [nzMinuteStep]="10"
        [disabled]="disabled()"
        [nzUse12Hours]="true"
        nzPlaceHolder="Seleccione hora inicial">
      </nz-time-picker>
      <nz-time-picker
        [nzAllowEmpty]="false"
        style="width: 50%"
        [id]="field.code"
        [(ngModel)]="endTime"
        (ngModelChange)="onChangeModel2($event)"
        [nzDisabled]="disabled()"
        nzFormat="HH:mm"
        [nzMinuteStep]="10"
        [disabled]="disabled()"
        [nzUse12Hours]="true"
        nzPlaceHolder="Seleccione hora final">
      </nz-time-picker>
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
