<div [ngSwitch]="fieldType" [ngStyle]="setStyles(column)">
  <div *ngSwitchCase="'TEXT'">{{ fieldValue }}</div>
  <div *ngSwitchCase="'BOOLEAN'"><app-icon [iconName]="iconBoolean"></app-icon></div>
  <div *ngSwitchCase="'NUMBER'">
    <ng-container *ngIf="column?.customAttributes.typeView === 'badge' && +fieldValue !== 0">
      <div [class]="column?.customAttributes.color" class="numBadge">{{ fieldValue }}</div>
    </ng-container>
    <ng-container *ngIf="column?.customAttributes.typeView !== 'badge'">
      {{ fieldValue }}
    </ng-container>
  </div>
  <div *ngSwitchCase="'EMAIL'">{{ fieldValue }}</div>
  <div *ngSwitchCase="'CURRENCY'">$ {{ (+fieldValue).toFixed(2) }}</div>
  <div *ngSwitchCase="'ONLYDATE'">{{ parseOnlyDate(fieldValue) }}</div>
  <div *ngSwitchCase="'DATE'">{{ parseMoment(fieldValue) }}</div>
  <div *ngSwitchCase="'UTCDATE'">{{ parseMomentUtc(fieldValue) }}</div>
  <div *ngSwitchCase="'ICON'"><app-icon [iconName]="fieldValue"></app-icon></div>
  <div *ngSwitchCase="'AVATAR'">
    <nz-avatar nzSize="large" nzIcon="user" nzSrc="{{ fieldValue }}"></nz-avatar>
  </div>
  <div *ngSwitchCase="'CHECK'">
    <app-icon [iconName]="fieldValue ? 'checked' : 'unchecked'"></app-icon>
  </div>
  <div *ngSwitchCase="'THUMBNAIL'">
    <a href="javascript: void(0);" class="cat__ecommerce__dashboard__list__img">
      <img src="{{ fieldValue }}" />
    </a>
  </div>
  <div *ngSwitchCase="'CHECK_THUMBNAIL'">
    <a *ngIf="fieldValue[0]" href="javascript: void(0);" class="thumbnail__list__img">
      <img src="{{ fieldValue[1] }}" />
    </a>
    <a *ngIf="!fieldValue[0]" href="javascript: void(0);" class="thumbnail__list__img">
      <img src="{{ fieldValue[1] }}" style="opacity: 0.5" />
    </a>
  </div>
  <div *ngSwitchCase="'PHONE'">{{ fieldValue[1] }}</div>
  <div *ngSwitchDefault>{{ fieldValue }}</div>
</div>
