import { Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-ngx-chart',
  templateUrl: './app-ngx-chart.component.html',
  styleUrls: ['./app-ngx-chart.component.scss']
})
export class AppChartNgxComponent {
  @Input() data: any = null;
  @Input() type: string = '';
  @Input() config: {} = {
    view: [],
    gradient: true,
    showXAxis: true,
    showYAxis: true,
    showLegend: true,
    showLabels: true,
    showXAxisLabel: false,
    showYAxisLabel: false,
    yAxisLabel: '',
    xAxisLabel: '',
  };

  @Input() colors = { domain: ['#019AA9', '#E2E3E7', '#AAE3f5', '#1EA7A1', '#FFC508', '#7AA3E5', '#00BCD4'] };

  constructor() {
    Object.assign(this, { data: this.data, });
  }

  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }
}