export const moduleRoutes = {
  root: 'security',
  childForms: {
    securityQuestionsSetup: {
      data: { title: 'Admin / securityQuestions' },
      requireAuth: false,
      route: 'securityQuestions',
    },
    securityQuestionsReset: {
      data: { title: 'Admin / securityQuestions' },
      requireAuth: false,
      route: 'questionsReset',
    },
    securityQuestionsVerify: {
      data: { title: 'Admin / securityQuestions' },
      requireAuth: false,
      route: 'securityQuestionsVerify',
    },
    habeasData: {
      data: { title: 'Admin / habeasData' },
      requireAuth: false,
      route: 'habeasData',
    },
    changePassword: {
      data: { title: 'Admin / ChangePassword' },
      requireAuth: false,
      route: 'changePassword',
    },
  },
  layout: 'security',
  loadChildren: () => import('src/app/page-modules/security/security.module').then(m => m.SecurityModule),
};
