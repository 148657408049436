<nz-layout style="background: #f4f5f9">
  <nz-content>
    <div class="authContainer">
      <div class="containerInner">
        <div class="float-left nav" style="justify-content: space-between">
          <img class="header-logo" src="{{ assetsBase }}/images/logos/horizontal-logo-pbb.png" />
          <div class="menuIcon">
            <span (click)="toggleMenuVisibility()">
              <span style="margin-right: 1rem; text-transform: capitalize; font-weight: 100">{{ userFeeling }}</span>
              <app-icon iconName="CARETDOWN" collection="awesome" size="lg"></app-icon>
            </span>
          </div>
        </div>
        <div class="float-left nav" style="background: var(--antd-wave-shadow-color)">
          <app-top-menu id="navbar-root"></app-top-menu>
        </div>

        <div nz-row>
          <div nz-col nzFlex="auto"></div>
          <div nz-col nzFlex="1200px">
            <div nz-row>
              <div nz-col nzSpan="16">
                <app-greetings></app-greetings>
                <div>
                  <app-txn-menu></app-txn-menu>
                </div>
                <div *ngIf="enabledRouter" class="cardBox widgetCard">
                  <!-- <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
                    [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
                    [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')"
                    class="containerRouter"> -->
                  <div class="containerRouter">
                    <router-outlet #outlet="outlet"></router-outlet>
                  </div>
                </div>
              </div>
              <div nz-col nzSpan="8" class="widgetCol">
                <app-balance-card></app-balance-card>
                <app-other-services></app-other-services>
              </div>
            </div>
          </div>
          <div nz-col nzFlex="auto"></div>
        </div>
      </div>
    </div>
  </nz-content>

  <nz-drawer [nzWidth]="300" [nzVisible]="menuVisible" nzPlacement="right" (nzOnClose)="toggleMenuVisibility()">
    <ng-container *nzDrawerContent>
      <div class="menuTitle" *ngFor="let actionGroup of menuData">
        <span>{{ actionGroup.title }}</span>
        <nz-divider nzPlain nzText=""></nz-divider>
        <div>
          <div
            class="menuItem"
            *ngFor="let menuItem of actionGroup.children; let i = index"
            (click)="openItemForm(menuItem.form)">
            <app-icon class="itemIcon" [iconName]="menuItem.iconName" collection="awesome" size="lg"></app-icon>
            <span>{{ menuItem.title }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</nz-layout>
