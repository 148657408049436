import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-label',
  templateUrl: './app-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLabelComponent extends FieldComponent {
  @Input() labelType: any;
}
